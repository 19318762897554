import moment from 'moment';
import env from './env.json';
import axios from 'axios';
import {DateTime} from 'luxon';


function generateGreetings(){

    // const currentHour = moment().format('HH');
    // if (currentHour >= 3 && currentHour < 12){
    //     return 'Good Morning,';
    // } else if (currentHour >= 12 && currentHour < 18){
    //     return 'Good Afternoon,';
    // }   else if (currentHour >= 18 || currentHour < 3){
    //     return 'Good Evening,';
    // } else {
    //     return 'Hello,';
    // }
    return 'Hi,';
}

const getGoogleRefreshToken = async (oktaAuthToken) => {
    try {
        const googleRefreshToken = localStorage.getItem('googleRefreshToken');
        if(googleRefreshToken && googleRefreshToken !== 'undefined' ) return googleRefreshToken;
        // if(googleToken) {
        //     // validate access token
        //     const resp = await axios({
        //         method: 'post',
        //         url: `${env.BACKEND_URL}/validate-google-token`,
        //         headers: { 'Authorization': oktaAuthToken },
        //         data: {token: googleToken}
        //     });

        //     if(resp.data.valid) return googleToken;
        // } 

        const resp1 = await axios({
            method: 'get',
            url: `${env.BACKEND_URL}/google-auth`,
            headers: { 'Authorization': oktaAuthToken },
        });
        if(resp1.data) {
           // console.log(resp1.data.data);
            window.location.href = resp1.data.data;
        }
       
    } catch(e) {
        console.log(e);
    }
};

const updatePeerHotelDesks = async (deskBookingResponse, oktaAuthToken) => {
    try {
        let desks = {};
        if(deskBookingResponse.data.data.createBooking) {
           const booking = deskBookingResponse.data.data.createBooking.booking;
           console.log(booking);
           if(booking) {
            const date = booking.date;
            const code = booking.space.externalId;
            const formattedDate =  DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
            desks[formattedDate] = code;
           }
        } else if(deskBookingResponse.data.data.createBookingSchedule) {
            const bookings = deskBookingResponse.data.data.createBookingSchedule?.bookingSchedule?.bookings;
            if(bookings && bookings.length > 0) {
                bookings.forEach(booking => {
                    const date = booking.date;
                    const code = booking.space.externalId;
                    const formattedDate =  DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
                    desks[formattedDate] = code;
                });
            }
        }
        if(Object.keys(desks).length === 0) return;
        let lenelId = localStorage.getItem('lenelId');
        if(!lenelId || lenelId === 'undefined') {
            const resp = await axios({
                method: "get",
                url: `${env.BACKEND_URL}/get-user?image=true&lenelId=true`,
                headers: { Authorization: oktaAuthToken },
              });
              lenelId = resp.data.lenelId;
              localStorage.setItem('lenelId', lenelId);
        }
        const response = await axios({
            method: 'post',
            url: `${env.BACKEND_URL}/peers/update-hotel-desk`,
            headers: { 'Authorization': oktaAuthToken },
            data: {
                lenelId,
                desks
            }
        });
        return response.data.ok;

    } catch(err) {
        console.log(err);
        return false;
    }
   
}

export {
    generateGreetings,
    getGoogleRefreshToken,
    updatePeerHotelDesks,
};
