import React, {useEffect, useState} from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { useLocation, useHistory } from 'react-router-dom';
import { Layout, Card } from 'antd';


import EventNavigationbar from '../components/EventList/EventNavigationbar';

import RoomReservation from '../components/HQEvent/RoomReservation';
import ProjectRoom from '../components/HQEvent/ProjectRoom';
import '../assets/css/hqevent.css';
import Header from '../shared/Header';

const { Content } = Layout;

const HQEvent = () => {
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setTabs([
        {
            tab: 'Room Reservation',
            key: 'roomReservation'
        },
        {
            tab: 'Project Room Reservation',
            key: 'projectRoom'
        },
        {
            tab: '3.4 Event Space',
            key: 'eventSpace'
        }  
    ]);
 
    setActiveTab('roomReservation');
    const eventData = location?.state?.eventData;
    if(eventData) {
        eventEditHandler(eventData);
    }
    }, []);





    const eventEditHandler = (item ) => {
        setSelectedItem(item);
        if(item.briefing_type_id === 1) {
            setActiveTab('roomReservation');
        } else if(item.briefing_type_id === 2) {
            setActiveTab('projectRoom');
        }
    };

    
    return <>
        <Header hideMyBadge={true}/>
        <div className='custom-bck inner-bck'>
            <EventNavigationbar navigationTitle='BOOK AN EVENT: SANTA CLARA HQ' path='/book-an-event' />
            <Content style={{ padding: '0 50px', marginTop: 64, display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: '1100px', border: '1px solid #d9d9d9', borderRadius: '4px', marginBottom: 64, background: 'white' }}>
                        <Card
                            style={{ width: '100%' }}
                            tabList={tabs}
                            activeTabKey={activeTab}
                            onTabChange={key => {
                                if(key === 'eventSpace') {
                                    localStorage.setItem('sitesCurrent', 'QnVpbGRpbmctQnVpbGRpbmcuZDJiMzE2MzYtYWIwOC00N2IyLTkxNzYtMjM3MDJjZmZhNjZi');
                                    localStorage.setItem('fromEventPage', 'true');
                                    history.push('/book-a-space');
                                    return;
                                }
                                setSelectedItem(null);
                                setActiveTab(key);
                            }}
                        >
                            {activeTab === 'roomReservation' ?  <RoomReservation data={selectedItem}  /> : null }
                            {activeTab === 'projectRoom' ?  <ProjectRoom data={selectedItem}  /> : null }
                          
                        </Card>

                </div>
            </Content>
        </div>
    </>;

};


export default withOktaAuth(HQEvent);