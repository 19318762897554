import React, {useState, useEffect} from 'react';
import moment from 'moment';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { Card, Form, Input, Select, Alert, DatePicker, TimePicker, InputNumber, Checkbox, Button, Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import env from '../../env';
import { DateTime } from 'luxon';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 10,
    }
};

const RoomReservation = ({ authState, data, hideHeader, fromMap }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageObject, setMessageObject] = useState({});
    const [showEventModal, setShowEventModal] = useState(false);
    const [hqDates, setHqDates] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [neighborhoods, setNeighborhoods] = useState([]);
    const history = useHistory();

    useEffect(async () => {
        let config = {
            method: 'get',
            url: `${env.BACKEND_URL}/get-hq-dates-availability`,
            headers: { 'Authorization': authState.accessToken.value },
        };
       
        try {
            let response = await axios(config);
            const data = response.data;
            if (!(data.data)) {
                throw data;
            }

            setHqDates(data.data);

        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(async () => {
        let config = {
            method: 'get',
            url: `${env.BACKEND_URL}/get-hq-neighborhoods`,
            headers: { 'Authorization': authState.accessToken.value },
        };
       
        try {
            let response = await axios(config);
            const data = response.data;
            if (!(data.data)) {
                throw data;
            }

            const ngh = data.data.map(item => item.name);
            setNeighborhoods(ngh);

        } catch (err) {
            console.log(err);
        }
    }, []);

    

    const getAvailableRooms = async (selectedDate) => {
        const date = DateTime.fromISO(selectedDate.toISOString());
        const formattedDate = date.toISO({format: 'basic', suppressMilliseconds: true}).split('.')[0];
        
        if(!formattedDate) return;
        let config = {
            method: 'get',
            url: `${env.BACKEND_URL}/get-available-neighborhoods?date=${formattedDate}`,
            headers: { 'Authorization': authState.accessToken.value },
        };
       
        try {
            let response = await axios(config);
            const data = response.data;
            if (!(data.data)) {
                throw data;
            }
            const ngh = data.data.map(item => item.name);

            setNeighborhoods(ngh);

            const selectedNbh = form.getFieldValue('preferedNeighborhoodLocation');
            if(selectedNbh) {
                const selectedIndex = ngh.findIndex(item => item === selectedNbh);
                if(selectedIndex === -1) {
                    form.setFieldsValue({
                        preferedNeighborhoodLocation: null
                    });
                }
            }

        } catch (err) {
            console.log(err);
        }
    };

    


    const onFormSubmit = async (e) => {
        setLoading(true);
        let config = {
            method: 'post',
            url: data && !fromMap ? `${env.BACKEND_URL}/update-hq-event?eventId=${data.briefing_id}` : `${env.BACKEND_URL}/create-hq-event`,
            headers: { 'Authorization': authState.accessToken.value },
            data: {
                title: e.eventName,
                preferred_location: e.preferedLocation,
                preferred_location_hq: e.preferedLocation,
                preferred_neighborhood_location_combinat: e.preferedNeighborhoodLocation ? e.preferedNeighborhoodLocation : undefined,
                requestor_name: e.requesterName,
                requestor_email: e.requesterEmail,
                requestor_phone: e.requesterPhone,
                email: e.requesterEmail,
                preferred_date: e.proposedStartDate.format('YYYY-MM-DD'),
                preferred_end_date: e.proposedEndDate.format('YYYY-MM-DD'),
                preferred_start_time:  e.proposedStartTime.format('HH:mm:ss'),
                preferred_end_time:  e.proposedEndTime.format('HH:mm:ss'),
                alternate_date: e.alternateStartDate ? e.alternateStartDate.format('YYYY-MM-DD') : undefined,
                alternate_end_date:  e.alternateEndDate ? e.alternateEndDate.format('YYYY-MM-DD') : undefined,
                alternate_start_time:  e.alternateStartTime ?  e.alternateStartTime.format('HH:mm:ss') : undefined,
                alternate_end_time: e.alternateEndTime ? e.alternateEndTime.format('HH:mm:ss') : undefined,
                estimated_attendees: e.attendeesNum,
                attendees_type: e.attendeesType,
                additional_information: e.additionalInformation,
                all_attendees_are_over_the_age_of_18: e.attendeesAge,
                will_there_be_any_panw_executives_attend: e.panwAttendees,
                are_there_any_vips_attending_the_event: e.vipAttendees,
                will_external_people_be_attending_the_ev: e.externalPeopple,
                will_any_external_vendors_be_utilized: e.externalVendors,
                if_external_vendors_will_be_utilized_ha: e.externalVendorsNDA,
                will_the_event_involve_any_sporting_acti: e.sportsInjury,
                will_there_be_the_need_to_have_any_doors: e.doorsPropped,
                will_alcohol_be_served: e.alcohol,
                is_catering_needed: e.catering,
                i_acknowledge: e.acknowledge,
                rrule: e.recurring === 'null' ? null : e.recurring,
                center_id: 1,
                briefing_type_id: 1,
                status_id: 2,
                submit: true
            }
          
        };

        if(data && !fromMap) {
            config.data.status_id = undefined;
            config.data.center_id = undefined;
            config.data.briefing_type_id = undefined;
        }
        try {
            let response = await axios(config);
            const responseData = response.data;
            if (!(responseData.data)) {
                throw responseData;
            }

            setMessageObject({ 
                value: true,  
                body: <div className='hasSubmittedTicket'>
                        <CheckCircleOutlined />
                        <h4>Thank You</h4>
                        <div>Your request has been {data && !fromMap ? 'updated ' : 'created '} successfully. An event manager will be in contact with you within 48 hours to discuss your event plan.</div>
                    </div> 
            });
            setShowEventModal(true);
            if(!data && fromMap) {
                form.resetFields();
            }

        } catch (e) {
            setMessageObject({ 
                value: true,  
                body: <div className='hasSubmittedTicket'>
                        <div>{e?.error?.message ? e?.error?.message : 'Error occured. Unable to submit an event.' }</div>
                    </div> 
            });
            setShowEventModal(true);
        }
        setLoading(false);
    };

    
    const toggleEventModal = () => {
        setShowEventModal(false);
        setMessageObject({});
    };

    const getMessageModal = (messageObject) => {
        return <Modal
            title="Book an event"
            centered
            visible={showEventModal}
            onOk={() => toggleEventModal()}
            onCancel={() => toggleEventModal()}
            footer={
                [
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => history.push('/my-reservations') }
                    >
                       My Reservations
                    </Button>
                ]}
        >
            <div style={{marginBottom: '8px'}}>{messageObject.body}</div>
        </Modal>;
    };

    // const toggleAvailability = (e) => {
    //     e.preventDefault();
    //     setShowAvailability(true);
    // }

    const isSameDate = (dateTime1, dateTime2) => {
        return dateTime1.month === dateTime2.month && dateTime1.day === dateTime2.day && dateTime1.year === dateTime2.year;
    };

    const getDisabledDate = (date, hqDates) => {
        const currentDate = DateTime.now();
        const dateObject = DateTime.fromJSDate(date.toDate());
        const maxFutureDate = currentDate.plus({days: 90});
        if(dateObject > maxFutureDate) {
            return true;
        }
        if(date && (dateObject  < currentDate)) {
            return true;
        } else {
            const index = hqDates.findIndex(d => {
                const hqDateObj = DateTime.fromSQL(d.date);
                if(isSameDate(hqDateObj, dateObject) && d.available) {
                    return true;
                } else {
                    return false;
                }

            });

            if(index !== -1) {
                return false;
            } else {
                return true;
            }
        }
    };



    return <><div className="room-reservation-form">
        <Card title={hideHeader ? null : 'Meeting Details'} style={{ width: '100%' }}>
            <Form
                name="room-reservation-form"
                {...layout}
                initialValues={{ remember: true }}
                onFinish={onFormSubmit}
                onFinishFailed={() => { }}
                autoComplete="off"
                form={form}>
                <Form.Item
                    label="Event name"
                    name="eventName"
                    initialValue={data?.title}
                    rules={[{ required: true, message: 'Please input event name!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Prefered Location"
                    name="preferedLocation"
                    initialValue={data ? data?.briefing_detail?.preferred_location_hq : null}
                    rules={[{ required: true, message: 'Please select Prefered location!' }]}>
                    <Select onChange={(value) => setSelectedLocation(value)}>
                        <Option value="MPR">MPR</Option>
                        <Option value="Community Space">Community Space</Option>
                        {! window.location.href.includes('hq-event') && <Option value="Neighborhood">Neighborhood</Option>}
                    </Select>
                </Form.Item>
                {(selectedLocation === 'Neighborhood' || data?.briefing_detail?.preferred_location_hq === 'Neighborhood') &&  <Form.Item
                    label="Preferred Neighborhood Location/Combination"
                    name="preferedNeighborhoodLocation"
                    initialValue={data ? data?.briefing_detail?.preferred_neighborhood_location_combinat : null}
                    rules={[{ required: true, message: 'Please select Preferred Neighborhood location!' }]}>
                    <Select>
                        {neighborhoods.map(item => <Option key={item} value={item}>{item}</Option>) } 
                    </Select>
                </Form.Item>}
                <Alert message="Requester Details" type="info" />
                <br />
                <Form.Item
                    label="Requester's name"
                    name="requesterName"
                    initialValue={ data && data?.requestor_name ? data?.requestor_name : authState.idToken.claims.name}
                    rules={[{ required: true, message: 'Please input requester\'s name!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Requester's Email"
                    name="requesterEmail"
                    initialValue={ data && data?.requestor_email ? data?.requestor_email :  authState.idToken.claims.email }
                    rules={[{ required: true, message: 'Please input requester\'s email!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Requester's Mobile Number"
                    name="requesterPhone"
                    initialValue={data?.requestor_phone}
                    rules={[{ required: true, message: 'Please input requester\'s mobile number!' }]}>
                    <Input />
                </Form.Item>
                <Alert message="Scheduling Details" type="info" />
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '13%' }}>
                    <div style={{flex: 2}}>
                        <Form.Item
                            style={{ marginBottom: '5px' }}
                            labelCol={{ span: 12 }}
                            label="Proposed Start Date"
                            name="proposedStartDate"
                            initialValue={data && data?.preferred_date ? moment(data?.preferred_date) : null}
                            rules={[{ required: true, message: 'Please input Proposed Start Date!' }]}>
                            <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: '230px' }}
                                onChange={(selectedDate) => {
                                    form.setFieldsValue({
                                        proposedEndDate: selectedDate
                                    });
                                    getAvailableRooms(selectedDate);
                                }}
                                disabledDate={current=> {
                                    return getDisabledDate(current, hqDates);
                                }}
                            />
                        </Form.Item>
                        {/* <div style={{ width: '100%', marginLeft: '50%' }}>
                            <a href="#" rel='noreferrer' className="check-availability">CHECK AVAILABILITY</a>
                        </div> */}
                    </div>
                    <div style={{flex: 1}} >
                        <Form.Item
                            labelCol={{ span: 3 }}
                            label="to"
                            name="proposedEndDate"
                            initialValue={data && data?.preferred_end_date ? moment(data?.preferred_end_date) : null}
                            rules={[{ required: true, message: 'Please input Proposed End Date!' }]}>
                            <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: '230px' }}
                                disabledDate={current=> {
                                    return getDisabledDate(current, hqDates);
                                }}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '13%', marginTop: '8px' }}>
                    <div style={{flex: 2}}>
                        <Form.Item
                            style={{ marginBottom: '5px' }}
                            labelCol={{ span: 12 }}
                            label="Proposed Time"
                            name="proposedStartTime"
                            initialValue={data && data?.preferred_start_time ? moment(data?.preferred_start_time, 'HH:mm:ss') : null}
                            rules={[{ required: true, message: 'Please input Proposed Start Time!' }]}>
                            <TimePicker
                                format='hh:mm A'
                                style={{ width: '230px' }}
                            />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Item
                            labelCol={{ span: 3 }}
                            label="to"
                            name="proposedEndTime"
                            initialValue={data && data?.preferred_end_time ? moment(data?.preferred_end_time, 'HH:mm:ss') : null}
                            rules={[{ required: true, message: 'Please input Proposed End Time!' }]}>
                            <TimePicker
                                format='hh:mm A'
                                style={{ width: '230px' }}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div style={{ display: 'flex', marginLeft: '13%', justifyContent: 'flex-end' }}>
                    <div style={{ flex: 2 }}>
                        <Form.Item
                            style={{ marginBottom: '5px' }}
                            labelCol={{ span: 12 }}
                            label="Alternate Start Date"
                            name="alternateStartDate"
                            initialValue={data && data?.alternate_date ? moment(data?.alternate_date) : null}
                           >
                            <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: '230px' }}
                                onChange={(selectedDate) => {
                                    form.setFieldsValue({
                                        alternateEndDate: selectedDate
                                    });
                                }}
                                disabledDate={current=> {
                                    return getDisabledDate(current, hqDates);
                                }}
                            />
                        </Form.Item>
                        {/* <div style={{ width: '100%', marginLeft: '50%' }}>
                            <a href="#" rel='noreferrer' className="check-availability">CHECK AVAILABILITY</a>
                        </div> */}
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Item
                            labelCol={{ span: 3 }}
                            label="to"
                            name="alternateEndDate"
                            initialValue={data && data?.alternate_end_date ? moment(data?.alternate_end_date) : null}
                           >
                            <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: '230px' }}
                                disabledDate={current=> {
                                    return getDisabledDate(current, hqDates);
                                }}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: '8px', justifyContent: 'flex-end', marginLeft: '13%' }}>
                    <div style={{ flex: 2 }}>
                        <Form.Item
                            style={{ marginBottom: '5px' }}
                            labelCol={{ span: 12 }}
                            label="Alternate Time"
                            name="alternateStartTime"
                            initialValue={data && data?.alternate_start_time ? moment(data?.alternate_start_time, 'HH:mm:ss') : null}
                            >
                            <TimePicker
                                format='hh:mm A'
                                style={{ width: '230px' }}
                            />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Item
                            labelCol={{ span: 3 }}
                            label="to"
                            name="alternateEndTime"
                            initialValue={data && data?.alternate_end_time ? moment(data?.alternate_end_time, 'HH:mm:ss') : null}
                           >
                            <TimePicker
                                format='hh:mm A'
                                style={{ width: '230px' }}
                            />
                        </Form.Item>
                    </div>
                </div>
                <Alert message="Logistics" type="info" />
                <br />
                <Form.Item
                    label="Number of Attendees"
                    name="attendeesNum"
                    initialValue={data?.briefing_detail?.estimated_attendees}
                    rules={[{ required: true, message: 'Please input number of Attendees!' }]}
                >
                    <InputNumber  />
                </Form.Item>

                <Form.Item
                    label="Attendees Type"
                    name="attendeesType"
                    initialValue={data ? data?.briefing_detail?.attendees_type : null}
                    rules={[{ required: true, message: 'Please select Attendees Type!' }]}>
                    <Select>
                        <Option value="Internal">Internal</Option>
                        <Option value="External">External</Option>
                        <Option value="Both Internal & External">Both Internal & External</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Additional Information"
                    name="additionalInformation"
                    initialValue={data ? data?.briefing_detail?.additional_information : null}
                >
                    <TextArea rows={3} />
                </Form.Item>
                <Form.Item 
                    wrapperCol={{ offset: 10}}
                    name="attendeesAge" 
                    valuePropName="checked"
                    initialValue={data ? data?.briefing_detail?.all_attendees_are_over_the_age_of_18 : false}
                    rules={[{ required: true, message: 'Please select Attendees age!' }]}>
                    <Checkbox >All Attendees are over the age of 18 *</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Will there be any PANW executives attending or speaking?"
                    name="panwAttendees"
                    initialValue={data ? data?.briefing_detail?.will_there_be_any_panw_executives_attend : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Are there any VIP's attending the event?"
                    name="vipAttendees"
                    initialValue={data ? data?.briefing_detail?.are_there_any_vips_attending_the_event : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Will external people be attending the event?"
                    name="externalPeopple"
                    initialValue={data ? data?.briefing_detail?.will_external_people_be_attending_the_ev : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Will any external vendors be utilized?"
                    name="externalVendors"
                    initialValue={data ? data?.briefing_detail?.will_any_external_vendors_be_utilized : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="If external vendors will be utilized, have they been provided an NDA?"
                    name="externalVendorsNDA"
                    initialValue={data ? data?.briefing_detail?.if_external_vendors_will_be_utilized_ha : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Will the event involve any sporting activities that could result in injuries?"
                    name="sportsInjury"
                    initialValue={data ? data?.briefing_detail?.will_the_event_involve_any_sporting_acti : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Will there be the need to have any doors propped or will there be in/out capabilities for attendees?"
                    name="doorsPropped"
                    initialValue={data ? data?.briefing_detail?.will_there_be_the_need_to_have_any_doors : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Will Alcohol be Served? *If yes, a TIPS trained bartender will be required."
                    name="alcohol"
                    initialValue={data ? data?.briefing_detail?.will_alcohol_be_served : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Is Catering Needed?"
                    name="catering"
                    initialValue={data ? data?.briefing_detail?.is_catering_needed : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </Form.Item>
                <Alert
                    message={<p>Need additional AV/IT Support: (Link to Service Now form <a target="_blank" rel="noreferrer" href="https://panservicedesk.service-now.com/services?id=pan_sc_cat_item&sys_id=e48e69b91359a60092f9b3b12244b021">here</a>.)</p>}
                    type="info"
                    showIcon
                />
                <br />
                <Alert
                    message={<p>Please read and acknowledge our  <a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/17O_3VxdSBtq9Y6_uJOzoHbJ3S2_4kp64b49g9dSAjTw">HQ Events Guidelines</a></p>}
                    type="info"
                    showIcon
                />
                <br />
                <Form.Item 
                    wrapperCol={{ offset: 10}}
                    name="acknowledge" 
                    valuePropName="checked"
                    initialValue={data ? data?.briefing_detail?.i_acknowledge : false}
                    rules={[{ required: true, message: 'Please check to continue!' }]}>
                    <Checkbox >I acknowledge *</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Recurring"
                    name="recurring"
                    initialValue={data ? data?.rrule == null ? 'null' : data?.rrule : null}
                    rules={[{ required: true, message: 'Please select value!' }]}>
                    <Select>
                        <Option value="null">Does Not Repeat</Option>
                        <Option value="RRULE:INTERVAL=1;FREQ=DAILY">Daily</Option>
                        <Option value="RRULE:INTERVAL=1;FREQ=WEEKLY;BYDAY=MO">Weekly on Monday</Option>
                        <Option value="RRULE:INTERVAL=1;FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR">Every Weekday (Monday to Friday)</Option>
                        <Option value="RRULE:INTERVAL=1;FREQ=MONTHLY;BYSETPOS=1;BYDAY=MO">Monthly on Monday</Option>
                        <Option value="custom">Custom Recurrence...</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {data && !fromMap ? 'Update' : 'Submit'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Card>
    </div>
    {showEventModal && getMessageModal(messageObject)}
    </>;
};

export default withOktaAuth(RoomReservation);