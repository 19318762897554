import React, { useState } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Form, InputNumber, Layout, Radio, Input, DatePicker, Checkbox, Button, Modal, Select } from 'antd';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';
import axios from 'axios';


import EventNavigationbar from '../components/EventList/EventNavigationbar';
import env from '../env.json';
import Header from '../shared/Header';

const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;



const TELAvivEvent = ({ authState }) => {
    const [refreshMents, setRefreshMents] = useState(false);
    const [externalGuests, setExternalGuests] = useState(false);
    const [preInviteGuests, setPreInviteGuests] = useState(false);
    const [itAvSupport, setItAvSupport] = useState(false);
    const [externalGuestsType, setExternalGuestsType] = useState('');
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [eventSysId, setEventSysId] = useState('');
    const [ticketNumber, setTicketNumber] = useState('');
    const [dryRun, setDryRun] = useState(false);
    const [offsiteEvent, setOffsiteEvent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFormSubmit = async (e) => {
        setLoading(true);
        let config = {
            method: 'post',
            url: `${env.BACKEND_URL}/create-tel-aviv-event`,
            headers: { 'Authorization': authState.accessToken.value },
            data: {
                callerEmail: authState.idToken.claims.email || '',
                number_people: e.peopleNum,
                refreshments: e.refreshments ? 'Yes' : 'No',
                vendor_name: e.vendorName,
                escort_vendor: e.vendorEscort,
                datetime_event: e.dateTime.format('MM-DD-YYYY HH:mm:ss'),
                time_setup_completed: e.dateTimeCompleted.format('MM-DD-YYYY HH:mm:ss'),
                time_setup_takendown: e.dateTimeCanTakenDown.format('MM-DD-YYYY HH:mm:ss'),
                short_description: e.shortDescription,
                description: getFinalDescription(e),
                onsite_owner: e.onSiteOwner,
                department: e.department,
                guests_external: e.externalGuests ? 'Yes' : 'No',
                remote_attendees: e.remoteAttendeesNum ? e.remoteAttendeesNum : '',
                invite_sent: e.inviteSentMethod ? e.inviteSentMethod : '',
                guests_vip: e.vip ? 'Yes' : 'No',
                // parking_vouchers: e.requireParkingVouchers ? 'Yes' : 'No',
                preinvite: e.preInvite ? 'Yes' : 'No',
                names_and_emails: e.nameAndEmailList ? e.nameAndEmailList : '',
                security_requests: e.securityRequests,
                it_av_support: e.itAvSupport ? 'Yes' : 'No',
                u_it_field: e.itAvSupport ? 'Yes' : 'No',
                event_name: e.eventName ? e.eventName : '',
                room: e.room ? e.room : '',
                dry_run: e.dryRun ? 'Yes' : 'No',
                dry_run_date: e.dryRunDate ? e.dryRunDate.format('MM-DD-YYYY HH:mm:ss') : '',
                offsite_event: e.offsiteEvent ? 'Yes' : 'No',
                name: e.name ? e.name : '',
                phone: e.phone ? e.phone : '',
                email: e.email ? e.email : '',
                chk_speakers: e.chkSpeakers ? 'Yes' : 'No',
                recorded: e.recorded ? 'Yes' : 'No',
                other_equipment: e.otherEquipment ? e.otherEquipment : ''
            }
        };
        try {
            let response = await axios(config);
            const data = response.data;
            if (!(data.data)) {
                throw data;
            }
            setEventSysId(data.data.result.sys_id);
            setTicketNumber(data.data.result.number);
            setShowSubmitModal(true);
            form.resetFields();

        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    const getFinalDescription  = (e) => {
        let finalDescription = e.description;
        finalDescription += '\n-----------------------------------------';
        finalDescription+= `\n Event Name : ${e.eventName}`;
        finalDescription+= `\n Conference Room Name : ${e.room}`;
        finalDescription+= `\n Date and Time of event : ${e.dateTime.format('MM-DD-YYYY HH:mm:ss')}`;
        finalDescription+= `\n Date and time set up needs to be completed : ${e.dateTimeCompleted.format('MM-DD-YYYY HH:mm:ss')}`;
        finalDescription+= `\n Date and time set up can be taken down : ${e.dateTimeCanTakenDown.format('MM-DD-YYYY HH:mm:ss')}`;
        finalDescription+= `\n Short description : ${e.shortDescription}`;
        finalDescription+= `\n Number of People : ${e.peopleNum}`;
        finalDescription+= `\n Refreshments : ${e.refreshments ? 'Yes' : 'No'}`;
        finalDescription+= `\n Vendor Name : ${e.vendorName ? e.vendorName : ''}`;
        finalDescription+= `\n Who will escort the vendor? : ${e.vendorEscort ? e.vendorEscort : ''}`;
        finalDescription+= `\n Name of the event owner : ${e.onSiteOwner}`;
        finalDescription+= `\n Department : ${e.department}`;
        finalDescription+= `\n Phone number of the event owner : ${e.phone}`;
        finalDescription+= `\n Are guests external? : ${e.externalGuests ? 'Yes' : 'No'}`;
        finalDescription+= `\n Number of external attendees : ${ e.remoteAttendeesNum ? e.remoteAttendeesNum : ''}`;
        finalDescription+= `\n How was the invite sent? : ${ e.inviteSentMethod ? e.inviteSentMethod : ''}`;
        finalDescription+= `\n Source of information : ${ e.sourceOfInfo ? e.sourceOfInfo : ''}`;
        finalDescription+= `\n Are any of guests VIP? : ${e.vip ? 'Yes' : 'No'}`;
        // finalDescription+= `\n Will the guests require parking vouchers? : ${e.requireParkingVouchers ? 'Yes' : 'No'}`;
        finalDescription+= `\n Would you like to send a pre-invite to the guests? With the option to register in advance will shorten the check-in process upon arrival : ${e.preInvite ? 'Yes' : 'No'}`;
        finalDescription+= `\n Additional security requests : ${e.securityRequests}`;
        finalDescription+= `\n Do you need IT/AV support? : ${e.itAvSupport ? 'Yes' : 'No'}`;
        finalDescription+= `\n Dry Run (Y/N) *We highly recommend a dry run for your event. : ${e.dryRun ? 'Yes' : 'No'}`;
        finalDescription+= `\n Dry Run Date : ${e.dryRunDate ? e.dryRunDate.format('MM-DD-YYYY HH:mm:ss') : ''}`;
        finalDescription+= `\n Is this an off site event? If so, please enter all related information including location and off site event contact details : ${e.offsiteEvent ? 'Yes' : 'No'}`;
        finalDescription+= '\n ----Point of Contact Information----';
        finalDescription+= `\n Name : ${e.name ? e.name : ''}`;
        finalDescription+= `\n Email : ${e.email ? e.email : ''}`;
        finalDescription+= '\n ----Presentation Details----';
        finalDescription+= `\n Will Remote Attendees need to be able to speak? : ${e.chkSpeakers ? 'Yes' : 'No'}`;
        finalDescription+= `\n Will this Zoom Meeting/Webinar need to be recorded? : ${e.recorded ? 'Yes' : 'No'}`;
        finalDescription+= `\n Other equipment : ${e.otherEquipment ? e.otherEquipment : '' }`;

        return finalDescription;
    };



    return (
        <>

            <Header hideMyBadge={true}/>
            <div className='custom-bck inner-bck'>
                <EventNavigationbar navigationTitle='BOOK AN EVENT: TEL AVIV-YAFO' path='/book-an-event' />

                <Content style={{ padding: '0 50px', marginTop: 64, display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '1000px', padding: '20px', border: '1px solid #d9d9d9', borderRadius: '4px', marginBottom: 64, background: 'white' }}>
                        <Form
                            name="tel-aviv-event-form"
                            layout='vertical'
                            initialValues={{ remember: true }}
                            onFinish={onFormSubmit}
                            onFinishFailed={() => { }}
                            autoComplete="off"
                            form={form}
                        >
                             <Form.Item
                                label="Event name"
                                name="eventName"
                                rules={[{ required: true, message: 'Please input event name!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Conference Room Name (make sure you booked the room before submitting this ticket)"
                                name="room"
                                rules={[{ required: true, message: 'Please input Conference Room Name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Date and Time of event"
                                name="dateTime"
                                rules={[{ required: true, message: 'Please select Date and Time!' }]}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Date and time set up needs to be completed"
                                name="dateTimeCompleted"
                                rules={[{ required: true, message: 'Please select Date and Time!' }]}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Date and time set up can be taken down"
                                name="dateTimeCanTakenDown"
                                rules={[{ required: true, message: 'Please select Date and Time!' }]}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm"
                                    showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Short description"
                                name="shortDescription"
                                rules={[{ required: true, message: 'Please input Sort description!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Number of People"
                                name="peopleNum"
                                rules={[{ required: true, message: 'Please input number of people!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                label="Refreshments"
                                name="refreshments"
                                initialValue={refreshMents}

                            >
                                <Radio.Group onChange={(e) => setRefreshMents(e.target.value)} value={refreshMents}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>

                                </Radio.Group>
                            </Form.Item>

                            {refreshMents && <Form.Item
                                label="Vendor Name"
                                name="vendorName"
                                rules={[{ required: true, message: 'Please input Vendor Name!' }]}
                            >
                                <Input />
                            </Form.Item>}

                            {refreshMents && <Form.Item
                                label="Who will escort the vendor?"
                                name="vendorEscort"
                                rules={[{ required: true, message: 'Please input Vendor escort Name!' }]}
                            >
                                <Input />
                            </Form.Item>}

                           
                            <Form.Item
                                label="Description of an event"
                                name="description"
                                rules={[{ required: true, message: 'Please input description!' }]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>
                            <Form.Item
                                label="Name of the event owner"
                                name="onSiteOwner"
                                rules={[{ required: true, message: 'Please input name of the event owner!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Department"
                                name="department"
                                rules={[{ required: true, message: 'Please input department name!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Phone number of the event owner"
                                name="phone"
                                rules={[{ required: true, message: 'Please input phone number of the event owner!' }]}
                            >
                                <Input />
                            </Form.Item>
                           
                            <Form.Item
                                label="Are guests external?"
                                name="externalGuests"
                                initialValue={externalGuests}

                            >
                                <Radio.Group onChange={(e) => setExternalGuests(e.target.value)} value={externalGuests}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {externalGuests &&
                                <>
                                    <Form.Item
                                        label="Number of external attendees"
                                        name="remoteAttendeesNum"
                                        rules={[{ required: true, message: 'Please select remote attendees!' }]}
                                    >
                                        <Input />
                                        {/* <Select>
                                            <Option value="1-50">1-50</Option>
                                            <Option value="50-100">50-100</Option>
                                            <Option value="100+">100+</Option>
                                        </Select> */}

                                    </Form.Item>
                                    <Form.Item
                                        label="How was the invite sent?"
                                        name="inviteSentMethod"
                                    >
                                        <Radio.Group onChange={(e) => setExternalGuestsType(e.target.value)} value={externalGuestsType}>
                                            <Radio value='linkedIn'>Linked in</Radio>
                                            <Radio value='facebook'>Facebook</Radio>
                                            <Radio value='publicMedia'>Public media</Radio>
                                            <Radio value='other'>Other</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </>
                            }

                            {externalGuests && externalGuestsType && externalGuestsType === 'other' && <Form.Item
                                label="Source of information"
                                name="sourceOfInfo"
                                rules={[{ required: true, message: 'Please input source of information!' }]}
                            >
                                <Input />
                            </Form.Item>}

                            <Form.Item name="vip" valuePropName="checked">
                                <Checkbox >Are any of guests VIP?</Checkbox>
                            </Form.Item>

                            {/* <Form.Item name="requireParkingVouchers" valuePropName="checked">
                                <Checkbox onChange={() => { }}>Will the guests require parking vouchers?</Checkbox>
                            </Form.Item> */}

                            <Form.Item
                                label={<span>Would you like to send a pre-invite to the guests? For more information click <a href='https://docs.google.com/document/d/13HC4V0DFWeAPUzCdV9inPXjQzhItUhmXM0CqsM3lRj8/edit?usp=sharing' target='_blank' style={{textDecoration: 'underline'}}>here </a></span>}
                                name="preInvite"
                                initialValue={preInviteGuests}

                            >
                                <Radio.Group onChange={(e) => setPreInviteGuests(e.target.value)} value={preInviteGuests}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {/* {preInviteGuests && <Form.Item
                                label="Paste list here with names and email addresses"
                                name="nameAndEmailList"
                                rules={[{ required: true, message: 'Please input list of names with email!' }]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>} */}

                            {preInviteGuests && <div>
                                <p style={{marginBottom: 0}}>Please make a copy of this google spreadsheet <a style={{textDecoration: 'underline'}} target="_blank" rel="noreferrer" href="https://docs.google.com/spreadsheets/d/1CcRxdOmVOx6YQ2Z-ommsDXvjQSb4YxaPh2z8bNyeP2g/edit#gid=1079822528">here</a>. Fill out the contact info for your guests, and share it with <span style={{fontWeight: '600'}}>tlv-reception@paloaltonetworks.com</span>  (at least 2 business days prior to the event)</p>    
                            </div>}

                            {/* <Form.Item >
                                <Checkbox onChange={() => { }}>Additional security requests</Checkbox>
                            </Form.Item> */}
                            <p style={{marginBottom: 0, marginTop: '1em'}}><span style={{fontSize: '14px', color: '#ff4d4f', display: 'inline-block', marginRight: '4px'}}>*</span>Additional security requests</p>
                            <Form.Item
                                label="Please contact the SAS team if you need assistance with escorting once you submit this ticket"
                                name="securityRequests"
                                rules={[{ required: true, message: 'Please input additional security requests!' }]}
                            >
                                <TextArea rows={3} />
                            </Form.Item>

                            <Form.Item
                                label="Do you need IT/AV support?"
                                name="itAvSupport"
                                initialValue={itAvSupport}

                            >
                                <Radio.Group onChange={(e) => setItAvSupport(e.target.value)} value={itAvSupport}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {itAvSupport &&
                                <>
                                   

                                    <Form.Item
                                        label="Dry Run (Y/N) *We highly recommend a dry run for your event."
                                        name="dryRun"
                                        initialValue={dryRun}

                                    >
                                        <Radio.Group onChange={(e) => setDryRun(e.target.value)} value={dryRun}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    {dryRun && <Form.Item
                                        label="Dry Run Date"
                                        name="dryRunDate"
                                        rules={[{ required: true, message: 'Please select Date and Time!' }]}
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD HH:mm"
                                            showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>}

                                    <Form.Item
                                        label="Is this an off site event? If so, please enter all related information including location and off site event contact details"
                                        name="offsiteEvent"
                                        initialValue={false}

                                    >
                                        <Radio.Group onChange={(e) => setOffsiteEvent(e.target.value)} value={offsiteEvent} >
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                   

                                    {offsiteEvent && <>
                                        <p>Point of Contact Information</p>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input name!' }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                  

                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, message: 'Please input Conference Room Name!' }]}
                                    >
                                        <Input />
                                    </Form.Item> </> }

                                    <p>Presentation Details</p>

                                    <Form.Item
                                        label="Will Remote Attendees need to be able to speak?"
                                        name="chkSpeakers"
                                        initialValue={false}

                                    >
                                        <Radio.Group >
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label="Will this Zoom Meeting/Webinar need to be recorded?"
                                        name="recorded"
                                        initialValue={false}

                                    >
                                        <Radio.Group >
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label="Other equipment"
                                        name="otherEquipment"
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            }



                            <Form.Item>
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Submit
                                    </Button>
                                </div>
                            </Form.Item>



                        </Form>
                    </div>
                </Content>
            </div>

            {showSubmitModal && <Modal
                title="Event is created"
                centered
                visible={showSubmitModal}
                onOk={() => setShowSubmitModal(false)}
                onCancel={() => setShowSubmitModal(false)}
                footer={[
                        <Button
                            key="submit" type="primary" onClick={() => setShowSubmitModal(false)}>
                            OK
                        </Button>
                    ]}
            >
                <div className='hasSubmittedTicket'>
                    <CheckCircleOutlined />
                    <div>Thank you for creating an event in Connected. Please refer to your ticket <a href="_" onClick={(e) => {
                        e.preventDefault();
                        //   window.open(`https://panservicedesk.service-now.com/esp?id=pan_sc_cat_item&sys_id=${eventSysId}`, '_blank');
                        window.open(`${env.SN_BASE_URL}/esp?id=ticket&table=facilities_request&sys_id=${eventSysId}&view=sp`, '_blank');
                    }} >{ticketNumber}</a> for further engagement</div>
                </div>

            </Modal>}
        </>
    );


};

export default withOktaAuth(TELAvivEvent);