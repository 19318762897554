import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './Dropdown.css';
const Dropdown = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [items, setItem] = useState(props.dropDownData);
    const [selectedItem, setSelectedItem] = useState(props.initialCampus);
    useEffect(() => {
        setItem(props.dropDownData);
    });
    const toggleDropdown = () => {
        setOpen(!isOpen);
    };

    const handleItemChange = (label) => {
        if (selectedItem != label) setSelectedItem(label);
        setOpen(false);
        props.selectedValue(label);
    };
    return (
        <div className={`dropdown ${isOpen && 'open-dropdown'}`}>
            <div className='dropdown-header' onClick={toggleDropdown}>
                {items.find(item => item.label == selectedItem).label}
                <FontAwesomeIcon icon={faCaretDown} />
            </div>
            <div className={`dropdown-body ${isOpen && 'open'}`}>
                {items.map(item => (
                    <div key={item.id} className={`dropdown-item ${item.label == selectedItem && 'selected'}`} onClick={() => handleItemChange(item.label)} id={item.id}>
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Dropdown;

