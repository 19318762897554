import React, { useRef, useState, useEffect } from 'react';

const Marker = (options) => {
    const [marker, setMarker] = React.useState(null);
  
    React.useEffect(() => {
      if (!marker) {
        setMarker(new window.google.maps.Marker());
      }

      if(marker) {

          window.google.maps.event.addListener(marker, 'click', function () {
                // const html = "<div><h3>" + title + "</h3><p>" + address + "<br></div><a href='" + url + "'>View location</a></p></div>";
                const html = `<div>
                            <h3>${options.title}</h3>
                            
                            </div>`;
                const iw = new window.google.maps.InfoWindow({
                    content: html,
                    maxWidth: 350
                });
                iw.open(options.map, marker);
            });
      }
  
      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null);
          window.google.maps.event.removeListener(marker,'click');
        }
      };
    }, [marker]);
  
    React.useEffect(() => {
      if (marker) {
        marker.setOptions(options);
      }
    }, [marker, options]);
  
    return null;
  };

const GoogleMap = ({ campus }) => {
    const ref = useRef();
    const [map, setMap] = useState(null);
    const [zoom] = useState(15);
    const [center, setCenter] = useState(null);
    const [markers, setMarkers] = useState([]);

    // const [bounds] = useState(new window.google.maps.LatLngBounds());

    const getLatLngFromAddress = (address, callback) => {
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({
            'address': address,
        }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                callback(results[0].geometry.location, results[0]);
            } else {
                //Error handling
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    };

    const getMarkers = (locations) => {
        const locationsArray = locations.split('\n');
        const markersList = [];
        locationsArray.forEach(loc => {
            getLatLngFromAddress(loc, (latLng) => {
                
                // const newMarker = new window.google.maps.Marker({
                //     icon: 'http://maps.google.com/mapfiles/ms/icons/blue.png',
                //     map: map,
                //     position: latLng,
                //     title: loc,
                //     animation: window.google.maps.Animation.DROP,
                //     address: loc,
                // });
                // console.log(data);
                const newMarker = {
                    icon: 'http://maps.google.com/mapfiles/ms/icons/blue.png',
                    position: latLng,
                    title: loc,
                    animation: window.google.maps.Animation.DROP,
                    address: loc
                };

                markersList.push(newMarker);

                if(markersList.length === locationsArray.length) {
                    setMarkers([...markers, ...markersList]);
                }
            });
        });
    };

    const getCenter = (address) => {
        getLatLngFromAddress(address, (latLng) => {
            setCenter(latLng);
            setMarkers([{
                    // icon: 'http://maps.google.com/mapfiles/ms/icons/yellow.png',
                    position: latLng,
                    title: address,
                    animation: window.google.maps.Animation.DROP,
                    address: address
            }]);
        });
    };

    // useEffect(() => {
    //     if(map && markers.length > 0) {
    //         markers.forEach(marker => {
    //             bounds.extend(marker.position);
    //             map.fitBounds(bounds);
    //         });
    //     }
    // }, [map, markers]);

    useEffect(() => {
        if(markers && markers.length === 1) {
            getMarkers(campus['Around Me']);
        }
    }, [markers]);

    useEffect(() => {
        getCenter(campus.Address);
    }, [campus]);

    useEffect(() => {
        if(center && map) {
            map.panTo(center);
        }
    }, [center, map]);


    useEffect(() => {
        if (center && ref.current && !map)  {
            setMap(new window.google.maps.Map(ref.current, {
                center,
                zoom,
                mapTypeControl: false,
                styles: [
                    {
                      featureType: 'administrative',
                      elementType: 'geometry',
                      stylers: [
                        {
                          visibility: 'off'
                        }
                      ]
                    },
                    {
                      featureType: 'poi',
                      stylers: [
                        {
                          'visibility': 'off'
                        }
                      ]
                    },
                    {
                      featureType: 'road',
                      elementType: 'labels.icon',
                      stylers: [
                        {
                          visibility: 'off'
                        }
                      ]
                    },
                    {
                      featureType: 'transit',
                      stylers: [
                        {
                          visibility: 'off'
                        }
                      ]
                    }
                  ]
            }));
        }
    }, [center, ref, map]);

    return <>
        <div ref={ref} id='map' style={{minHeight: '400px'}} />
        {map && markers.map((marker, index) => {
            return <Marker key={marker.address + index} map={map} {...marker} />;
        } )}
        
    </>;
};


export default GoogleMap;