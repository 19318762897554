import React, {useState} from 'react';
import MyBookings from '../../components/MyBookings';
import Logo from './../../assets/images/icons/index_logo.svg';
import './header.css';
import flexwork_icon from '../../assets/images/flexwork_icon.svg';
import MyNotifications from '../../components/BookADesk/MyNotifications';
import {withRouter} from 'react-router-dom';
import useGAEventsTracker from '../../hooks/useGAEventsTracker';

const gAEventTracker = useGAEventsTracker('Header menu event');

const Header = (props) => {
    const [showMyBookingsModal] = useState(window.location.search === '?myreservations=true');

    const showReservations = () => {
        gAEventTracker('My reservation button click');
        props.history.push('/my-reservations');
        // setShowMyBookingsModal(true);
    };

    const hideReservations = () => {
        props.history.push('/my-reservations');
        // setShowMyBookingsModal(false);
    };


    const getHeaderClasses = () => {
        const classes = ['header'];

        if(props.home) {
            classes.push('header-home');
        }

        return classes.join(' ');
    };

    return (
        <React.Fragment>
            <header className={getHeaderClasses()}>
                <div className={props.home ? "content-wrap custom-container custom-container-home" : "content-wrap custom-container"}>
                    <div className="main-logo" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <img src={flexwork_icon} alt='flexwork_icon' style={{marginRight: 20}}/>
                        <div src={Logo} alt="Logo" style={{textTransform: 'uppercase', fontWeight: 700, fontSize: '30px'}} onClick={() => {
                            if(!props.home) {
                                props.history.push('/');
                            }
                        }}>Connected</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div className="link my-reservations rounded-button" onClick={showReservations} >
                            My Reservations
                        </div>
                         {/*<MyBadge/>*/}
                        {!props.hideMyBadge && <MyNotifications/>}
                    </div>

                </div>
            </header>
            <MyBookings isOpen={showMyBookingsModal} onClose={hideReservations} />

        </React.Fragment>
    );
};

export default withRouter(Header);
