import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './Dropdown.css';
const GroupDropdown = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [items, setItem] = useState(props.dropDownData);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemName, setSelectedItemName] = useState('Building 1, Floor 1');
    
    //console.log(Object.values(items)[0]);
    useEffect(() => {
        setItem(props.dropDownData);
    });
    const toggleDropdown = () => {
        setOpen(!isOpen);
    };

    //console.log(selectedItem);
    const handleItemClick = (id, item) => {
        selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
        setOpen(false);
        setSelectedItemName(item);
        props.selectedValue(item);
    };
    return (
        <>
            <div className={`dropdown ${isOpen && 'open-dropdown'}`}>
                <div className='dropdown-header' onClick={toggleDropdown}>
                    {selectedItemName}
                    <FontAwesomeIcon icon={faCaretDown} />
                </div>
                <div className={`dropdown-body ${isOpen && 'open'}`}>
                    

                    {Object.keys(items).map((keyName, i) => (
                        <div key={Object.keys(items)[i]}>
                            <div className="dropdown-item group-dropdown-item" >{Object.keys(items)[i]}</div>
                            {Object.values(items)[i].map(item => (
                                <div key={item.id} className={`group-dropdown ${item.id == selectedItem && 'selected'}`} onClick={e => handleItemClick(e.target.id, item.name)} id={item.id}>
                                    <div className="dropdown-item">{item.name}</div>
                                    <div className="dropdown-item available-seat">Available: 6 Self-Services</div>
                                </div>
                            ))}
                        </div>
                    ))}
                       
                </div>
            </div>
            
        </>
    );
};
export default GroupDropdown;

