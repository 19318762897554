import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import { FindEventContext } from '../../pages/FindAnEvent';

const FindAnEventCheckBox = ({item, onChange}) => {
    const { colorMap } = useContext(FindEventContext);

    return (
        <div style={{marginRight: "2rem", display: "flex"}}>
            <Checkbox checked={item.checked} onChange={(e) => onChange(item.id, e.target.checked)} />
            <span style={{fontWeight: "bold", color: colorMap[item.name]?.filter ?? "blue", marginLeft: ".5rem"}}>{item.name}</span>
        </div>
    );
};

export default FindAnEventCheckBox;