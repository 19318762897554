import React from 'react';
import { Button, Slider } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const MapToolbar = ({zoomIn, zoomOut, resetTransform, currentZoom, setCurrentZoom }) => {
    // const [zoom, setZoom] = useState(1);

    const onZoomChange= (val) => {
        if(val > 20 || val < 1) {
            return;
        }

        if(val > currentZoom) {
            // let diff = val - currentZoom;
            // for(let i = 0; i< diff; i++) {
            //     zoomIn();
            // }
            
            zoomIn();

        } else {
            // let diff = currentZoom - val;
            // for(let i = 0; i< diff; i++) {
            //     zoomOut();
            // }
            zoomOut();
        }

        setCurrentZoom(val);
    };

 
    
    return (
        <div className='map-toolbar' style={{
            position: 'fixed',
            left: '0',
            right: '0',
            margin: 'auto',
            zIndex: '1',
            textAlign: 'center',
            bottom: window.innerWidth > 600 && window.innerWidth < 1500 ? '20px' :  '50px',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <div style={{ backgroundColor: '#eaf0f4', display: 'flex' }}>

                <Button style={{ color: '#8e979f' }} icon={<MinusOutlined />} type="text" onClick={() => {
                    if(currentZoom > 0) {
                        setCurrentZoom(currentZoom -1);
                    }
                    zoomOut();
                } } />
                <div style={{ width: '200px', marginLeft: '5px', marginRight: '5px' }}>

                    <Slider
                        defaultValue={1}
                        min={1}
                        max={20}
                        onChange={onZoomChange}
                        value={currentZoom}  
                        tooltipVisible={false}
                    />
                </div>
                <Button style={{ color: '#8e979f' }} icon={<PlusOutlined />} type="text" onClick={() => {
                    if(currentZoom < 20) {
                        setCurrentZoom(currentZoom + 1);
                    }
                    zoomIn();
                    
                }} />

                <Button style={{ color: '#8e979f' }} type="text" onClick={() =>  {
                    resetTransform();
                    setCurrentZoom(1);
                    }}>Reset</Button>
            </div>
        </div>
    );
};

export default MapToolbar;