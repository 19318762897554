import React, { useState } from 'react';
import LabelHeader from './../components/LabelHeader';
import HomeEventCard from './HomeEventCard';
import CloseIcon from './../assets/images/bookEvent/close_white.svg';
import auditorium from './../assets/images/bookEvent/auditorium.svg';
import boardroom from './../assets/images/bookEvent/boardroom.svg';
import classroom from './../assets/images/bookEvent/classroom.svg';
import ushape from './../assets/images/bookEvent/u-shape.svg';
import ProgressBar from './ProgressBar';


const ConfigurationSpace = (props) => {
    const [isHome, setHome] = useState(false);
    const navigateHome = () => {
        setHome(!isHome);
        props.onhandleClose();
    };
    return (
        <React.Fragment>
            {isHome === false ? <div className='flex-event-bg'> <div className="close-icon" onClick={navigateHome}><img src={CloseIcon} /></div>
                <div className="flexevt-card-btn-wrap">
                    <LabelHeader labelNum='4' labelPath='' labelContent='What is your preferred set-up configuration?' />
                    <div className="flexevt-card-inner-wrap quarter-inner-card">
                        <div onClick={()=>props.navigateEventList('Auditorium')} className="flexevt-card-btn step-modal-card" ><img src={auditorium} alt="auditorium" /><p>Auditorium</p></div>
                        <div  onClick={()=>props.navigateEventList('Boardroom')}className="flexevt-card-btn step-modal-card"><img src={boardroom} alt="boardroom" /><p>Boardroom</p></div>
                        <div  onClick={()=>props.navigateEventList('Classroom')}className="flexevt-card-btn step-modal-card class-icon"><img src={classroom} alt="classroom" /><p>Classroom</p></div>
                        <div onClick={()=>props.navigateEventList('UShape')} className="flexevt-card-btn step-modal-card"><img src={ushape} alt="ushape" /><p>U-Shape</p></div>
                    </div>
                    <div className="bar-position">
                        <ProgressBar filledPercentage='75%' />
                    </div>
                </div>
            </div> : <HomeEventCard />}
        </React.Fragment>
    );
};
export default ConfigurationSpace;
