import React from 'react';
import Logo from './../../../assets/images/icons/index_logo.svg';
import './EventHeader.css';
import {withRouter} from 'react-router';
import {withOktaAuth} from '@okta/okta-react';

class EventHeader extends React.Component {
    render() {
        return (
            <header className="event-header">
                <div className="content-wrap custom-container">
                    <div className="main-logo">
                        <img src={Logo} alt="Logo" onClick={() => this.props.history.push('/')} style={{cursor: 'pointer'}}/>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(withOktaAuth(EventHeader));
