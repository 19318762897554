import React from 'react';
import FindAnEventCalendarRow from './FindAnEventCalendarRow';

const FindAnEventCalendar = ({ dates }) => {
    return (
        <div className="find-an-event-calendar" style={{display: "flex", marginTop: "2rem"}}>
            {dates?.map((date, index) => <FindAnEventCalendarRow date={date} key={index + 1}/>)}
        </div>
    );
};

export default FindAnEventCalendar;