import React, {useState, useEffect} from 'react';
import {Modal, Button, Spin, Divider, Select } from 'antd';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import env from '../../env';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import useDebounce from '../../hooks/useDebounce';

import BookDesk from '../../assets/images/bookEvent/book_desk.svg';
import DeskImage from '../../assets/images/desk-image.png';
import TelAvivDeskImage from '../../assets/images/telAviv-desk-image.png';
import Slack from '../../assets/images/bookEvent/slack.svg';
import Edit from '../../assets/images/bookEvent/edit.svg';
import PrivateOfficeImage from '../../assets/images/private-office.jpg';
import './BookADeskModal.css';

const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

const BookADeskModal = ({
    show, 
    onToggle, 
    onSubmit, 
    onMoveBooking,
    onCancelBooking, 
    messageObject, 
    currentNode, 
    loading,
    isDeskAvailable,
    canMove,
    site,
    floor,
    employeeEmail,
    onEmployeeEmailChange,
    history,
    authState,
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    
    useEffect(async () => {
          if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
            setSearchLoading(true);
            let config = { method: 'get', url: `${env.BACKEND_URL}/search-employees?query=${debouncedSearchTerm}`, headers: { 'Authorization': authState.accessToken.value } };
            let response = await axios(config);
            setResults(response.data.data);
            setSearchLoading(false);
          } else {
            setResults([]);
          }
        },[debouncedSearchTerm]);

    const navtomyres = () => {
        history.push('/my-reservations');
    };
    
    const isOfficePrivateRoom = (node) => {
        if(node.spaceType && node.spaceType.name === 'office_private') {
            return true;
        }

        return false;
    };

    const isBookedByMe = (node) => {
        return node.dailyBookableSpacesAvailability[0]?.bookings[0]?.employee.email === authState.idToken.claims.email;
    };

    const getLoader = () => {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '16em' }}><Spin indicator={loaderIcon} /></div>;
    };

    const getTitle = (text) => {
        return <div><span><img src={BookDesk} alt="BookDesk" style={{maxWidth: '1.6rem', marginRight: '2em'}} /></span><span>{text}</span></div>;
    };

    const getModalContent = (title, content, footer) => {
        return <div className="booking-modal-container">
            <div className="booking-modal-header-wrapper">
                <div className="booking-modal-header">
                    {title}
                </div>
                <div className="booking-modal-content">{content}</div>
            </div>
            <div className="booking-modal-footer">
                {footer}
            </div>
        </div>;
    };

    const getDeskImage = () => {
        if(site === 'Tel Aviv') {
            return TelAvivDeskImage;
        }

        if(isOfficePrivateRoom(currentNode)) {
            return PrivateOfficeImage;
        }
        return DeskImage;
    };

    const getDeskContent = () => {
        const content =  <div className="booking-modal-body">
                <div style={{display: 'flex', margin: '-0.5rem -1rem'}}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '120px', padding: '1rem', backgroundColor: '#F4F4F2', borderRight: '1px solid #dddddd', marginRight: '20px', overflow: 'hidden'}}>
                        <div style={{width: '90%', borderRadius: '10px', overflow: 'hidden', marginBottom: '1rem'}}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20px', background: '#FA582D', color: 'white'}}>
                                <span style={{fontSize: '10px', marginLeft: 0, marginTop: 2}}>Scheduled</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px', background: 'white'}}>
                                <span style={{fontSize: '13px', marginLeft: 0, textAlign: 'center', lineHeight: '1rem', fontWeight: 'bold', marginTop: '-3px'}}>
                                    All-Day Only
                                </span>
                            </div>
                        </div>
                        <img  src={getDeskImage()} style={{borderRadius: '8px', width: '100%', maxHeight: '100px', objectFit: 'cover'}} alt='Meeting-room-placeholder' />
                    </div>
                    <div style={{flex: 1, padding: '1rem 1.5rem'}}>
                        <h1 style={{marginBottom: 0}}>{site}</h1>
                        <h2 style={{fontWeight: 400, fontSize: '1.2rem'}}>{floor}, {currentNode.code}</h2>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontSize: 12, color: '#807C7B', marginRight: '1rem'}}><b>Department:</b></span>
                            <p style={{fontWeight: 400, fontSize: '.9rem', marginBottom: 0}}>{currentNode.spaceAssignment.department ? <span>{currentNode.spaceAssignment.department.name}</span> : <span>Open to all</span>}</p>
                        </div>
                        {/* <p><b>Floor: </b><span>{floor}</span></p>
                        <p><b>Desk: </b><span>{currentNode.code}</span></p>
                        <p><b>Time:</b>{isDeskAvailable(currentNode) ? <span>All-Day Only</span> : <span>This desk is booked by another user</span>}</p>

                        <p><b>Assigned Department:</b>{currentNode.spaceAssignment.department ? <span>{currentNode.spaceAssignment.department.name}</span> : <span>Open to all</span>}</p> */}
                        {/* { currentNode.dailyBookableSpacesAvailability[0]?.availability && <p>Assets: {currentNode.tags.length > 0 ? <Button type="link" onClick={() => setShowAssetsList(true)}>List</Button> : <span>None</span> }</p>} */}
                        {/* <div><b>Dates: </b> <MultipleDatePicker value={bookingDates} onChange={setBookingDates} availableDates={desksAvailableDates.length > 0 ? getCurrentNodeAvailableDates() : []} /></div> */}
                        {isDeskAvailable(currentNode) && <div style={{display: 'flex', marginTop: '1rem'}}>
                            <span style={{fontSize: 12, color: '#807C7B', marginRight: '1rem'}}><b>Assets:</b></span>
                            <div style={{height: '75px', border: '1px solid #ddd', borderRadius: '8px', padding: '.5rem', fontSize: 12, overflowY: 'auto', width: '100%' }}>
                                {currentNode.tags.length > 0 ? currentNode.tags.map(asset => asset.name).join(', ') : 'None'}
                            </div>
                        </div>}
                        {/* <p><b>Book on someone&apos;s behalf (optional):</b><Input value={employeeEmail} onChange={(e) => onEmployeeEmailChange(e.target.value) } style={{borderRadius: '8px'}} placeholder="Enter employee's email address here" id='book-on-behalf-employee-email'/></p> */}
                        <div style={{marginTop: '1rem'}}><span style={{fontSize: 12, color: '#807C7B', marginRight: '1rem'}}><b>Book on someone&apos;s behalf (optional):</b></span>
                            <Select 
                                value={employeeEmail !== '' ? employeeEmail : null } 
                                onChange={(value) => onEmployeeEmailChange(value) } 
                                style={{borderRadius: '8px', width: '100%'}} 
                                placeholder="Enter employee's name or email" 
                                id='book-on-behalf-employee-email'
                                className='booking-modal-select'
                                showSearch
                                filterOption={false}
                                loading={searchLoading}
                                defaultActiveFirstOption={false}
                                onSearch={(value) => setSearchTerm(value)}>
                                    {results.map((employee) => <Option key={employee.email}>
                                    <div style={{paddingBottom: '.5rem'}}>
                                        <p style={{marginBottom: 0}}>{employee.firstName + ' ' + employee.lastName }</p>  
                                        <p style={{marginBottom: 0, fontWeight: 'bold'}}>{employee.email}</p>
                                    </div>
                                    </Option>)}
                            </Select>
                        </div>
                    </div>
                </div>
              

        </div>;

        const footer = <div>
             { canMove 
                && currentNode.dailyBookableSpacesAvailability.length 
                && !currentNode.dailyBookableSpacesAvailability[0].availability 
                && <Button className="mr-1" onClick={() => {
                        onToggle(false); 
                        onMoveBooking(true);
                    }} >
                    Move
                </Button> }
                {!canMove 
                    && currentNode.dailyBookableSpacesAvailability.length 
                    && !currentNode.dailyBookableSpacesAvailability[0].availability 
                    && isBookedByMe(currentNode) 
                    && <Button className="mr-1" onClick={() => {
                    onCancelBooking();
                    // onToggle(false); 
                }} >
                Cancel Booking
            </Button> }
                <Button
                    disabled={currentNode.dailyBookableSpacesAvailability[0]?.availability ? false : true}
                    key="submit" type="primary"  loading={loading} onClick={onSubmit}>
                    Book Now
                </Button>
        </div>;

        const title = getTitle('BOOK A SPACE - DESK SUMMARY');

        return getModalContent(title, content, footer);
    };

    const getMessageContent = () => {
        // const content = <div style={{marginBottom: '8px'}}>{messageObject.body}</div>;
        const content = <div style={{marginBottom: '8px', padding: '2rem 1rem'}}>
            {messageObject.bookingSuccessful && <>
                <h3 style={{color: '#52C41B', fontWeight: '600' }}>Thanks for booking a desk!</h3>
                <div style={{marginTop: '1.5em'}}>
                    <h2 style={{marginLeft: '2em'}}>What&rsquo;s next?</h2>
                    <Divider style={{borderWidth : 2, margin: 0}} />
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: '80%'}}>
                            <div style={{margin: '1rem 0' , display: 'flex'}}>
                                <img src={Slack} alt="slack" style={{maxWidth: '1.5rem', marginRight: '2em'}} />
                                <div style={{fontSize: 12}}>
                                    Reminders & updates will be sent via Slack for your reservation
                                </div>
                            </div>
                            <div style={{marginTop: '1rem', display: 'flex' }}>
                                <img src={Edit} alt="edit" style={{maxWidth: '1.5rem', marginRight: '2em'}} />
                                <div style={{fontSize: 12}}>
                                    You can always modify or delete your reservation under &nbsp;
                                    <span style={{color: '#FA582D', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navtomyres()}>&ldquo;My Reservations&rdquo;</span> 
                                    &nbsp; or Connected.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> }
            {!messageObject.bookingSuccessful && <>
                <div style={{marginTop: '1.5em'}}>
                        {messageObject.body}
                </div>
            </>}
        </div>;
        const footer = <div>
             <Button
                    key="submit"
                    type="primary"
                    onClick={messageObject.bookingSuccessful ? () => navtomyres() : () => location.reload() }
                >
                    {messageObject.bookingSuccessful ? 'My Reservations' : 'Refresh Page'}
                </Button>
        </div>;

        const title = getTitle('BOOK A SPACE - DESK ALL SET!');

        return getModalContent(title, content, footer);
    };


    return <Modal
        title={null}
        centered
        width="620px"
        visible={show}
        wrapClassName="ant-modal-flexspace"
        onOk={() => onSubmit()}
        onCancel={() => onToggle(false)}
        footer={null}
    >
        {
            messageObject.value
                ? getMessageContent()
                : loading
                    ? getLoader()
                    : getDeskContent()
        }

        <div style={{fontStyle: 'italic', fontSize: 10, marginTop: 10, marginLeft: 10, marginRight: 10}}>
            *For any ergo-related needs such as keyboards, mice, or chairs, please reach out to our concierge on 3.4.
            Please note, your seating assignment may change based on neighborhood reservations. A Places team member will contact you with an updated seat assignment.
        </div>
    </Modal>;
};

export default withRouter(withOktaAuth(BookADeskModal));
