import React from 'react';
import ArrowRightcon from './../assets/images/icons/arrow-right-solid.svg';
const LabelHeader=(props)=> {
return (
<div className='bne-label'>
<span className="label-text">{props.labelNum}</span>
<span className="arrow-right"><img src={ArrowRightcon} alt="Arrow Right icon" />{props.labelPath}</span>
<span className="label-text">{props.labelContent}</span>
</div>
);
};

export default LabelHeader;