import React, { useState, useEffect } from 'react';

const DynamicForm = (props) => {
    const [fieldValue, setFieldValue] = useState(props.field.value);

    if (props.field.name == props.optionalFieldsTrigger && props.field.value == 'no') {
        useEffect(() => { showOptionalFields(false); }, []);
    }

    const showOptionalFields = (trueFalse) => {
        props.optionalFields.forEach(id => {
            document.getElementById(id).style.display = trueFalse ? 'block' : 'none';
        });
    };

    const handleRadioValueChange = (e) => {
        setFieldValue(e.target.value);
        if (props.field.name == props.optionalFieldsTrigger) {
            showOptionalFields(e.target.value == 'no' ? false : true);
        }
    };

    switch (props.field.type) {
        case 'radio': {
            return (
                <div className="book-formg radio-block" id={props.field.name}>
                    <label className="rad-lbel"><span className="req-form">{props.field.required ? '*' : ''}</span>{props.field.label}</label>
                    <div className="input-group">
                        {props.field.choices.map((choice, index) => {
                            return (
                                <div key={index}>
                                    <span className="radio-grp">
                                        <input type="radio" checked={fieldValue === choice.value} id={props.field.name+index} name={props.field.name} value={choice.value} onChange={handleRadioValueChange} />
                                        <label htmlFor={props.field.name+index}>{choice.label}</label>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        case 'multi': {
            return (
                <div className="book-formg radio-block" id={props.field.name}>
                    <label className="rad-lbel"><span className="req-form">{props.field.required ? '*' : ''}</span>{props.field.label}</label>
                    <div className="input-group">
                        {props.field.choices.map((choice, index) => {
                            return (
                                <div key={index}>
                                    <span className="radio-grp">
                                        <input type="radio" checked={fieldValue === choice.value} id={props.field.name+index} name={props.field.name} value={choice.value} onChange={handleRadioValueChange} />
                                        <label htmlFor={props.field.name+index}>{choice.label}</label>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        case 'text': {
            return (
                <div className="book-formg flex-b">
                    <label htmlFor={props.field.name}><span className="req-form">{props.field.required ? '*' : ''}</span>{props.field.label}</label>
                    <input type="text" className="form-control" placeholder={props.field.helpText} name={props.field.name}
                        id={props.field.name} value={fieldValue} readOnly={props.field.read_only} autoComplete='off'
                        onChange={e => setFieldValue(e.target.value)} required={props.field.required} />
                </div>
            );
        }
        case 'checkbox': {
            return (
                <>
                    <div className="book-formg acknow-block">
                        <div className="input-group">
                            <span className="req-form mr-1 req-ack-icon">{props.field.required ? '*' : ''}</span>
                            <span className="radio-grp">
                                <input type="checkbox" checked={props.field.value} id={props.field.name} name={props.field.name} value={fieldValue} onChange={e => setFieldValue(e.target.checked)} required={props.field.required} />
                                <label htmlFor={props.field.index}>{props.field.label}</label>
                            </span>
                        </div>
                    </div>
                </>
            );
        }
        case 'label': {
            return (
                <h3 className="form-heading grey-bg" readOnly={props.field.read_only}>{props.field.label}</h3>
            );
        }
        case 'textarea': {
            return (
                <div className="book-formg add-inforblock">
                    <label className="rad-lbel"><span className="req-form">{props.field.required ? '*' : ''}</span>{props.field.label}</label>
                    <textarea rows="3" cols="50" placeholder={props.field.helpText} name={props.field.name} id={props.field.name} value={fieldValue} readOnly={props.field.read_only} onChange={e => setFieldValue(e.target.value)} required={props.field.required} />
                </div>
            );
        }
        default:
            return (<div></div>);
    }
};

export default DynamicForm;