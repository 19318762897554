import React from 'react';
import FindAnEventCheckBox from './FindAnEventCheckBox';

const FindAnEventCheckBoxList = ({eventsTypeList, setEventsTypeList}) => {
    const onChange = (id, checked) => {
        const eventTypeIndex = eventsTypeList.findIndex(item => item.id === id);
        if(eventTypeIndex === -1) return;

        const updatedEventTypeList = [...eventsTypeList];
        updatedEventTypeList[eventTypeIndex].checked = checked;
        setEventsTypeList(updatedEventTypeList);
    };

    const getEventsTypeListCheckBoxes = (list) => {
        return list.map((item, index) => {
            return (
                <FindAnEventCheckBox item={item} onChange={onChange} key={index + 1} />
            );
        });
    };
    return (
        <div style={{display: "flex", marginTop: "1rem"}}>
            {getEventsTypeListCheckBoxes(eventsTypeList)}
        </div>
    );
};

export default FindAnEventCheckBoxList;