import React, { useState, useEffect } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import env from '../env';
import '../../src/assets/css/mybookings.css';
import { DateTime } from 'luxon';


import {Table, Button, Popconfirm, Popover, Tag, Modal, Calendar, Tabs } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
// import EventHeader from '../components/EventList/EventHeader/EventHeader';
import Header from '../shared/Header';
import EventNavigationbar from '../components/EventList/EventNavigationbar';
import { CalendarOutlined, CheckCircleOutlined, EditOutlined  } from '@ant-design/icons';

import useGAEventsTracker from '../hooks/useGAEventsTracker';
import SheldonComponent from '../components/SheldonComponent';
import HQEvents from '../components/HQEvent/HQEvents';
import MeetingRoomEvents from '../components/MeetingRoomEvents';


const gAEventTracker = useGAEventsTracker('My reservations page event');


const MyBookings = ({ authState, socket }) => {
	const [bookingsData, setBookingsData] = useState([]);
	// const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState(null);
	const [showModifyModal, setShowModifyModal] = useState(false);
	const [updatedDate, setUpdatedDate] = useState(null);
	const [updateEnabled, setUpdateEnabled] = useState(false);
	const [messageObject, setMessageObject] = useState({value: false, body: ''});

	const getBookings = async () => {
		setLoading(true);
		let config = { method: 'get', url: `${env.BACKEND_URL}/desk-bookings`, headers: { 'Authorization': authState.accessToken.value } };
		let response = await axios(config);
		const data = response.data;
		if (data) {
			const updatedBookings = data.bookings.map(item => {
				const currentDate = DateTime.utc();
				const itemDate = DateTime.fromMillis(Date.parse(item?.startDate), { zone: 'utc' });
				const diff = itemDate.diff(currentDate,  ['hours']).hours;


				return {
					key: item.id,
					id: item.id,
					date: moment(item.date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY') ,
					address: item?.space?.floor?.building?.address,
					floor: item?.space?.floor?.name,
					space: item?.space?.code,
					item: {...item, date:  moment(item.date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY'), difference : diff },
					startDate: item?.startDate,
				};
			});
			setBookingsData(updatedBookings);
		}
		setLoading(false);

		return;
	};

	const checkIn = async (spaceId) => {
		gAEventTracker('Check-in button click');
		let config = { method: 'post', url: `${env.BACKEND_URL}/check-in-booking?spaceId=${spaceId}`, headers: { 'Authorization': authState.accessToken.value } };
		let response = await axios(config);
		const data = response.data;
		if (data) {
			await getBookings();
		}

		return;
	};

	const deleteBoooking = async (spaceId) => {
		gAEventTracker('Booking delete button click');
		// setLoading(true);
		let config = { method: 'post', url: `${env.BACKEND_URL}/delete-booking?spaceId=${spaceId}`, headers: { 'Authorization': authState.accessToken.value } };
		let response = await axios(config);

		if (response.data) {
			// setShowConfirmDeleteModal(false);
			// setCurrentItem(null);
			// socket.emit('message', 'canceled');
			if(socket && socket.connected) {
				socket.emit('message', JSON.stringify({body: 'canceled', token: authState.accessToken.value}));
			}
			await getBookings();
		}
		// setLoading(false);
	};

	useEffect(() => {
		/* Supports Google Callback */
		sessionStorage.setItem('callbackPage', '/my-reservations');

		getBookings();
	}, []);

	useEffect(() => {
		if(socket && socket.connected) {
			socket.on('message',(message)=> {
				if(message === 'refresh') {
					getBookings();
				}
			});
		}
	}, [socket]);

	const getISODate = (date) => {
		return date.year + date.month.toString().padStart(2, '0') + date.day.toString().padStart(2, '0') + 'T' + date.hour.toString().padStart(2, '0') +  date.minute.toString().padStart(2, '0')  + date.second.toString().padStart(2, '0') + 'Z';
	};

	const getReservationsUrl =  () => {
		if(env.NODE_ENV === 'local' || env.NODE_ENV === 'dev') {
			return 'https://dev.api.paloaltonetworks.com/hr-flexspace/my-reservations';
		} else if(env.NODE_ENV === 'stage') {
			return 'https://stg.api.paloaltonetworks.com/hr-flexspace/my-reservations';
		} else {
			return 'https://connected.paloaltonetworks.com/my-reservations';
		}
	};

	const createCalendarEvent = (item) => {
		const startDate = DateTime.fromMillis(Date.parse(item?.startDate), { zone: 'utc' });
		const isoStartDate = getISODate(startDate);
		const endDate = DateTime.fromMillis(Date.parse(item?.endDate), { zone: 'utc' });
		const isoEndDate = getISODate(endDate);
		const eventName = 'Desk Reservation - ' + item?.space?.floor?.building?.name + ' - '+ item?.space?.code;
		const location = item?.space?.floor?.building?.address;
		const reservationUrl = getReservationsUrl();
		// return `https://calendar.google.com/calendar/r/eventedit?text=My+Custom+Event&dates=20180512T230000Z/20180513T030000Z&details=For+details,+link+here:+https://example.com/tickets-43251101208&location=Garage+Boston+-+20+Linden+Street+-+Allston,+MA+02134`;
		const details = `<a href="${reservationUrl}" >Click here</a> to view or modify you reservations.`;
		return `https://calendar.google.com/calendar/r/eventedit?text=${eventName}&dates=${isoStartDate}/${isoEndDate}&details=${details}&location=${location}&crm=AVAILABLE`;

	};

	const modifyClickHandler = (item) => {
		setCurrentItem(item);
		setShowModifyModal(true);
	};

	const onDateChange = (value) => {
		const currentDate = DateTime.utc();
		const currentItemDate= DateTime.fromMillis(moment(currentItem.date, 'dddd, MMMM Do YYYY').valueOf(), { zone: 'utc' });
		const selectedDate = DateTime.fromMillis(value.valueOf(), { zone: 'utc' });
		const selectedItemDateDiff = selectedDate.diff(currentItemDate, ['years', 'months', 'days', 'hours']).days;
		const currentDateDiff = selectedDate.diff(currentDate, ['years', 'months', 'days', 'hours']).days;


		if(currentDateDiff >= 0 && selectedItemDateDiff !== 0 ) {
			setUpdatedDate(value);
			setUpdateEnabled(true);
		} else {
			setUpdatedDate(null);
			setUpdateEnabled(false);
		}

	};


    const isSameDate = (dateTime1, dateTime2) => {
        return dateTime1.month === dateTime2.month && dateTime1.day === dateTime2.day && dateTime1.year === dateTime2.year;
    };

	const updateBooking = async () => {
		gAEventTracker('Booking update button click');
		setLoading(true);
		const timezone = currentItem.space?.floor?.building?.timezone || 'utc';
		const updatedDateObj = DateTime.fromMillis(updatedDate.valueOf(), { zone: timezone });

        const year = updatedDateObj.year;
        const month = updatedDateObj.month;
        const day = updatedDateObj.day;


        let startDate = DateTime.fromObject({year: year, month: month, day: day, hour: 9}, {zone: timezone});
        let endDate = DateTime.fromObject({year: year, month: month, day: day, hour: 18}, {zone: timezone});


        if (isSameDate(startDate, DateTime.now())) {
            startDate =  DateTime.now().setZone(timezone).plus({minutes: 1});

        }


        let config = { method: 'post', url: `${env.BACKEND_URL}/update-booking?spaceId=${currentItem.id}&startDate=${startDate.toString()}&endDate=${endDate.toString()}`, headers: { 'Authorization': authState.accessToken.value } };
        let response = await axios(config);
		setLoading(false);
        if (response.data.errors && response.data.errors.length > 0) {
			const errMsg = response.data.errors[0].message;
			setMessageObject({value: true,  body: errMsg});
        } else {
			// socket.emit('message', 'updated');
			if(socket && socket.connected) {
				socket.emit('message', JSON.stringify({body: 'updated', token: authState.accessToken.value}));
			}
			setMessageObject({value: true,  body:
				<div className='hasSubmittedTicket'>
					<CheckCircleOutlined />
					<div>Your desk reservation has been updated successfully.</div>
				</div>
		});
        }
		getBookings();

	};

	const reset = () => {
		setShowModifyModal(false);
		setCurrentItem(null);
		setMessageObject({value: false, body: ''});
		setUpdateEnabled(false);
	};



	const columns = [
		{
			title: 'Date',
			dataIndex: 'item',
			key: 'date',
			width: '20%',
			render: item => {
				return ([
					<React.Fragment key={item.date}>
						<span>{item.date}</span>
						{item.bookingSchedule?.recurrencePattern === 'multi_day' && <Tag>Multi-Day</Tag>}
					</React.Fragment>]

				);
			}
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			width: '20%',
		},
		{
			title: 'Floor',
			dataIndex: 'floor',
			key: 'floor',
			width: '20%',
		},
		{
			title: 'Space',
			dataIndex: 'space',
			key: 'space',
			width: '15%',
		},
		{
			title: 'Actions',
			dataIndex: 'item',
			key: 'item',
			width: '25%',
			render: item => {
                const canCheckIn = item.difference > -1 && item.difference <= 24;
				return (
					[
						<React.Fragment key={item.id}>

							{ !item.checkedIn && <Popover  placement="top" content={(canCheckIn) ? <span>If not checked in, booking will be released 1 hours after it starts.</span> : <span>Check-in is available 24hr before start of your booking.</span>}>
								<Button
									disabled={!(canCheckIn)}
									style={{padding: 0, height: 'auto'}}
									type='text'
									onClick={() => {
										checkIn(item.id);
										// setCurrentItem(item);
										// setShowConfirmDeleteModal(true);
									}}
								>
									<Tag color={ (canCheckIn) ?  '#13b5b0'  : '#ccc'} >CHECK-IN</Tag>
								</Button>
							</Popover> }
							{item.checkedIn && <Tag >CHECKED-IN</Tag> }
							<Button type='text' style={{color: '#0071e3'}} onClick={() =>  modifyClickHandler(item)} icon={<EditOutlined  />} />
							<span> | </span>
							<Popover
								content={<React.Fragment>
									<div>Add this event to your google calendar.</div>
								</React.Fragment>
								}>

									<Button type='text' onClick={() => window.open(createCalendarEvent(item)) } icon={<CalendarOutlined />} />

									{/* <CalendarOutlined onClick={() => window.open(createCalendarEvent(item))}/> */}
							</Popover>
							<span> | </span>
							<Popconfirm
								title={<><span>Are you sure you want to cancel this booking?</span><div>{item?.space?.code} </div></>}
								onConfirm={() => {
									// setCurrentItem(null);
									deleteBoooking(item.id);
								}}
								onCancel={() => {}}
								okText="Yes"
								cancelText="No"
							>
								<Button danger type='text' onClick={() => {
									// setCurrentItem(item);
									// setShowConfirmDeleteModal(true);
								}} icon={<DeleteOutlined />} />
							</Popconfirm>

						</React.Fragment>
					]
				);
			}
		},
	];

	// const getTitle = (title) => {
	// 	return <div style={{display: 'flex', justifyContent: 'center'}}>
	// 		<div style={{minWidth: '90%'}}>
	// 			<h2>{title}</h2>
	// 		</div>
	// 	</div>;
	// };


	const getTabContent = (id = '1') => {
		if(id === '1') {
			return <Table
				columns={columns}
				dataSource={bookingsData}
				pagination={false}
				className="ant-table-flexspace"
				bordered
				scroll={{x: true}}
				loading={loading}
			/>;
		} else if( id === '2') {
			return <HQEvents />;
		} else {
			return <MeetingRoomEvents socket={socket} />;
		}
	};

	return (
		<>
			<Header />
			<EventNavigationbar navigationTitle='My Reservations'  path='/'/>
			<br/>
			<br/>

			<div style={{display: 'flex', justifyContent: 'center'}}>
				<div style={{width: '90%'}}>
					<Tabs defaultActiveKey='1' type='card'>
						<Tabs.TabPane tab='Desk Bookings' key='1'>
							{getTabContent('1')}
						</Tabs.TabPane>
						<Tabs.TabPane tab='Event Bookings' key='2'>
							{getTabContent('2')}
						</Tabs.TabPane>
						<Tabs.TabPane tab='Meeting Rooms Events' key='3'>
							{getTabContent('3')}
						</Tabs.TabPane>
					</Tabs>
				</div>
			</div>
			
			  {showModifyModal && currentItem && <Modal key={currentItem.id}
                title="Edit Booking"
                centered
                visible={showModifyModal}
                onOk={() => reset()}
                onCancel={() => reset()}
                footer={[
                    <div key='footer'>
				    { !messageObject.value
						? <>
							<Button
								disabled={!updateEnabled} onClick={() => updateBooking()} key="submit" type="primary" loading={loading}>
									Update Booking
							</Button>
						</>
						: <Button
							onClick={() => reset()}
							type="primary"
							>
								My Reservations
						</Button>

					}
					</div>
                ]}
            >

 				{ messageObject.value
                    ? <div>{messageObject.body}</div>
					: <Calendar
							defaultValue={ moment(currentItem.date, 'dddd, MMMM Do YYYY')}
							fullscreen={false}
							on
							onChange={onDateChange}
							headerRender={() => <></>}
							disabledDate={current => {
								return current && DateTime.fromJSDate(current.toDate())  <= DateTime.now();
							}}
						/>
				}
            </Modal>}
            <SheldonComponent/>
		</>

	);

};

export default withOktaAuth(MyBookings);
