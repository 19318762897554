import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Select } from 'antd';

const FindAnEventHeader = ({
    sites,
    selectedSite,
    setSelectedSite,
    dateRanges,
    selectedDateRange,
    setSelectedDateRange
}) => {

    /* TODO: Sort options alphabetically */
    const getMenu = (sites) => {
        const sitesMenuItems = sites.map((item) =>  {
            return (
                <Menu.Item key={item}>
                    <div>{item}</div>
                </Menu.Item>
            );
        });

		return (
            <Menu onClick={({key}) => onSiteSelect(key)}>
                {sitesMenuItems}
            </Menu>
        );
	};

    const getDateRangeDrowDown = (ranges) => {
        return ranges.map(range => {
			return <Select.Option value={range.id} key={range.id + '_' + range.text}>{range.text}</Select.Option>;
		});
    };

    const onSiteSelect = (siteName) => {
        const index = sites.findIndex(item => item === siteName);
        if(index === -1) return;
        setSelectedSite(sites[index]);
    };

    const onDateRangeSelected = (rangeId) => {
        const index = dateRanges.findIndex(item => item.id === rangeId);
        if(index === -1) return;
        setSelectedDateRange(dateRanges[index]);
    }

    return (
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <h1 style={{marginRight: "1rem", fontSize: "2rem", marginBottom: 0}}>Events this week at</h1>
                <Dropdown overlay={getMenu(sites)}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <h1 style={{fontSize: "2rem", color: "blue", marginBottom: 0}}>{selectedSite}</h1>
                            <div style={{marginLeft: 10}}>
                                <DownOutlined />
                            </div>
                        </div>
                    </a>
                </Dropdown>
            </div>
            <div>
                <Select value={selectedDateRange.text} loading={false} onChange={(id) => onDateRangeSelected(id)}>
                    {getDateRangeDrowDown(dateRanges)}
                </Select>
            </div>
        </div>
    );
};

export default FindAnEventHeader;