import React, {useEffect, useState} from 'react';
import { withOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import env from '../../../env.json';
import DynamicForm from './DynamicForm';
import CloseIconDark from './../../../assets/images/icons/arrow-right-solid-dark.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {Spin} from 'antd';
import CheckCircle from '../../../assets/images/icons/CheckCircle.svg';
import './BookingForm.css';

const BookingForm = (props) => {

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [snResponse, setSNResponse] = useState({sysID: '', msg: ''});
  const optionalFieldsTrigger = 'u_do_you_need_av_it_support';
  const optionalFields = ['u_dry_run_we_highly_recommended_a_dry_run_for_your_event','u_will_this_zoom_meeting_webinar_needs_to_be_recorded','u_no_of_remote_employees'];

  useEffect(() => {
    const labels = document.getElementsByTagName('label');
    for (let l in labels) {
      try {
       if (labels[l].innerHTML === 'I acknowledge') {
         labels[l].innerHTML = 'I acknowledge that I have read the Event Guidelines ';
       }
      } catch (e) { console.log(e); }
    }
  });

  const close = (redirect) => {
    if (props.onCloseBookingForm) {
      props.onCloseBookingForm();
      setIsSubmitted(false);
      setIsSuccess(false);
      if (!redirect) return true;

      let origin = window.location.origin;
      if (window.location.href.includes('hr-flexspace')) {
        origin += '/hr-flexspace';
      }
      window.open(origin, '_self');
    }
  };

  const handleSubmitBooking = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsSubmitted(true);

    const form = new FormData(e.target);
    form.set('start', moment(props.startDate, 'ddd MMM DD,YYYY hh:mma').format('YYYY-MM-DDTHH:mm:ss'));
    form.set('end', moment(props.endDate, 'ddd MMM DD,YYYY hh:mma').format('YYYY-MM-DDTHH:mm:ss'));
    form.set('location', props.roomID);
    form.set('campus', props.campus);
    form.set('u_configuration_type', props.configStyle);

    const result = await axios({
      url: `${env.BACKEND_URL}/bookEvent`,
      method: 'post',
      headers: { 'Content-Type': 'application/json', Authorization: props.authState.accessToken.value },
      data: Object.fromEntries(form)
    });

    setIsLoading(false);
    if (result.data.ok && 'data' in result.data && 'sysID' in result.data.data) {
      setSNResponse({sysID: result.data.data.sysID, msg: result.data.data.requestNumber});
      setIsSuccess(true);
    }
    else {
      const msg = 'message' in result.data ? result.data.message : 'An unknown error has occurred, please contact support';
      setSNResponse({msg: msg});
      setIsSuccess(false);
    }
  };

  if (!props.isOpen) { return null; }
  if (isSubmitted) {
    return (
      <div id="myModal" className="modal">
        <div className="modal-content bookmodal-content">
          <span className="close" onClick={() => close(isSuccess)}><img src={CloseIconDark} /></span>
          <div className="modal-message-wrap">
          <div className={`modal-message ${isLoading ? '' : isSuccess ? 'bg-ok' : 'bg-err'}`}>
          { isLoading ?
          <>
            <div className='ant-spin-container'><Spin size='large'/></div>
          </>
          : isSuccess ?
          <>
            <div><img className="check-circle" src={CheckCircle} width="5%" /></div>
            Thank you for creating an event via Connected! Here is your ticket number: {snResponse.msg}
            <br/>Please bookmark
            <a className="cursor-pointer" onClick={() => window.open(`${env.SN_BASE_URL}/nav_to.do?uri=/sn_wsd_rsv_reservation.do?sys_id=${snResponse.sysID}`, '_blank')}> this link </a>
            to track updates on confirming your event.
          </>
          :
          <>
            <div className="image-text close-error-icon"><FontAwesomeIcon icon={faTimesCircle} /></div>
            {snResponse.msg}
          </>
          }
          </div>
          { isLoading ? '' : isSuccess ?
              <CopyToClipboard text={`${env.SN_BASE_URL}/nav_to.do?uri=/sn_wsd_rsv_reservation.do?sys_id=${snResponse.sysID}`}>
                <div className="bookform-btns popup-center">
                  <button type="button" name="sbmtFrm" className="btn btn-lg btn-primary btn-booknow m-0" onClick={() => close(true)}>Close & Copy Link</button>
                </div>
              </CopyToClipboard> : <div className="bookform-btns popup-center">
                  <button type="button" name="sbmtFrm" className="btn btn-lg btn-primary btn-booknow m-0" onClick={() => close(false)}>Close</button>
                </div>
          }
          </div>
        </div>
      </div>
    );
  }
  // else
  return (
    <div id="myModal" className="modal" style={{ display: props.isOpen ? 'block' : 'none' }}>
      <div className="modal-content bookmodal-content">
        <span className="close" onClick={() => close(false)}><img src={CloseIconDark} /></span>
          <div className="book-main-cont">
            <div className="bookmodal-header">Book this room</div>
            <form onSubmit={(e) => handleSubmitBooking(e)}>
              <div className="booking-form">
                {props.formFields.map((field, index) => {
                  if (field.type === 'check_box') {
                    return (
                      <>
                        <div className="book-formg read-block">{field.label}</div>
                        {field.choices.map((choice, choiceIndex) => {
                          return (
                            <div className="book-block-main" key={'choice' + (choiceIndex + 1)}>
                              <DynamicForm field={choice} />
                            </div>
                          );
                        })
                        }
                      </>
                    );
                  }
                  if (field.type === 'radio') {
                    return (
                      <div className="book-block-main" key={index + 1}>
                        <DynamicForm
                          field={field}
                          optionalFieldsTrigger={optionalFieldsTrigger}
                          optionalFields={optionalFields}
                        />
                      </div>
                    );
                  }
                  else {
                    return (
                      <div className="book-block-main" key={index + 1}>
                        <DynamicForm field={field} />
                      </div>
                    );
                  }
                })
                }
              </div>

              <div className="bookform-btns">
                <button type="button" name="sbmtFrm" className="btn btn-lg btn-primary btn-cancel" onClick={() => close(false)}>Cancel</button>
                <input type="submit" value="Book Now" name="sbmtFrm" className="btn btn-lg btn-primary btn-booknow" />
              </div>
            </form>
          </div>
      </div>
    </div>
  );
};
export default withOktaAuth(BookingForm);
