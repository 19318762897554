import React from "react";
import { withRouter } from "react-router-dom";
import { Drawer, Alert, message, Spin, Switch } from "antd";
import axios from "axios";
import env from "../../env";
import { withOktaAuth } from "@okta/okta-react";

import useGAEventsTracker from "../../hooks/useGAEventsTracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";

const gAEventTracker = useGAEventsTracker("Header menu event");

class MyNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      badgeData: false,
      enrollmentsData: false,
    };
  }

  componentDidMount = async () => {
  try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let config = { method: 'get', url: `${env.BACKEND_URL}/badge-status-and-team-arrivals?tz=${tz}`, headers: { 'Authorization': this.props.authState.accessToken.value } };
      let resp = await axios(config);
      this.setState({loading: false, badgeData: resp.data});
  } catch (e) {
      this.setState({loading: false, badgeData: false});
  }
  // this.getEnrollments();
  };

  getEnrollments = async () => {
    try {
      let config = {
        method: "get",
        url: `${env.BACKEND_URL}/enrollments`,
        headers: { Authorization: this.props.authState.accessToken.value },
      };
      let resp = await axios(config);
      let enrollmentsOptions = resp.data.data.options;
      let enrollmentsObject = {
        rcv_directs: enrollmentsOptions.rcv_directs === "True" ? true : false,
        rcv_mgr: enrollmentsOptions.rcv_mgr === "True" ? true : false,
        rcv_peers: enrollmentsOptions.rcv_peers === "True" ? true : false,
        snd_directs: enrollmentsOptions.snd_directs === "True" ? true : false,
        snd_mgr: enrollmentsOptions.snd_mgr === "True" ? true : false,
        snd_peers: enrollmentsOptions.snd_peers === "True" ? true : false,
      };
      // {"data":{"options":{"rcv_directs":"True","rcv_mgr":"True","rcv_peers":"True","snd_directs":"True","snd_mgr":"True","snd_peers":"True"},"user":"ebloomstein@paloaltonetworks.com"}}
      this.setState({ loading: false, enrollmentsData: enrollmentsObject });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, enrollmentsData: false });
    }
  };

  changeNotification = async (key) => {
    const updatedEnrollmentsData = { ...this.state.enrollmentsData };
    updatedEnrollmentsData[key] = !updatedEnrollmentsData[key];
    this.setState({ enrollmentsData: updatedEnrollmentsData });
    try {
      const config = {
        method: "post",
        url: `${env.BACKEND_URL}/enrollments`,
        headers: { Authorization: this.props.authState.accessToken.value },
        data: updatedEnrollmentsData,
      };
      const response = await axios(config);
      if (response.data) {
        message.success("Team arrival Notifications have been updated");
      }
    } catch (err) {
      console.log(err);
    }
    console.log(key);
  };

  onNotificationBadgeClick = () => {
    gAEventTracker("Notification button click");
    this.setState({ visible: true });
  };

  render() {
    const { visible, loading, badgeData } = this.state;
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            cursor: "pointer",
          }}
          onClick={this.onNotificationBadgeClick}
        >
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={{height: 30, width: 30}}>
                        <path fill='white' d="M336 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm0 464H48V48h288v416zM144 112h96c8.8 0 16-7.2 16-16s-7.2-16-16-16h-96c-8.8 0-16 7.2-16 16s7.2 16 16 16zm48 176c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"/>
                    </svg> */}
          <div className="link my-reservations">
            <FontAwesomeIcon
              icon={faIdBadge}
              style={{ color: "white", fontSize: 25 }}
            />
          </div>
        </div>
        <Drawer
          title="My Badge"
          placement="right"
          onClose={() => this.setState({ visible: false })}
          visible={visible}
          width={500}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              {!badgeData && (
                <Alert
                  message="Integration Failure"
                  description={
                    <div>
                      Connected is unable to connect with the badging server to
                      show whether your badge is active or not. We have a
                      support ticket in place to fix this.
                    </div>
                  }
                  type="info"
                  showIcon
                />
              )}
              {badgeData &&
                badgeData.active.length === 0 &&
                badgeData.inactive.length === 0 && (
                  <Alert
                    message="No badge on file"
                    description={
                      <div>
                        Please follow the instructions{" "}
                        <a
                          href="https://theloop.paloaltonetworks.com/loop/badge-access-request"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>{" "}
                        to obtain a badge.
                      </div>
                    }
                    type="info"
                    showIcon
                  />
                )}
              {badgeData &&
                badgeData.active.length === 0 &&
                badgeData.inactive.length > 0 && (
                  <Alert
                    message="No active badge(s) detected"
                    description={
                      <div>
                        Please reference your local country guidelines{" "}
                        <a
                          href="https://theloop.paloaltonetworks.com/loop/coronavirus-covid-19-information/office-status"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>{" "}
                        on site access information
                      </div>
                    }
                    type="error"
                    showIcon
                  />
                )}
              {badgeData && badgeData.active.length > 0 && (
                <Alert
                  message="Active Badge"
                  description={
                    <div>
                      Your badge(s) on file are active! Please refer to Sheldon
                      below if you are still having trouble entering the site.
                    </div>
                  }
                  type="success"
                  showIcon
                />
              )}

              <div className="notification-preference-item-seperator"></div>
              <div className="notification-preferences-container">
                {/* <div className='notification-preference-item'>
                                   <div className='notification-preference-item-text'>Remind me to grab lunch at 11am on days that I have booked a space</div>
                                   <div><Switch defaultChecked onChange={() => this.changeNotification('lunch')}/></div>
                               </div>
                               <div className='notification-preference-item'>
                                   <div className='notification-preference-item-text'>Remind me about my space booking at 7am on days that I have booked a space</div>
                                   <div><Switch defaultChecked onChange={() => this.changeNotification('7am')}/></div>
                               </div> */}
                {/* <div className="notification-preference-title"><a href="https://panw-global.slack.com/archives/D035T8LAXH8" target="_blank" rel="noreferrer" >Team Arrivals Integration</a></div>
                               <br />
                               <div className="notification-preference-title">Notify me when my...</div>
                               <div className='notification-preference-item'>
                                   <div className='notification-preference-item-text'>directs are on-site</div>
                                   <div><Switch checked={this.state.enrollmentsData && this.state.enrollmentsData.rcv_directs}  onChange={() => this.changeNotification('rcv_directs')}/></div>
                               </div>
                               <div className='notification-preference-item'>
                                   <div className='notification-preference-item-text'>manager is on-site</div>
                                   <div><Switch checked={this.state.enrollmentsData && this.state.enrollmentsData.rcv_mgr}  onChange={() => this.changeNotification('rcv_mgr')}/></div>
                               </div>
                               <div className='notification-preference-item'>
                                   <div className='notification-preference-item-text'>peers are on-site</div>
                                   <div><Switch checked={this.state.enrollmentsData && this.state.enrollmentsData.rcv_peers}  onChange={() => this.changeNotification('rcv_peers')}/></div>
                               </div>
                               <div className="notification-preference-item-seperator"></div>
                               <div className="notification-preference-title">Notify my...</div>
                               <div className='notification-preference-item'>
                                   <div className='notification-preference-item-text'>directs when I am on-site</div>
                                   <div><Switch checked={this.state.enrollmentsData && this.state.enrollmentsData.snd_directs}  onChange={() => this.changeNotification('snd_directs')}/></div>
                               </div>
                               <div className='notification-preference-item'>
                                   <div className='notification-preference-item-text'>manager when I am on-site</div>
                                   <div><Switch checked={this.state.enrollmentsData && this.state.enrollmentsData.snd_mgr}  onChange={() => this.changeNotification('snd_mgr')}/></div>
                               </div>
                               <div className='notification-preference-item'>
                                   <div className='notification-preference-item-text'>peers when I am on-site</div>
                                   <div><Switch checked={this.state.enrollmentsData && this.state.enrollmentsData.snd_peers}  onChange={() => this.changeNotification('snd_peers')}/></div>
                               </div> */}
              </div>
            </>
          )}
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withOktaAuth(withRouter(MyNotifications));
