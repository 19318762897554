import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import Header from '../shared/Header';
import HomeEventCard from '../components/HomeEventCard';
import SheldonComponent from '../components/SheldonComponent';
import './Home.css';

import useGAEventsTracker from '../hooks/useGAEventsTracker';
import FeedBackComponent from '../components/FeedBackComponent';

const gAEventTracker = useGAEventsTracker('Home page event');

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	navigateBookAnEvent = (navigationPath) => {
		switch (navigationPath) {
			case 'bookanevent':
				gAEventTracker('Book an event click');
				this.props.history.push('/book-an-event');
				break;
			case 'bookadesk':
				gAEventTracker('Book a desk click');
				this.props.history.push('/book-a-space');
				break;
			case 'bookaconfrence':
				this.props.history.push('/book-a-conference-room');
				break;
			case 'orderfood':
				gAEventTracker('Order food click', this.props?.authState?.idToken?.claims?.name);
				this.props.history.push('/order-food');
				break;
			case 'findMyFriends':
				this.props.history.push('/find-my-friends');
				break;
			case 'findAnEvent':
				gAEventTracker('Events I can join click', this.props?.authState?.idToken?.claims?.name);
				this.props.history.push('/find-an-event');
				break;
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className='custom-bck' >
					
					<Header home={true} />
					<section className="custom-container custom-container-home">
						<HomeEventCard onhandleClose={this.navigateBookAnEvent} />
					</section>
					<SheldonComponent/>
					<div className="custom-bck-gradient">
						<svg mlns='http://www.w3.org/2000/svg' viewBox="0 0 64 64">
							<defs>
							<linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
							<stop offset="0%" stopColor="#DE2F52" />
							<stop offset="80%" stopColor="#FA582D" />
							</linearGradient>
						</defs>
							<path d='M0 20 C35 5 40 20 64 15 L64 0 L0 0 Z'  fill="url(#grad)"/>
						</svg>
					</div>
					<FeedBackComponent onClick={() =>{
						gAEventTracker('Feedback button clicked', this.props?.authState?.idToken?.claims?.name);
						window.open('https://forms.gle/4C2cYehjMPCzE8997', '_blank');
					}} />
				</div>
			</React.Fragment>
		);
	}
}
Home.propTypes = {
	authState: PropTypes.object
};
export default withRouter(withOktaAuth(Home));
