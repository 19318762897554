import React, { useState } from 'react';
//import { withRouter } from 'react-router-dom';
import LabelHeader from './../components/LabelHeader';
import HomeEventCard from './HomeEventCard';
import CommunitySpace from './../assets/images/bookEvent/community_space.svg';
import Mpr from './../assets/images/bookEvent/mpr.svg';
import CloseIcon from './../assets/images/bookEvent/close_white.svg';
import { withRouter } from 'react-router-dom';


const EventSpace = (props) => {
    const [isHome, setHome] = useState(false);
    const navigateHome = () => {
        setHome(!isHome);
        props.onhandleClose();
    };

    return (
        <React.Fragment>
            {isHome === false ? <div className='flex-event-bg'> 
                <div className="close-icon" style={{display: 'none'}} onClick={navigateHome}><img src={CloseIcon} /></div>
                <div className="flexevt-card-btn-wrap">
                    <LabelHeader labelPath='' labelContent='Where would you like to book your event? *' />
                    <div className="flexevt-card-inner-wrap">
                        {/* <div onClick={() => window.open('https://hqreservations.briefingsource.com/#/home', '_blank')} className="flexevt-card-btn step-modal-card" ><img src={CommunitySpace} alt="CommunitySpace" /><p>Santa Clara HQ</p></div> */}
                        <div onClick={() => props.history.push('/hq-event')} className="flexevt-card-btn step-modal-card" ><img src={CommunitySpace} alt="CommunitySpace" /><p>Santa Clara HQ</p></div>
                        {/* <div onClick={() => window.open('https://panservicedesk.service-now.com/esp?id=pan_sc_cat_item&sys_id=c7ffc9f0134b430007dbbb722244b0e2', '_blank')} className="flexevt-card-btn step-modal-card"><img src={Mpr} alt="Mpr" /><p>Tel Aviv-Yafo</p></div> */}
                        <div onClick={() => props.history.push('/tel-aviv-event')} className="flexevt-card-btn step-modal-card"><img src={Mpr} alt="Mpr" /><p>Tel Aviv-Yafo</p></div>
                    </div>
                    {/*<div className="bar-position">*/}
                    {/*    <ProgressBar filledPercentage='0%' />*/}
                    {/*</div>*/}
                </div>
            </div> : <HomeEventCard />}
        </React.Fragment>
    );
};

export default withRouter(EventSpace);
// export default EventSpace;
