import React from 'react';
import {Modal, Button, Spin, Divider, Input } from 'antd';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import BookDesk from '../../assets/images/bookEvent/book_desk.svg';
import DeskImage from '../../assets/images/meeting-room.jpg';
import TelAvivDeskImage from '../../assets/images/telAviv-desk-image.png';
import FourFivePersonImage from '../../assets/images/4-5Persons.jpg';
import SevenEightPersonImage from '../../assets/images/7-8Persons.jpg';
import EightPlusImage from '../../assets/images/8+Persons.jpg';
import Edit from '../../assets/images/bookEvent/edit.svg';
import './BookADeskModal.css';

const {TextArea} = Input;

const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const BookAMeetingRoomModal = ({
    show, 
    onToggle, 
    onSubmit, 
    messageObject, 
    currentNode, 
    loading,
    site,
    dateCurrent,
    dateCurrentEnd,
    history,
    capacity,
    meetingRoomEventTitle,
    setMeetingRoomEventTitle,
    meetingRoomEventDescription,
    setMeetingRoomEventDescription
}) => {
    
    const navtomyres = () => {
        history.push('/my-reservations');
    };

    const getLoader = () => {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '16em' }}><Spin indicator={loaderIcon} /></div>;
    };

    const getTitle = (text) => {
        return <div><span><img src={BookDesk} alt="BookDesk" style={{maxWidth: '1.6rem', marginRight: '2em'}} /></span><span>{text}</span></div>;
    };

    const getModalContent = (title, content, footer) => {
        return <div className="booking-modal-container">
            <div className="booking-modal-header-wrapper">
                <div className="booking-modal-header">
                    {title}
                </div>
                <div className="booking-modal-content">{content}</div>
            </div>
            <div className="booking-modal-footer">
                {footer}
            </div>
        </div>;
    };

    const getDeskImage = () => {
        if(site === 'Tel Aviv') {
            return TelAvivDeskImage;
        }
    

        if(site === 'HQ - Building 1' ||  site === 'HQ - Building 2' || site === 'HQ - Building 3' ||  site === 'HQ - Building 4') {
            if(capacity >= 4  && capacity <= 5) {
                return FourFivePersonImage;
            }

            if(capacity >= 6  && capacity <= 8) {
                return SevenEightPersonImage;
            }

            if(capacity > 8 ) {
                return EightPlusImage;
            }
            
        }

        return DeskImage;
    };

    const getWhen = () => {
        return <span>{dateCurrent.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)} - {dateCurrent.toLocaleString(DateTime.TIME_SIMPLE)} - {dateCurrentEnd.toLocaleString(DateTime.TIME_SIMPLE)}</span>;
    };

    const getTime = () => {
        return <span>{dateCurrent.toLocaleString(DateTime.TIME_SIMPLE)} - {dateCurrentEnd.toLocaleString(DateTime.TIME_SIMPLE)}</span>;
    };

    const getDayAndMonth = () => {
        return dateCurrent.toFormat('ccc, LLL');
    };

    const getDay = () => {
        return dateCurrent.toFormat('dd');
    };

    const getDeskContent = () => {
        const content =  <div className="booking-modal-body">
                <div style={{display: 'flex', margin: '-0.5rem -1rem'}}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '120px', padding: '1rem', backgroundColor: '#F4F4F2', borderRight: '1px solid #dddddd', marginRight: '20px', overflow: 'hidden'}}>
                        <div style={{fontSize: '.8rem', display: 'flex', justifyContent: 'center', letterSpacing: '-1px', fontWeight: 'bold', marginBottom: '.5rem', color: '#000'}}>
                            <p>Capacity:</p>
                            <span>{capacity}</span>
                        </div>
                        <div style={{width: '90%', borderRadius: '10px', overflow: 'hidden', marginBottom: '.5rem'}}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20px', background: '#FA582D', color: 'white'}}>
                                <span style={{fontSize: '10px', marginLeft: 0, marginTop: 2}}>{getDayAndMonth()}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px', background: 'white'}}>
                                <span style={{fontSize: '24px', marginLeft: 0, textAlign: 'center', lineHeight: '1rem', fontWeight: 'bold', marginTop: '-3px'}}>
                                    {getDay()}
                                </span>
                            </div>
                        </div>
                        <div style={{fontSize: '.5rem', letterSpacing: '-1px', fontWeight: 'bold', marginBottom: '.5rem', color: '#000'}}>
                            {getTime()}
                        </div>
                        <img  src={getDeskImage()} style={{borderRadius: '8px', width: '100%', maxHeight: '100px', objectFit: 'cover'}} alt='Meeting-room-placeholder' />
                    </div>
                    <div style={{flex: 1, padding: '1rem 1.5rem'}}>
                        <h1 style={{marginBottom: 0}}>{currentNode.name}</h1>
                        <h2 style={{fontWeight: 400, fontSize: '1.2rem', letterSpacing:'-1px'}}>{getWhen()}</h2>

                        <div style={{display: 'flex', marginTop: '1rem'}}>
                            <span style={{fontSize: 12, color: '#807C7B', marginRight: '1rem'}}><b>Assets:</b></span>
                            <div style={{height: '50px', border: '1px solid #ddd', borderRadius: '8px', padding: '.5rem', fontSize: 12, overflowY: 'auto', width: '100%' }}>
                                {currentNode.tags.length > 0 ? currentNode.tags.map(asset => asset.name).join(', ') : 'None'}
                            </div>
                        </div>

                        { currentNode.canBook && <div style={{flex: 1}}>
                            <div style={{ marginTop: '10px' }}>
                                <p style={{ fontSize: '13px', position: 'relative', top: '10px' }}><span style={{color: '#ff4d4f'}}>*&nbsp;</span><strong>Meeting subject:</strong></p> 
                                <Input 
                                    value={meetingRoomEventTitle} 
                                    style={{borderRadius: '8px', width: '100%'}} 
                                    onChange={(e) => setMeetingRoomEventTitle(e.target.value)} />
                            </div>
                            <div >
                                <p style={{ fontSize: '13px', position: 'relative', top: '10px' }}><strong>Meeting description:</strong></p> 
                                <TextArea 
                                    rows={2}  
                                    style={{borderRadius: '8px', width: '100%'}} 
                                    value={meetingRoomEventDescription} onChange={(e) => setMeetingRoomEventDescription(e.target.value)} />
                            </div>

                        </div> }

                    </div>
                </div>
              

        </div>;

        const footer = <div>
                <Button className="mr-1" onClick={() => {
                        onToggle(false); 
                    }} >
                    Cancel
                </Button>
                <Button
                    disabled={currentNode.canBook === '' || meetingRoomEventTitle === ''}
                    key="submit" type="primary"  loading={loading} onClick={onSubmit}>
                    Book Now
                </Button>
        </div>;

        const title = getTitle('BOOK A SPACE - MEETING ROOM SUMMARY');

        return getModalContent(title, content, footer);
    };

    const getMessageContent = () => {
        // const content = <div style={{marginBottom: '8px'}}>{messageObject.body}</div>;
        const content = <div style={{marginBottom: '8px', padding: '2rem 1rem'}}>
            {messageObject.bookingSuccessful && <>
                <h3 style={{color: '#52C41B', fontWeight: '600' }}>Thanks for booking a meeting room!</h3>
                <div style={{marginTop: '1.5em'}}>
                    <h2 style={{marginLeft: '2em'}}>What&rsquo;s next?</h2>
                    <Divider style={{borderWidth : 2, margin: 0}} />
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: '80%'}}>
                            <div style={{marginTop: '1rem', display: 'flex' }}>
                                <img src={Edit} alt="edit" style={{maxWidth: '1.5rem', marginRight: '2em'}} />
                                <div style={{fontSize: 12}}>
                                    You can always modify or delete your reservation under &nbsp;
                                    <span style={{color: '#FA582D', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navtomyres()}>&ldquo;My Reservations&rdquo;</span> 
                                    &nbsp; or Connected.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> }
            {!messageObject.bookingSuccessful && <>
                <div style={{marginTop: '1.5em'}}>
                        {messageObject.body}
                </div>
            </>}
        </div>;
        const footer = <div>
             <Button
                    key="submit"
                    type="primary"
                    onClick={messageObject.bookingSuccessful ? () => navtomyres() : () => location.reload() }
                >
                    {messageObject.bookingSuccessful ? 'My Reservations' : 'Refresh Page'}
                </Button>
        </div>;

        const title = getTitle('BOOK A SPACE - MEETING ROOM ALL SET!');

        return getModalContent(title, content, footer);
    };


    return <Modal
        title={null}
        centered
        width="680px"
        visible={show}
        wrapClassName="ant-modal-flexspace"
        onOk={() => onSubmit()}
        onCancel={() => onToggle(false)}
        footer={null}
    >
        {
            messageObject.value
                ? getMessageContent()
                : loading
                    ? getLoader()
                    : getDeskContent()
        }


    </Modal>;
};

export default withRouter(withOktaAuth(BookAMeetingRoomModal));