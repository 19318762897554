import ReactGA from 'react-ga4';

const useGAEventsTracker = (category = 'Event Category') => {
    const trackEvent = (action = 'action', label = '') => {
        const eventProps = {
            category, action
        };
        if(label){
            eventProps.label = label;
        }
        ReactGA.event(eventProps);
       // console.log('Information sent----->>>>',category, action, label);
    };
    return trackEvent;
};

export default useGAEventsTracker;