import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import Home from './pages/Home';
import OktaComponentError from './pages/OktaComponentError';
import io from 'socket.io-client';
import ReactGA from 'react-ga4';
import { Route, BrowserRouter, useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute } from '@okta/okta-react';
import env from './env.json';
import './assets/css/index.css';
import 'antd/dist/antd.css';
import './assets/css/bookadesk.css';
// import BookAConferenceRoom from './pages/BookAConferenceRoom';
import BookAnEvent from './pages/BookAnEvent';
import OrderFood from './pages/OrderFood';
import Concierge from './pages/Concierge';
import FindAnEvent from './pages/FindAnEvent';
import BookADesk from './pages/BookADesk';
import MyReservations from './pages/MyReservations';
import DiscoverCampuses from './pages/DiscoverCampuses';
import TELAvivEvent from './pages/TELAvivEvent';
import HQEvent from './pages/HQEvent';
import GoogleCallback from './pages/GoogleCallback';

createBrowserHistory();
const oktaAuth = new OktaAuth({
	clientId: env.OKTA_CLIENT_ID,
	issuer: env.OKTA_ISSUER,
	redirectUri: env.OKTA_REDIRECT_URI,
	scopes: ['openid', 'profile', 'email'],
	pkce: env.PKCE,
	disableHttpsCheck: env.NOT_HTTPS,
	tokenManager: { storage: 'sessionStorage' },
	transformAuthState: async (oktaAuth, authState) => {
        if (!authState.isAuthenticated) {
          return authState;
        }
        authState.accessToken.value = authState.accessToken.accessToken;
        return authState;
    }
});


let socket = null;

if(socket) {
	socket.on('connect_error', () => {
		socket.disconnect();
		console.log('error establishing connection');
	});
}


const OktaRoutes = () => {
	const history = useHistory();
	
	
	useEffect(()=>{
		
		oktaAuth.authStateManager.subscribe(authState => {
			if(authState.isAuthenticated) {
				socket = io(
					env.SOCKET_URL, 
					{
						path: env.NODE_ENV === 'local' ||  env.NODE_ENV === 'prod' ? '/socket.io' :  '/hr-flexspace/socket.io', 
						upgrade: false,
						auth: {
							token: authState.accessToken.value
						}
				});
				window.gtag('config', env.GOOGLE_ANALYTICS_ID, {
					'user_id' : authState.idToken.claims.email 
				});
				window.gtag('set', 'user_properties',  {
					'user_email': authState.idToken.claims.email 
				});
				window.gtag('config', env.GOOGLE_ANALYTICS_ID, {
					'user_name' : authState?.idToken?.claims?.name
				});
				window.gtag('set', 'user_properties',  {
					'user_name': authState?.idToken?.claims?.name
				});
				//authState?.idToken?.claims?.name
			}
		});
		ReactGA.initialize(env.GOOGLE_ANALYTICS_ID);
	},[]);

	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		const url = toRelativeUrl(originalUri, window.location.origin).replace('/hr-flexspace', '');
		history.replace(url);
    };


	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
			<Route path="/callback" component={OktaComponentError} />
			<SecureRoute exact path="/discover-campuses" component={DiscoverCampuses} />
			<SecureRoute exact path="/book-an-event" component={BookAnEvent} />
			<SecureRoute exact path="/order-food" component={OrderFood} />
			<SecureRoute exact path="/find-my-friends" component={Concierge} />
			<SecureRoute exact path="/find-an-event" component={FindAnEvent} />
			<SecureRoute exact path="/book-a-space" render={() => <BookADesk socket={socket} />} />
			<SecureRoute exact path="/my-reservations" render={() => <MyReservations socket={socket} />} />
			<SecureRoute exact path="/tel-aviv-event" component={TELAvivEvent} />
			<SecureRoute exact path="/hq-event" component={HQEvent} />
			<SecureRoute exact path="/google-callback" component={GoogleCallback} />
			<SecureRoute exact path="/" component={Home} />
		</Security>
	);
};


ReactDOM.render(
	<BrowserRouter basename={env.NODE_ENV === 'prod' || env.NODE_ENV === 'local'  ? '/' : '/hr-flexspace'}>
		<OktaRoutes />
	</BrowserRouter>,
	document.getElementById('root')
);
