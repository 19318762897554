import React from 'react';
import CloseIconDark from './../../assets/images/icons/arrow-right-solid-dark.svg';
import './ComingSoonModal.css';

const ComingSoonModal = (props) => {
  const close = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  if (props.isOpen === false)
    return null;
  return (
    <div id="myModal" className="modal" style={{ display: props.isOpen === true ? 'block' : 'none' }}>
      <div className="modal-content">
        <span className="close" onClick={close}><img src={CloseIconDark} /></span>
        <p>{props.title}</p>
      </div>
    </div>
  );
};

export default ComingSoonModal;
