import React, { useContext } from 'react';
import { Button } from 'antd';
import { FindEventContext } from '../../pages/FindAnEvent';
import FindAnEventItem from './FindAnEventItem';

const FindAnEventCalendarRow = ({ date }) => {
    const {
        events,
        now
    } = useContext(FindEventContext);

    /* TODO: Change Today from a button to label */
    return (
        <div className="find-an-event-calendar-row" style={{ display: "flex", flexDirection: "column", flex: "1 1 0px" }}>
            <div style={{ height: "130px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", height: "32px", justifyContent: "center" }}>
                    {date.day === now.day && date.month === now.month && <Button type="default" style={{ cursor: 'default' }}>Today</Button>}
                </div>
                <h2 style={{ fontSize: "1.6rem", color: "#666", marginBottom: 0 }}>{date.weekdayShort}</h2>
                <h2 style={{ fontSize: "2rem", color: "#000", marginBottom: 0 }}>{date.day}</h2>
            </div>
            <div className="find-an-event-calendar-col" style={{ minHeight: "500px", marginTop: '.5rem' }}>
                {events[date.month]?.[date.day]?.map((event, index) => {
                    return (
                        <FindAnEventItem item={event} key={index + 1} />
                    );
                })}
            </div>
        </div>
    );
};

export default FindAnEventCalendarRow;