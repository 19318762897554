import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import { Button, Input, Switch } from "antd";
import { PlusOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import axios from "axios";
import { DateTime } from "luxon";

import env from "../env";
import Header from "../shared/Header";
import EventNavigationbar from "../components/EventList/EventNavigationbar";
import SheldonComponent from "../components/SheldonComponent";
import PeerList from "../components/Concierge/PeerList";
import PeerSearchModal from "../components/Concierge/PeerSearchModal";

import useGAEventsTracker from '../hooks/useGAEventsTracker';

const gAEventTracker = useGAEventsTracker('Find my peers');

const { Search } = Input;

const Concierge = ({ history, authState }) => {
  const [data, setData] = useState([]);
  const [dataClone, setDataClone] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [selectedSlackUser, setSelectedSlackUser] = useState(null);
  const [currentUserEmail, setCurrntUserEmail] = useState(false);
  const [alertMessageObject, setAlertMessageObject] = useState({
    value: false,
    body: "",
  });
  const [slackUsers, setSlackUsers] = useState({});
  const [isOptIn, setIsOptIn] = useState(true);
  // const location = useLocation();

  // useEffect(async () => {
  //   if (location.search.length === 0) return;
  //   const queryStrings = location.search
  //     .split("?")[1]
  //     .split("&")
  //     .map((item) => {
  //       const keyVal = item.split("=");
  //       return {
  //         key: keyVal[0],
  //         value: keyVal[1],
  //       };
  //     });
  //   const emailIndex = queryStrings.findIndex((item) => item.key === "email");
  //   if (emailIndex !== -1) {
  //     const email = queryStrings[emailIndex].value;
  //     setCurrntUserEmail(email);
  //   }
  // }, [location.search]);

  const getTeams = async (emails) => {
    const config = {
      method: "post",
      url: `${env.BACKEND_URL}/get-employees-teams`,
      headers: { Authorization: authState.accessToken.accessToken },
      data: { emails: emails },
    };

    const response = await axios(config);

    if (response.data.ok) {
      return response.data.data;
    }
    return false;
  };

  const searchAndAddPeer = async (peerName) => {
    try {
      const resp = await axios({
        method: "get",
        url: `${env.BACKEND_URL}/search-slack-users?search=${peerName}`,
        headers: { Authorization: authState.accessToken.accessToken },
      });
      if (resp.data.ok) {
        setLoading(true);
        console.log(resp.data.data.length);
        if (resp.data.data.length === 0) return;
        setSelectedSlackUser(resp.data.data[0]);
        await addConnctionHandler(resp.data.data[0]);
        setTimeout(() => {
          setShowSearchModal(true);
        }, 500);
        // getPeers();
      } else {
        setSelectedSlackUser([]);
      }
    } catch (e) {
      setSelectedSlackUser([]);
      return;
    }
  };

  const addConnctionHandler = async (peer) => {
    setLoading(true);
    const data = {};
    const selectedPeer = peer ? peer : selectedSlackUser;

    const currentSlackUserEmail = currentUserEmail
      ? currentUserEmail
      : undefined;

    const slackUser = await getSlackUser(currentSlackUserEmail);
    if (slackUser) {
      data.currentUser = {
        username: slackUser.userName,
        slackId: slackUser.id,
        lenelId: slackUser.lenelId,
        channelId: slackUser.channelId,
        team: "",
      };
    }
    const slackPeer = await getSlackUser(selectedPeer.email);
    data.users = [
      {
        username: selectedPeer.username,
        slackId: selectedPeer.slackId,
        channelId: slackPeer.channelId,
        fullName: slackPeer.fullName,
        team: "",
      },
    ];
    const teams = await getTeams([slackUser.email, selectedPeer.email]);
    if (teams && teams.length > 0) {
      data.currentUser.team = teams[0].team;
      data.users[0].team = teams[1].team;
    }

    // params: lanelId
    const config = {
      method: "post",
      url: `${env.BACKEND_URL}/peers`,
      headers: { Authorization: authState.accessToken.accessToken },
      data: data,
    };
    gAEventTracker('Peer connection add click', authState?.idToken?.claims?.name);
    const response = await axios(config);
    setLoading(false);
    setAlertMessageObject({ value: true, body: response.data.message });
  };

  const updatePeerArrivalsNotifications = async (peerId, accept) => {
    const slackUser = await getSlackUser();
    if (!slackUser) return;
    const lenelId = slackUser.lenelId.toString();

    const data = {
      lenelId,
      accept,
    };

    const config = {
      method: "put",
      url: `${env.BACKEND_URL}/peers/notifications/${peerId}`,
      headers: { Authorization: authState.accessToken.accessToken },
      data: data,
    };

    const response = await axios(config);

    if (response.data.ok) {
      getPeers();
    }
  };

  const updatePeerNotifications = async (item, status, type) => {
    const itemIndex = data.findIndex((i) => i.id === item.id);
    if (itemIndex === -1) return;
    const updatedData = [...data];
    updatedData[itemIndex][type] = status ? "active" : "inactive";

    setData(updatedData);
    setDataClone(updatedData);
    setLoading(true);

    const slackUser = await getSlackUser();
    if (!slackUser) {
      setLoading(false);
      return;
    }
    const lenelId = slackUser.lenelId.toString();
    const notification_type =
      type === "outboundStatus"
        ? "outboundNotifications"
        : "inboundNotifications";
    const axiosData = {
      lenelId,
      notification_type,
      peers: [item.id],
    };

    const config = {
      method: "put",
      url: `${env.BACKEND_URL}/peers/notifications`,
      headers: { Authorization: authState.accessToken.accessToken },
      data: axiosData,
    };

    const response = await axios(config);
    setLoading(false);
    if (response.data.ok) {
      await getPeers();
    }
  };

  const deletePeerNotifications = async (peerId) => {
    setLoading(true);
    const slackUser = await getSlackUser();
    if (!slackUser) {
      setLoading(false);
      return;
    }
    const lenelId = slackUser.lenelId.toString();
    const axiosData = {
      lenelId,
      peerId,
    };

    const config = {
      method: "delete",
      url: `${env.BACKEND_URL}/peers/notifications`,
      headers: { Authorization: authState.accessToken.accessToken },
      data: axiosData,
    };

    const response = await axios(config);
    setLoading(false);
    if (response.data.ok) {
      await getPeers();
    }
  };

  const getSlackUser = async (email) => {
    const slackEmail = email ? email : authState.idToken.claims.email;

    if (slackUsers[slackEmail]) {
      return slackUsers[slackEmail];
    }

    const config = {
      method: "get",
      url: `${env.BACKEND_URL}/get-slack-user?email=${slackEmail}&lenelId=true`,
      headers: { Authorization: authState.accessToken.accessToken },
    };

    const response = await axios(config);
    if (response.data.ok) {
      const updatedSlackUsers = { ...slackUsers };
      updatedSlackUsers[slackEmail] = response.data.data;
      setSlackUsers(updatedSlackUsers);
      return response.data.data;
    }

    return false;
  };

  const getPeers = async () => {
    setLoading(true);
    const slackUser = await getSlackUser();
    if (!slackUser) return;
    const lenelId = slackUser.lenelId;
    // // params: lanelId
    const config = {
      method: "get",
      url: `${env.BACKEND_URL}/peers?lenelId=${lenelId}`,
      headers: { Authorization: authState.accessToken.accessToken },
      // data: users
    };
    let peers = [];
    const response = await axios(config);

    if (response.data.ok) {
      peers = response.data.peers;
      const optIn = !!response.data.optIn;
      const updatedPeers = peers.map((peer) => {
        const peerData = {
          id: peer.lenelId,
          key: peer.lenelId,
          outboundStatus: peer.outboundStatus,
          inboundStatus: peer.inboundStatus,
          peerName: peer.name,
          officeDeskBooking: peer.hotelSeat
            ? peer.hotelSeat
            : peer.seat
            ? peer.seat
            : "",
          team: peer.team ? peer.team : "",
          date: peer.connectedAt
            ? DateTime.fromSeconds(peer.connectedAt).toFormat("MM/dd/yyyy")
            : "",
        };
        peerData.item = peerData;
        return peerData;
      });
      setData(updatedPeers);
      setIsOptIn(optIn);
      setDataClone(updatedPeers);
    }

    setLoading(false);
  };

  const updateUserOptIn = async (value) => {
    setLoading(true);
    setIsOptIn(value);
    const slackUser = await getSlackUser();
    if (!slackUser) {
      setLoading(false);
      return;
    }
    const lenelId = slackUser.lenelId.toString();

    const config = {
      method: "put",
      url: `${env.BACKEND_URL}/peers?lenelId=${lenelId}`,
      headers: { Authorization: authState.accessToken.accessToken },
    };

    const response = await axios(config);

    if (!response.data.ok) {
      setIsOptIn(!value);
    }

    setLoading(false);
  };

  const triggerBadgeInOut = async (badgeStatus, badgeId) => {
    let lenelId = badgeId;
    const badgeUrl = badgeStatus ? "badge-in" : "badge-out";
    if (!lenelId) {
      const slackUser = await getSlackUser();
      if (!slackUser) {
        setLoading(false);
        return;
      }
      lenelId = slackUser.lenelId.toString();
    }
    const config = {
      method: "post",
      url: `${env.BACKEND_URL}/${badgeUrl}`,
      headers: { Authorization: authState.accessToken.accessToken },
      data: { lenelId },
    };

    const response = await axios(config);
    if (response.data.ok) {
      console.log(`${lenelId} ${badgeUrl}`);
    }
  };

  useEffect(() => {
    getPeers();
  }, []);

  const handleBack = () => {
    history.push("/");
  };

  const toggleModal = () => {
    setShowSearchModal(false);
    setAlertMessageObject({ value: false, body: "" });
    setTimeout(() => {
      getPeers();
    }, 1000);
  };

  const handleSearch = (name) => {
    if (name.length === 0) {
      setData([...dataClone]);
      return;
    }
    let updatedData = [...dataClone];

    updatedData = updatedData.filter((item) =>
      item.peerName.toLowerCase().includes(name.toLowerCase())
    );

    setData(updatedData);
  };

  const getNotOptIntInBody = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <h4 style={{ marginBottom: "2rem", fontWeight: "bold" }}>
          You are currently opted out of Find My Colleagues please opt in to see
          the available features
        </h4>
        <div style={{ marginRight: "1rem" }}>
          <span style={{ marginRight: ".5rem", fontWeight: "bold" }}>
            Opt-In:{" "}
          </span>
          <Switch
            checked={isOptIn}
            onChange={(value) => updateUserOptIn(value)}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Header hideMyBadge={true} />
      <EventNavigationbar
        navigationTitle="Find My Friends"
        path="/"
        onBack={() => handleBack()}
      />

      <div className="custom-bck inner-bck">
        <section className="custom-container" style={{ maxWidth: "100rem" }}>
          <div className="flexevt-card-btn-wrap">
            <div className="flexevt-card-inner-wrap">
              <p>
                This Connected App feature allows you to give visibility to your
                work friends and peers about when you arrive at an office.
                Giving your permission means they'll get a Slack message when
                you badge-in to the office (once per day). This goes two-ways;
                you can also request to know when your friends badge-in, and
                with their permission, be notified via Slack. Below are the
                requests for you to review. Have fun more easily connecting with
                your work friends when you're at the office!
              </p>
              <div
                style={{
                  marginTop: "20px",
                  width: "100%",
                  minHeight: "200px",
                  backgroundColor: "white",
                  borderRadius: "12px",
                }}
              >
                <div
                  style={{
                    margin: "10px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {isOptIn && (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 style={{ marginBottom: 0 }}>Find My Friends</h2>
                        <Search
                          style={{ width: "250px", marginLeft: "1rem" }}
                          placeholder="Search"
                          // suffix={ <SearchOutlined/>}
                          onChange={(e) => handleSearch(e.target.value)}
                          onSearch={(e) => handleSearch(e)}
                        />
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "1rem" }}>
                          <span
                            style={{ marginRight: ".5rem", fontWeight: "bold" }}
                          >
                            Opt-In:{" "}
                          </span>
                          <Switch
                            checked={isOptIn}
                            onChange={(value) => updateUserOptIn(value)}
                          />
                        </div>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => setShowSearchModal(!showSearchModal)}
                        >
                          New Connection
                        </Button>
                        {env.NODE_ENV !== "prod" && (
                          <>
                            <Button
                              style={{ marginLeft: ".5rem" }}
                              type="primary"
                              icon={<LoginOutlined />}
                              onClick={() => triggerBadgeInOut(true)}
                            >
                              Badge In
                            </Button>
                            <Button
                              style={{ marginLeft: ".5rem" }}
                              type="primary"
                              icon={<LogoutOutlined />}
                              onClick={() => triggerBadgeInOut(false)}
                            >
                              Badge Out
                            </Button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {!isOptIn ? (
                  getNotOptIntInBody()
                ) : (
                  <PeerList
                    data={data}
                    loading={loading}
                    onUpdate={updatePeerArrivalsNotifications}
                    onUpdatePeerNotifications={updatePeerNotifications}
                    onDelete={deletePeerNotifications}
                    onBadgeTrigger={triggerBadgeInOut}
                    onRequestConnection={searchAndAddPeer}
                  />
                )}
              </div>
              <p style={{ marginTop: "20px" }}>
                Privacy Notice: Arrival notifications are sent only if the
                arriving party has granted permission to do so. A permission
                request is send whenever you add a user to the list of
                colleagues that you wish to share your location with. If the
                user accepts your request, then you will receive a notification
                whenever they arrive on-site (badge in). Neither you or the
                other user will receive notifications unless each respective
                party has granted permission. All arrival notifications are
                deleted at midnight each day.
              </p>
            </div>
          </div>
        </section>
      </div>
      {showSearchModal && (
        <PeerSearchModal
          visible={showSearchModal}
          onToggle={toggleModal}
          setSelectedUser={setSelectedSlackUser}
          loading={loading}
          alertMessageObject={alertMessageObject}
          selectedUser={selectedSlackUser}
          onSubmit={addConnctionHandler}
        />
      )}
      <SheldonComponent />
    </React.Fragment>
  );
};

export default withRouter(withOktaAuth(Concierge));
