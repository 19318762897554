import React, { useState } from 'react';

const EventListSidebar = (props) => {
  const [preferredLocation, setPreferredLocation] = useState(props.location);
  const [isPanEmployee, setPanEmployee] = useState(props.guestsAttending.emp);
  const [isExternalGuest, setExternalGuest] = useState(props.guestsAttending.guest);
  const [isExecutive, setPanExecutive] = useState(props.guestsAttending.exec);
  const [isVip, setVip] = useState(props.guestsAttending.vip);
  const [configType, setConfigType] = useState(props.configuration);


  const handlePreferredLocation = (location) => {
    setPreferredLocation(location);
    props.locationConfig(location);
    location === 'multipurpose' && handleConfiguration('Auditorium');
  };
  const handleConfiguration = (config) => {
    setConfigType(config);
    props.ConfigurationConfig(config);
  };
  const handlePanEmployee = (panemp) => {
    setPanEmployee(panemp);
    props.guestAttendingConfig(panemp, isExternalGuest, isExecutive, isVip);
  };
  const handleExternalGuest = (extGuest) => {
    setExternalGuest(extGuest);
    props.guestAttendingConfig(isPanEmployee, extGuest, isExecutive, isVip);
  };
  const handlePanExecutive = (panexec) => {
    setPanExecutive(panexec);
    props.guestAttendingConfig(isPanEmployee, isExternalGuest, panexec, isVip);
  };
  const handleVip = (vip) => {
    setVip(vip);
    props.guestAttendingConfig(isPanEmployee, isExternalGuest, isExecutive, vip);
  };
  return (

    <div className="sidebar-wrap">
      <div className="flexevt-card-inner-wrap">
        <div className="border-grid border-bt">
          Preferred Location
        </div>
        <div className="border-grid border-bt radio-wrap">
          <div className="label-text-main-wrap" onChange={(e) => handlePreferredLocation(e.target.value)}>
            <div className="label-text-wrap">
              <input type="radio" id="multipurpose" checked={preferredLocation === 'multipurpose'} name="location" value='multipurpose' />
              <label>Multi-Purpose Room</label>
            </div>
            <div className="label-text-wrap">
              <input type="radio" id="community" checked={preferredLocation === 'community'} name="location" value='community' />
              <label>Community Space</label>
            </div>
          </div>
        </div>
        <div className="border-grid border-bt">
          How many guests will be attending?
        </div>
        <div className="form-input-label-wrap">
          <div className="input-label-wrap">
            <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} onChange={(e) => handlePanEmployee(e.currentTarget.value)} onKeyDown={(evt)=>['e','E','+','-'].includes(evt.key) && evt.preventDefault()} onKeyUp={(e) => { if (e.currentTarget.value < 0) setPanEmployee(e.currentTarget.value * -1); }} type="number" min="0" value={isPanEmployee} /><label className="label-text">PANW Employees & Contractors</label>
          </div>
          <div className="input-label-wrap">
            <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} onChange={(e) => handleExternalGuest(e.currentTarget.value)} onKeyDown={(evt)=>['e','E','+','-'].includes(evt.key) && evt.preventDefault()} onKeyUp={(e) => { if (e.currentTarget.value < 0) setExternalGuest(e.currentTarget.value * -1); }} type="number" min="0" value={isExternalGuest} /><label className="label-text">External guests</label>
          </div>
          <div className="input-label-wrap">
            <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} onChange={(e) => handlePanExecutive(e.currentTarget.value)} onKeyDown={(evt)=>['e','E','+','-'].includes(evt.key) && evt.preventDefault()} onKeyUp={(e) => { if (e.currentTarget.value < 0) setPanExecutive(e.currentTarget.value * -1); }} type="number" min="0" value={isExecutive} /><label className="label-text">PANW Executives</label>
          </div>
          <div className="input-label-wrap">
            <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} onChange={(e) => handleVip(e.currentTarget.value)} onKeyDown={(evt)=>['e','E','+','-'].includes(evt.key) && evt.preventDefault()} onKeyUp={(e) => { if (e.currentTarget.value < 0) setVip(e.currentTarget.value * -1); }} type="number" min="0" value={isVip} /><label className="label-text">VIPs</label>
          </div>
        </div>
        <div className={preferredLocation !== 'community' ? 'border-bt-wrap border-tp border-bt' : 'border-bt-wrap border-tp border-bt config-fade'}>Configuration type</div>

        <div className={preferredLocation !== 'community' ? 'label-text-main-wrap' : 'label-text-main-wrap config-fade'} onChange={(e) => handleConfiguration(e.target.value)}>
          <div className="label-text-wrap"><input type="radio" disabled={preferredLocation !== 'community' ? false : true} id="Auditorium" checked={configType === 'Auditorium'} name="configuration" value='Auditorium' />
            <label>Auditorium</label></div>
          <div className="label-text-wrap">
            <input type="radio" disabled={preferredLocation !== 'community' ? false : true} id="Boardroom" checked={configType === 'Boardroom'} name="configuration" value='Boardroom' />
            <label>Boardroom</label>
          </div>
          <div className="label-text-wrap">
            <input type="radio" disabled={preferredLocation !== 'community' ? false : true} id="Classroom" checked={configType === 'Classroom'} name="configuration" value='Classroom' />
            <label>Classroom</label>
          </div>
          <div className="label-text-wrap">
            <input type="radio" disabled={preferredLocation !== 'community' ? false : true} id="UShape" checked={configType === 'UShape'} name="configuration" value='UShape' />
            <label>U-shape</label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventListSidebar;
