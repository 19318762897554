import React, { useState } from 'react';
import LabelHeader from './../components/LabelHeader';
import HomeEventCard from './HomeEventCard';
import CloseIcon from './../assets/images/bookEvent/close_white.svg';
import ProgressBar from './ProgressBar';
import { DatePicker} from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
const { RangePicker } = DatePicker;

const LocationSpace = (props) => {
    const [isHome, setHome] = useState(false);
    const [fromdate,setFromDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [dataValue,setDataValue]= useState('');
    
    const navigateHome = () => {
        setDataValue(dataValue);
        setHome(!isHome);
        props.onhandleClose();
    };  
    const onDateChange = async (value, dateStrings) => {
     await setFromDate(dateStrings[0]);
       await setEndDate(dateStrings[1]);
       setDataValue(value);
    };

    return (
        <React.Fragment>
            {isHome === false ? <div className='flex-event-bg'> <div className="close-icon" onClick={navigateHome}><img src={CloseIcon} alt="CloseIcon" /></div>
                <div className="flexevt-card-btn-wrap form-main-wrap">
                    <LabelHeader labelNum='2' labelPath='' labelContent='Ok, when will your event take place?' />
                    
                        
                    <div className="card-inner-wrap"> 
                            <div className="evnt-lable">Event Start and ends* </div>
                            <RangePicker                                    
                                    showTime={{ use12Hours: true }}
                                    format="ddd MMM DD,YYYY hh:mma"
                                    onChange={onDateChange}
                                    disabledDate={d=>!d ||d.isSameOrBefore(moment())}
                                />
                    </div>
                    <div className="btn-wrap"> <button className={`next-btn ${fromdate !== '' && endDate !== '' ? '' : 'disable-btn'}`} disabled={fromdate !== '' && endDate !== '' ? false : true} onClick={()=>props.navigateGuestAttend(fromdate,endDate)}>Continue to next</button></div>
                    <div className="bar-position">
                        <ProgressBar filledPercentage={props.location==='community'?'33%':'25%'} />
                    </div>
                </div>
            </div> : <HomeEventCard />}
            
        </React.Fragment>
    );
};

export default LocationSpace;