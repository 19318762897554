import React, { useState, useEffect } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import env from '../env';
import '../../src/assets/css/mybookings.css';
import { DateTime } from 'luxon';


import { Modal, Table, Button, Spin, Popconfirm, Popover, Tag, Tooltip } from 'antd';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const MyBookings = ({ isOpen, onClose, authState }) => {
    const [bookingsData, setBookingsData] = useState([]);
    // const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [currentItem, setCurrentItem] = useState(null);


    const getBookings = async () => {
        setLoading(true);
        let config = { method: 'get', url: `${env.BACKEND_URL}/desk-bookings`, headers: { 'Authorization': authState.accessToken.value } };
        let response = await axios(config);
        const data = response.data;
        if (data) {
            const updatedBookings = data.bookings.map(item => {
                const currentDate = DateTime.utc();
                const itemDate = DateTime.fromMillis(Date.parse(item?.startDate), { zone: 'utc' });
                const diff = itemDate.diff(currentDate, ['years', 'months', 'days', 'hours']).hours;
              

                //console.log(item.date);
                return {
                    key: item.id,
                    id: item.id,
                    date: moment(item.date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY') ,
                    address: item?.space?.floor?.building?.address,
                    floor: item?.space?.floor?.name,
                    space: item?.space?.code,
                    item: {...item, difference : diff },
                    startDate: item?.startDate,
                };
            });
            setBookingsData(updatedBookings);
        }
        setLoading(false);

        return;
    };

    const checkIn = async (spaceId) => {
        let config = { method: 'post', url: `${env.BACKEND_URL}/check-in-booking?spaceId=${spaceId}`, headers: { 'Authorization': authState.accessToken.value } };
        let response = await axios(config);
        const data = response.data;
        if (data) {
            await getBookings();
        }

        return;
    };

    const deleteBoooking = async (spaceId) => {
        // setLoading(true);
        let config = { method: 'post', url: `${env.BACKEND_URL}/delete-booking?spaceId=${spaceId}`, headers: { 'Authorization': authState.accessToken.value } };
        let response = await axios(config);

        if (response.data) {
            // setShowConfirmDeleteModal(false);
            // setCurrentItem(null);
            await getBookings();
        }
        // setLoading(false);
    };

    useEffect(() => {
        if (isOpen) {
            getBookings();
        }
    }, [isOpen]);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '20%',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '20%',
        },
        {
            title: 'Floor',
            dataIndex: 'floor',
            key: 'floor',
            width: '20%',
        },
        {
            title: 'Space',
            dataIndex: 'space',
            key: 'space',
            width: '15%',
        },
        {
            title: 'Actions',
            dataIndex: 'item',
            key: 'item',
            width: '25%',
            render: item => {
                return (
                    [
                        <React.Fragment key={item.id}>
                        
                        { !item.checkedIn && <Tooltip  placement="top" title={(item.difference > -2 && item.difference <= 4) ? <span>If not checked in, booking will be released 2 hours after it starts.</span> : <span>Check-in is available 4hr before start of your booking.</span>}>
                            <Button 
                                disabled={!(item.difference > -2 && item.difference <= 1)} 
                                style={{padding: 0, height: 'auto'}} 
                                type='text'  
                                onClick={() => {
                                    checkIn(item.id);
                                    // setCurrentItem(item);
                                    // setShowConfirmDeleteModal(true);
                                }} 
                            >
                            <Tag color={ (item.difference > -2 && item.difference <= 1) ?  '#13b5b0'  : '#ccc'} >CHECK-IN</Tag>
                            </Button>
                        </Tooltip> }
                        {item.checkedIn && <Tag >CHECKED-IN</Tag> }
                       
                        <Popover 
                        content={<React.Fragment>
                            <div>Coming soon! </div>
                            <div>For now, please delete and create a new booking.</div>
                            </React.Fragment>
                        }>
                            <span style={{cursor: 'pointer', color: '#0071e3'}}>Modify</span>
                        </Popover>
                        <span> |</span>
                        <Popconfirm
                                title={<><span>Are you sure you want to cancel this booking?</span><div>{item?.space?.code} </div></>}
                                onConfirm={() => {
                                    // setCurrentItem(null);
                                    deleteBoooking(item.id);
                                }}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger type='text' onClick={() => {
                                    // setCurrentItem(item);
                                    // setShowConfirmDeleteModal(true);
                                }} icon={<DeleteOutlined />} />
                            </Popconfirm>
                       
                        </React.Fragment>
                    ]
                );
            }
        },
    ];


    return (
        <>
            <Modal
                title="My Reservations"
                visible={isOpen}
                width={1000}
                onOk={() => onClose(false)}
                onCancel={() => onClose(false)}
                footer={null}
            >
                <div className='bookings-table'>
                    {loading
                    ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px'}}>
                        <Spin indicator={loaderIcon} />
                    </div>
                    : <Table
                        columns={columns}
                        dataSource={bookingsData} pagination={false}/>}
                </div>


            </Modal>

            {/* {showConfirmDeleteModal && currentItem && <Modal key={currentItem.id + 1}
                title="Delete confirmation"
                centered
                visible={showConfirmDeleteModal}
                onOk={() => {
                    setShowConfirmDeleteModal(false);
                    setCurrentItem(null);
                }}
                onCancel={() => {
                    setCurrentItem(null);
                    setShowConfirmDeleteModal(false);
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        setCurrentItem(null);
                        setShowConfirmDeleteModal(false);
                    }}>
                        No
                    </Button>,
                    <Button
                        danger onClick={() => deleteBoooking(currentItem.id)} key="submit" type="primary">
                        Yes
                    </Button>
                ]}
            >
                {loading
                    ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '2em' }}>
                        <Spin indicator={loaderIcon} tip="Loading..." />
                    </div>
                    : <p> Are you sure you want to cancel this booking : {currentItem?.space?.code}?</p>}
            </Modal>} */}
        </>

    );

};

export default withOktaAuth(MyBookings);
