import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { DownSquareOutlined } from '@ant-design/icons';
import { Carousel, Menu, Dropdown, Spin} from 'antd';
import axios from 'axios';
import {LoadingOutlined} from '@ant-design/icons';
import EventHeader from '../components/EventList/EventHeader/EventHeader';
import EventNavigationbar from '../components/EventList/EventNavigationbar';
import env from '../env.json';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import '../assets/css/discovercampuses.css';
import GoogleMap from '../components/GoogleMap';
import SheldonComponent from '../components/SheldonComponent';

// import one from '../assets/images/sites/1.jpg';
// import two from '../assets/images/sites/2.jpg';
// import three from '../assets/images/sites/3.jpg';
// import highlights from '../assets/images/sites/highlights.png';
// import map from '../assets/images/sites/map.png';

// const menu = (
// 	<Menu>
// 		<Menu.Item>
// 			<div>Santa Clara HQ</div>
// 		</Menu.Item>
// 	</Menu>
// );

const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


class DiscoverCampuses extends React.Component {

	constructor() {
		super();
		this.state = {
			loading: false,
			campusesData: [],
			selectedCampus: null,
			mapKey: null
		};
		this.dropdownButtonRef = React.createRef();
	}


	componentDidMount() {
		this.getCampusData();
	}

	setSelectedCampus = (site) => {
		const index = this.state.campusesData.findIndex((campus) => campus.Site === site);

		if(index !== -1) {
			this.setState({selectedCampus: this.state.campusesData[index]});

		}
	}

	getMenu = () => {
		return (<Menu onClick={({key}) => this.setSelectedCampus(key)}>
			{this.state.campusesData.map((item) => <Menu.Item key={item.Site}>
						<div>{item.Site}</div>
					</Menu.Item>)}
				</Menu>);
	}

	getCampusData = async () => {
		let config = { method: 'get', url: `${env.BACKEND_URL}/get-campus-spreadsheet-data`, headers: { 'Authorization': this.props.authState.accessToken.value } };
		this.setState({loading: true});
		try {
			let response = await axios(config);
			if(!(response.data.data)) {
				throw response.data;
			}
			this.setState({campusesData: response.data.data , selectedCampus: response.data.data[0], mapKey: response.data.mapKey});
		} catch(err) {
			console.log(err);
		}
		this.setState({loading: false});

	}

	// getSiteDrodown = () => {
	// 	const children = this.state.campusesData.map(item => <Option key={item.Site} value={item.Site}>{item.Site}</Option>);
	// 	return <div style={{margin: '-20px -24px', border: '2px solid #fa582d'}}><Select
	// 		allowClear
	// 		showSearch
	// 		style={{ width: '100%', minWidth: 200}}
	// 		bordered={true}
	// 		placeholder="Please select"
	// 		onChange={(val) =>  {
	// 			this.setSelectedCampus(val);
	// 			this.dropdownButtonRef.current.click();

	// 		}}
	// 		filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
	// 		filterSort={(optionA, optionB) =>
	// 			optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
	// 		}
	// 	>
	// 		{children}
	// 	</Select></div>;
	// }

	renderMap(status)  {
		switch (status) {
		  case Status.LOADING:
			return (<div className='loader'>
					<Spin indicator={loaderIcon} />
				</div>);
		  case Status.FAILURE:
			return <div>Could not load map</div>;
		  case Status.SUCCESS:
			return <GoogleMap campus={this.state.selectedCampus} />;
		}
	}



	render() {
		if (env.NODE_ENV === 'stage' || env.NODE_ENV === 'prod'){
			return (
				<>
					<EventHeader/>
					<EventNavigationbar navigationTitle='Discover Campuses'  path='/'/>
					<br/>
					<br/>
					<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
						<div style={{width: '50%'}}>
							<p>Coming Soon! We will be using this page to show active amenities, local businesses and transport for each site at Palo Alto Networks.
								For now, you can visit the loop <a href='https://theloop.paloaltonetworks.com/loop/departments/Places' target='_blank' rel='noreferrer'>here</a> for relevant information.
							</p>
						</div>
					</div>
				</>
			);
		}
		return (
			<>
				<EventHeader/>
				<EventNavigationbar navigationTitle='Discover Campuses'  path='/'/>
				<div className='discover-site-wrapper'>
					{this.state.loading
					? <div className="loader">
							<Spin indicator={loaderIcon} />
					</div>
					:
					<div className='discover-site-container'>
						<div className='site-header'>

							<Dropdown overlay={this.getMenu()}>
								<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
									<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
										<h1>{this.state.selectedCampus &&  this.state.selectedCampus.Site }</h1>
										<div style={{marginLeft: 10}}>
											<DownSquareOutlined />
										</div>
									</div>
								</a>
							</Dropdown>
							{/* <Popover content={this.getSiteDrodown()} trigger="click" placement="bottom" style={{border: '1px solid red'}}>
								<a className="ant-dropdown-link" ref={this.dropdownButtonRef}  onClick={e => e.preventDefault()}>
									<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
										<h1>{this.state.selectedCampus &&  this.state.selectedCampus.Site }</h1>
										<div style={{marginLeft: 10}}>
											<DownSquareOutlined />
										</div>
									</div>
								</a>
							</Popover> */}

						</div>

						<h2>Introduction</h2>
						<div className='site-intro' style={{marginBottom: 80}}>
						{ this.state.selectedCampus &&
							<Carousel autoplay>
								{this.state.selectedCampus && this.state.selectedCampus['Image URL'].split('\n').map(url => {
									return <div key={url}>
										<img src={url} style={{height : '300px', objectFit: 'cover'}}/>
									</div>;
								})}

							</Carousel> }
							<div>{this.state.selectedCampus && this.state.selectedCampus.Introduction }</div>
						</div>
						<br/>

						<h2>Around Me</h2>
						{this.state.selectedCampus &&  <div >
							<Wrapper apiKey={this.state.mapKey} render={ (status) => this.renderMap(status)} />
							{/* <ErrorBoundary>
								<iframe
									width="100%"
									height="450"
									style={{border:0}}
									loading="lazy"
									allowFullScreen
									referrerPolicy="no-referrer-when-downgrade"
									src={`https://www.google.com/maps/embed/v1/place?key=${this.state.mapKey}&q=${this.state.selectedCampus.Address}`}>
								</iframe>
							</ErrorBoundary> */}
						</div> }
						<br/>
						<br/>

						<h2>Highlights</h2>
						<div>
							{this.state.selectedCampus
							&& this.state.selectedCampus.highlights
							&& this.state.selectedCampus.highlights
							.map(item => (<div key={item.Highlight}>
								<div style={{display: 'flex', marginLeft: '20px'}}>
									<img src={'https://drive.google.com/uc?id=' + item.Icon.split('/d/')[1].split('/')[0]} style={{height: '24px', marginRight: '10px'}} />
									<p style={{color: '#fa582d', fontWeight: 'bold'}}>{ item.Highlight }</p>
								</div>
							</div>))}

						</div>
						{/* <img src={highlights} /> */}
						<br/>
						<br/>

						<h2>Additional Info</h2>
						<ul>
							{this.state.selectedCampus && this.state.selectedCampus['Additional Info'].split('•').filter((item, index) => index !== 0).map(text => <li key={text}>
								{text}
							</li>)}

						</ul>
					</div>
					}

				</div>
				<SheldonComponent/>
			</>

		);
	}

}

export default withOktaAuth(DiscoverCampuses);
