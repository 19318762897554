import React, {useState, useEffect} from 'react';
import { Button } from 'antd';
import { withOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { DateTime } from 'luxon';
import env from '../../env';
import { getGoogleRefreshToken } from '../../helpers';
import { MenuOutlined } from '@ant-design/icons';

import InteractiveMapFilter from './InteractiveMapFilter';

const InteractiveMapSideNav = ({
    nodes, 
    showLegends, 
    height, 
    setSelectedDesk, 
    date, 
    meetingRoomsAvalibilites, 
    authState, 
    filters, 
    onFilterChange, 
    hasNeighborhood, 
    setShowNeighborhood, 
    showNeighborhood,
    canShowPrivateOffice,
    showPrivateOffice,
    setShowPrivateOffice,
    hasPriavteRooms,
    hasConfreneceRooms,
    showEvents,
    setShowEvents,
    meetingRoom,
}) => {
    const [availableDesks, setAvailableDesks] = useState(0);
    const [events, setEvents] = useState([]);
    const [isMobileVisible, setIsMobileVisible] = useState(false);
    
	const getMeetingRoomEvents = async (date) => {
		const googleRefreshToken = await getGoogleRefreshToken(authState.accessToken.value);
		const userCalenderId = authState.idToken.claims.email.split('@')[0] + env.DOMAIN_SUFFIX;
		if(!googleRefreshToken || !userCalenderId) return;
		let config = { 
			method: 'post', 
			url: `${env.BACKEND_URL}/get-google-events`, 
			headers: { 'Authorization': authState.accessToken.value },
			data: {
				refreshToken: googleRefreshToken,
				calendarId: userCalenderId,
                date: date.toISO()
			}
		};
		let response = await axios(config);
		if(response.data.ok) {
			setEvents(response.data.data);
		}
	};

    useEffect(() => {
        if(showLegends.showMeetingRooms) {
            getMeetingRoomEvents(date);
        }
    }, [showLegends.showMeetingRooms, date, meetingRoomsAvalibilites]);

    useEffect(() => {
        if( nodes.length > 0) {

            let availDesks = 0;
            nodes.forEach(item => {
               
                if(item.node.dailyBookableSpacesAvailability[0]?.availability && !item.node.dailyBookableSpacesAvailability[0]?.searched) {
                    availDesks +=1;
                }
            });

            // const capacity = Math.round(  (availDesks / response.data.spaces?.spaceConnection?.edges.length) * 100);
            // setFloorCapacity( 100 - capacity);
            setAvailableDesks(availDesks);
        } else {
            setAvailableDesks(0);
        }
    }, [nodes]);

    // const getLegends = (showLegends) => {
    //     return (
    //         <div className="neighborhood-legend-container info-box">
    //             {showLegends.showHotelDesks &&
    //                 <div className="neighborhood-legend">
    //                     <div className="neighborhood-legend-box "><div className="bookable-desk"></div></div>
    //                     <p className="neighborhood-legend-text">Bookable desk</p>
    //                 </div>
    //             }
    //             {showLegends.showNeighborhood &&
    //                 <>
    //                     <div className="neighborhood-legend ">
    //                         <div className="neighborhood-legend-box neighborhood-large"></div>
    //                         <p className="neighborhood-legend-text">Neighborhood</p>
    //                     </div>
    //                     <div className="neighborhood-legend">
    //                         <div className="neighborhood-legend-box neighborhood-small"></div>
    //                         <p className="neighborhood-legend-text">Sub Neighborhood</p>
    //                     </div>
    //                 </>
    //             }
    //             {showLegends.showDepartmentRestrictedHotelDesks &&
    //                 <div className="neighborhood-legend">
    //                     <div className="neighborhood-legend-box "><div className="bookable-desk" style={{backgroundColor: '#fa582d'}}></div></div>
    //                     <p className="neighborhood-legend-text">Bookable desk (department)</p>
    //                 </div>
    //             }
    //              {showLegends.showMeetingRooms &&
    //                 <div className="neighborhood-legend">
    //                     <div className="neighborhood-legend-box" style={{height: 'auto'}}><div style={{position: 'relative', top: '-3px', fontSize: '2em', color: 'green'}}>&#3664;</div></div>
    //                     <p className="neighborhood-legend-text">Bookable Meeting Room</p>
    //                 </div>
    //             }
    //         </div>
    //     );
    // };

    const getTime = (item) => {
        let startDate = DateTime.fromISO(item.start.dateTime);
        let endDate =  DateTime.fromISO(item.end.dateTime);

        return <div key={item.id + startDate.toLocaleString(DateTime.DATE_FULL)}>
            {/* <div>{startDate.toLocaleString(DateTime.DATE_FULL)}</div> */}
            <div>{startDate.toLocaleString(DateTime.TIME_SIMPLE)} - {endDate.toLocaleString(DateTime.TIME_SIMPLE)}</div>
        </div>;
    };

    const getNodeInfoFromGoogleName = (nodes, event) => {
        const index = nodes.findIndex(({node}) =>{
            if(!event.location) return false;
            return event.location.includes(node.googleName);
        } );
        if(index !== -1) {
            return {code: nodes[index].node.code, name: nodes[index].node.name};     
        }
        return {};
    };

    const getMeetingRoomBookings = (events) => {

       return <>
            {events.map(event => {
                const selectedNode =  getNodeInfoFromGoogleName(nodes, event);
                if(Object.keys(selectedNode).length === 0) return null;

                return  <div className="floor-map-sidenav-box" key={'sidenav-booking-item-' + event.id}>
                <div className="floor-map-sidenav-box-content">
                    <Button style={{ paddingLeft: 0 }} type="link" onClick={() => setSelectedDesk(selectedNode.code)}>{selectedNode.name}</Button>
                    {getTime(event)}
                </div>
            </div>;
            })}
        
       </>;
            
    };

    const getInfo = (title, value, styles) => {
        return <div className="info-box" style={styles.container ? styles.container : null}>
            <h4 style={styles.title ? styles.title : null}>{value}</h4>
            <p>{title}</p>
        </div>;
    };

    const getSidenavClasses = () => {
        const classes = ['floor-map-sidenav'];
        if(isMobileVisible) {
            classes.push('floor-map-sidenav-visible');
        }
        return classes.join(' ');
    };

    return <>
          {/* <img src={currentFloorMap.background && currentFloorMap.background.url && currentFloorMap.background.url} alt='' className='current-floor-img'/> */}
          <div className={getSidenavClasses()} style={{ height: height}}>
              <div className='floor-map-sidenav-toggle' onClick={() => setIsMobileVisible(!isMobileVisible)}>
                <MenuOutlined />
              </div>
            <InteractiveMapFilter
                filters={filters} 
                onFilterChange={onFilterChange}
                hasNeighborhood={hasNeighborhood}
                setShowNeighborhood={setShowNeighborhood}
                showNeighborhood={showNeighborhood}
                canShowPrivateOffice={canShowPrivateOffice}
                showPrivateOffice={showPrivateOffice}
                setShowPrivateOffice={setShowPrivateOffice}
                hasPriavteRooms={hasPriavteRooms}
                hasConfreneceRooms={hasConfreneceRooms}
                showEvents={showEvents}
                setShowEvents={setShowEvents}
                nodes={nodes}
                meetingRoom={meetingRoom}
            />
            <div className="info-box-container">
                {/* {getInfo('FLOOR CAPACITY', `${floorCapacity}%`)} */}
                {getInfo('DESKS AVAILABLE', availableDesks, { container: {backgroundColor: '#000', color: '#fff', paddingTop: '1em'}, title: {color: '#fff'}})}
            </div>
            {/*{ getLegends(showLegends) }*/}
            {showLegends.showMeetingRooms && <h3 className="floor-map-sidenav-heading">My meeting room bookings</h3>}
            {showLegends.showMeetingRooms && events.length > 0 && getMeetingRoomBookings(events) }
            <h3 className="floor-map-sidenav-heading">Desk bookings at this floor</h3>
              <div style={{overflowY: 'auto', maxHeight: '400px'}}>
            {nodes.length > 0 && <>
                {nodes.map(({ node }) => {
                    if (!node.dailyBookableSpacesAvailability[0]?.availability && !node.isMeetingRoom) {
                        return <div className="floor-map-sidenav-box" key={'sidenav-booking-item-' + node.code}>
                            <div className="floor-map-sidenav-box-img">
                                <img src={node.dailyBookableSpacesAvailability[0]?.bookings[0].employee.image} />
                            </div>
                            <div className="floor-map-sidenav-box-content">
                                <p>{node.dailyBookableSpacesAvailability[0]?.bookings[0].employee.name}</p>
                                <Button style={{ paddingLeft: 0 }} type="link" onClick={() => setSelectedDesk(node.code)}>{node.code}</Button>
                            </div>
                        </div>;

                    } else {
                        return null;
                    }
                })}

            </>}
              </div>
           
            
        </div>
    </>;
};

export default withOktaAuth(InteractiveMapSideNav);
