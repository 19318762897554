import React, {useState, useEffect} from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {Table, Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import axios from 'axios';
import env from '../env';
import { getGoogleRefreshToken } from '../helpers';


const MeetingRoomEvents = ({authState, socket}) => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    
	const getMeetingRoomEvents = async () => {
		const googleRefreshToken = await getGoogleRefreshToken(authState.accessToken.value);
		const userCalenderId = authState.idToken.claims.email.split('@')[0] + env.DOMAIN_SUFFIX;
		if(!googleRefreshToken || !userCalenderId) return;
		let config = { 
			method: 'post', 
			url: `${env.BACKEND_URL}/get-google-events`, 
			headers: { 'Authorization': authState.accessToken.value },
			data: {
				refreshToken: googleRefreshToken,
				calendarId: userCalenderId,
				startDate: DateTime.now().toISO(),
			}
		};
		let response = await axios(config);
		if(response.data.ok) {
			setEvents(response.data.data);
		}

	};

    const deleteEvent = async (item) => {
        setLoading(true);
        const eventId = item.id;
        const googleRefreshToken = await getGoogleRefreshToken(authState.accessToken.value);
		const userCalenderId = authState.idToken.claims.email.split('@')[0] + env.DOMAIN_SUFFIX;
		if(!googleRefreshToken || !userCalenderId) return;
		let config = { 
			method: 'post', 
			url: `${env.BACKEND_URL}/delete-google-event`, 
			headers: { 'Authorization': authState.accessToken.value },
			data: {
				refreshToken: googleRefreshToken,
				calendarId: userCalenderId,
                eventId: eventId
			}
		};
		await axios(config);
        await getMeetingRoomEvents();
		socket.emit('message', 'canceled');
		setLoading(false);
    };

    useEffect(async () => {
        setLoading(true);
        await getMeetingRoomEvents();
        setLoading(false);
        
    }, []);

	useEffect(() => {
		if(!socket) return;
		socket.on('message',(message)=> {
			if(message === 'refresh') {
				getMeetingRoomEvents();
			}
		});
	}, [socket]);

    const columns = [
		{
			title: 'Title',
			dataIndex: 'summary',
			key: 'summary',
			width: '15%',
		},
		{
			title: 'Date & Time',
			//dataIndex: 'scheduled_date',
			key: 'date',
			width: '10%',
            render: item => {
                let startDate = DateTime.fromISO(item.start.dateTime);
                let endDate =  DateTime.fromISO(item.end.dateTime);

                return [<div key={item.id + startDate.toLocaleString(DateTime.DATE_FULL)}>
                    <div>{startDate.toLocaleString(DateTime.DATE_FULL)}</div>
                    <div>{startDate.toLocaleString(DateTime.TIME_SIMPLE)} - {endDate.toLocaleString(DateTime.TIME_SIMPLE)}</div>
                </div>];
            }
		},
		{
			title: 'Room',
			dataIndex: 'location',
			key: 'location',
			width: '20%',
          
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '10%',
		},
		{
			title: 'Actions',
			width: '5%',
            key: 'item',
            dateIndex: 'item',
			render: item => {
				return (
					[
                        <React.Fragment key={item.id}>
                            <Button
                            
                            style={{padding: 0, height: 'auto', color: '#1890ff'}}
                            type='text'
                            onClick={() => {
                                const eventId = item.htmlLink.split('eid=')[1]; 
                                const editLink = `https://calendar.google.com/calendar/u/0/r/eventedit/${eventId}?sf=true`;
                                window.open(editLink);
                                // history.push({
                                //     pathname: '/hq-event',
                                //     state: {eventData: item}
                                // });
                            }}
							icon={<EditOutlined />}
                        />
                        <span> | </span>
                        <Popconfirm
								title={<><span>Are you sure you want to cancel this Event?</span></>}
								onConfirm={() => {
									// setCurrentItem(null);
									deleteEvent(item);
								}}
								onCancel={() => {}}
								okText="Yes"
								cancelText="No"
							>
								<Button danger type='text' onClick={() => {
									// setCurrentItem(item);
									// setShowConfirmDeleteModal(true);
								}} icon={<DeleteOutlined />} />
							</Popconfirm>
                        </React.Fragment>
					
					]
				);
			}
		},
	];

    return <div className="hq-events-table-container">
        <div style={{width: '100%', overflowX: 'auto' }}>
            <Table
            columns={columns}
            dataSource={events}
            pagination={false}
			className="ant-table-flexspace"
            bordered
            loading={loading}
        />
        </div>
    </div>;

};

export default withOktaAuth(MeetingRoomEvents);