import React from 'react';
import NavClick from './navClick';
import './slider.css';
class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            images: this.props.images.length > 0 ? this.props.images :
                [ 'https://www.american-stock-research.com/uploads/1/2/7/1/127111504/palo-hq_orig.jpg' ]
        };
    }

    goToHistoryClick = (curIndex, index) => {
        this.setState({
            current: index,
        });
    }
    render() {
        let index = this.state.current,
            src = this.state.images[index];
        return (
            <div className="app">
                <div className="carousel">
                    <div className="carousel_slide" key={index}>
                        <img src={src} />
                    </div>
                    <div className="carousel_history">
                        <NavClick
                            current={this.state.current}
                            items={this.state.images}
                            changeSilde={this.goToHistoryClick}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider;
