import React, {useState, useEffect, createContext} from "react";
import {withOktaAuth} from "@okta/okta-react";
import {withRouter} from "react-router-dom";
import {DateTime} from "luxon";
import axios from "axios";
import Header from "../shared/Header";
import EventNavigationBar from "../components/EventList/EventNavigationbar";
import SheldonComponent from "../components/SheldonComponent";
import FindAnEventHeader from "../components/FindAnEvent/FindAnEventHeader";
import FindAnEventCheckBoxList from "../components/FindAnEvent/FindAnEventCheckBoxList";
import FindAnEventCalendar from "../components/FindAnEvent/FindAnEventCalendar";
import { notification } from "antd";
import env from "../env.json";
import {getGoogleRefreshToken} from '../helpers';
import useGAEventsTracker from '../hooks/useGAEventsTracker';
const gAEventTracker = useGAEventsTracker('Events I can join event');

const FindEventContext = createContext({});

const FindAnEvent = ({history, authState}) => {
    /* TODO: Populate from backend to limit upkeep? */
    const locationMap = {
        'Amsterdam': 'AMS',
        'Bangalore': 'BLR',
        'Hong Kong': 'HKG',
        'London': 'LON',
        'Melbourne': 'MEL',
        'New York City': 'NYC',
        'Plano': 'PLA',
        'Reston': 'RES',
        'Santa Clara': 'SC',
        'Shanghai': 'SHG',
        'Singapore': 'SIN',
        'Sydney': 'SIN',
        'Tel Aviv': 'TLV',
        'Tokyo': 'TYO',
    };
    const regionalCalendars = {
        'Shanghai': 'SHG',
        'Singapore': 'SIN',
        'Sydney': 'SIN',
        'Tel Aviv': 'TLV',
        'Tokyo': 'TYO',
        'Bangalore': 'BLR',
        'Hong Kong': 'HKG',
        'London': 'LON',
        'Melbourne': 'MEL',
    }
    const sites = Object.keys(locationMap);

    const [dateRanges, setDateRanges] = useState([]);
    const [calendarFilters, setCalendarFilters] = useState([
        {
            id: 1,
            name: 'Company Events',
            checked: true
        },
        {
            id: 2,
            name: 'Fun, Food & Fitness',
            checked: true
        },
        {
            id: 3,
            name: 'I&D / Employee Networks',
            checked: true
        },
        {
            id: 4,
            name: 'In-Person Learning',
            checked: true
        }
    ]);
    const [selectedSite, setSelectedSite] = useState('Santa Clara');
    const [selectedDateRange, setSelectedDateRange] = useState({});
    const [events, setEvents] = useState([]);
    const [eventMap, setEventMap] = useState({});
    const [isEmployee, setIsEmployee] = useState(JSON.parse(localStorage.getItem("employee")));
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = ({type, message, description}) => {
        api[type]({
            message,
            description
        });
    };
    const calendarColorMap = {
        'Company Events': {
            'events': '#EFF8FF',
            'filter': '#006FCC'
        },
        'Fun, Food & Fitness': {
            'events': '#FFF4E2',
            'filter': '#EF9700'
        },
        'I&D / Employee Networks': {
            'events': '#F9C8F0',
            'filter': '#903280'
        },
        'In-Person Learning': {
            'events': '#FFBEBE',
            'filter': '#D13C3C'
        }
    };

    useEffect(async () => {
        /* Supports Google Callback - Add to Calendar feature */
        sessionStorage.setItem('callbackPage', '/find-an-event');
        await getGoogleRefreshToken(authState.accessToken.accessToken);

        /* No need to wait for promise to fulfill */
        getUser();

        const {
            ok,
            data
        } = (await axios({
            url: `${env.BACKEND_URL}/find/events`,
            method: 'GET',
            headers: {
                Authorization: authState.accessToken.accessToken
            }
        })).data;

        if (ok) {
            const sortedEvents = sortHelper({ events: data });

            setEvents(sortedEvents);
            filterHelper({ events: sortedEvents });
        }
    }, []);

    useEffect(() => {
        filterHelper({ events });
    }, [calendarFilters, selectedSite]);

    useEffect(() => {
        updateDateRange();
    }, [selectedSite]);

    const updateDateRange = () => {
        /* Handles initial population and selection of date range */
        const dateRangesArr = getDateRanges();

        setDateRanges(dateRangesArr);
        setSelectedDateRange(dateRangesArr[0]);
    };

    const getUser = async () => {
        try {
            const {
                data
            } = await axios({
                method: "get",
                url: `${env.BACKEND_URL}/get-user`,
                headers: { Authorization: authState.accessToken.value },
            });

            if(data) {
                const {
                    employee
                } = data;

                /*
                Covers scenarios where get-user fails and does not have an employee key;
                currently it always returns a 200 response, so that would not be a valid check
                */
                if(typeof data.employee === 'boolean') {
                    /* Update state and storage value */
                    setIsEmployee(employee);
                    localStorage.setItem("employee", employee);
                }
            }
        } catch {}
    };

    const handleBack = () => {
        history.push("/");
    };

    const getCalendarFilters = () => {
        try {
            const activeFilterArr = [];

            Object.values(calendarFilters).forEach(v => {
                if (v.checked) {
                    activeFilterArr.push(v.name);
                }
            });

            return activeFilterArr;
        } catch {}
    }

    const getDateRanges = () => {
        try {
            const now = DateTime.now();
            let start;
            let end;

            return [...Array(4).keys()].map((w, index) => {
                /* Intentionally does not shift date for first iteration */
                const rangeWeek = now.plus({week: w});

                start = rangeWeek.startOf('week');
                /* 5-Day Work Week */
                end = rangeWeek.endOf('week').minus({days: 2});

                if(selectedSite === 'Tel Aviv') {
                    start = start.minus({days: 1});
                    end = end.minus({days: 1});
                }

                return {
                    id: index + 1,
                    text: start.toFormat('LLLL d') + ' - ' + end.toFormat('LLLL d'),
                    start,
                    end,
                    all: getDatesInRange({ start, end })
                }
            });
        } catch {}
    };

    /* TODO: Better approach? */
    /* Two days are accounted for - determine the 3 in-between and produce new array */
    const getDatesInRange = ({ start, end }) => {
        try {
            const middleRange = [...Array(3).keys()].map(d => start.plus({days: d + 1}));

            return [start, ...middleRange, end];
        } catch {}
    };

    const getEventMap = ({ events }) => {
        try {
            const eventMap = {};

            events.forEach(e => {
                e.start = DateTime.fromISO(e.start);
                e.end = DateTime.fromISO(e.end);

                if(!eventMap[e.start.month]) {
                    eventMap[e.start.month] = {};
                }

                if(!eventMap[e.start.month][e.start.day]) {
                    eventMap[e.start.month][e.start.day] = [];
                }

                eventMap[e.start.month][e.start.day].push(e);
            });

            return eventMap;
        } catch {}
    };

    const filterHelper = ({ events }) => {
        setEventMap(
            getEventMap({
                events: events.filter(e => {
                    return getCalendarFilters().includes(e.calendar) && e.office === locationMap[selectedSite] || e.office === regionalCalendars[selectedSite]
                })
            })
        );
    };

    const sortHelper = ({ events }) => {
        return events.sort((a, b) => {
            return DateTime.fromISO(a.start).toMillis() - DateTime.fromISO(b.start).toMillis();
        });
    };

    const addEventHandler = async ({ eventTitle, eventId, calendarId }) => {
        try {
            const refreshToken = localStorage.getItem('googleRefreshToken');

            const {
                data: {
                    ok
                }
            } = await axios({
                method: 'POST',
                url: `${env.BACKEND_URL}/find/events/add`,
                data: {
                    eventId,
                    calendarId,
                    refreshToken
                }
            });

            if(ok) {
                openNotificationWithIcon({
                    type: 'success',
                    message: eventTitle,
                    description: `Hurray! We have successfully added this event to your calendar.`
                });
                gAEventTracker('Calender event click', `${eventTitle} is added by ${authState?.idToken?.claims?.name}`);
            }

        } catch {
            openNotificationWithIcon({
                type: 'error',
                message: eventTitle,
                description: 'Oops! We were unable to add this event to your calendar, please try again.'
            });
            gAEventTracker('Calender event click', `${eventTitle} failed to add for ${authState?.idToken?.claims?.name}`);
        }
    };

    const RenderFindAnEvent = () => {
        return (
            <div>
                <section className="custom-container" style={{maxWidth: "100rem"}}>
                    <div className="flexevt-card-btn-wrap">
                        <div className="flexevt-card-inner-wrap">
                            <div
                                style={{
                                    width: "100%",
                                    minHeight: "200px",
                                    backgroundColor: "white",
                                }}
                            >
                                <FindAnEventHeader
                                    sites={sites}
                                    selectedSite={selectedSite}
                                    setSelectedSite={setSelectedSite}
                                    dateRanges={dateRanges}
                                    selectedDateRange={selectedDateRange}
                                    setSelectedDateRange={setSelectedDateRange}

                                />
                                {!regionalCalendars[selectedSite] && <FindAnEventCheckBoxList
                                    eventsTypeList={calendarFilters}
                                    setEventsTypeList={setCalendarFilters}
                                />}
                                <FindAnEventCalendar dates={selectedDateRange.all}/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    return (
        <FindEventContext.Provider value={{ colorMap: calendarColorMap, events: eventMap, now: DateTime.now(), addEventHandler }}>
            {contextHolder}
            <Header hideMyBadge={true}/>
            <EventNavigationBar
                navigationTitle="Events I Can Join"
                path="/"
                onBack={() => handleBack()}
            />
            {
                isEmployee ?
                    <RenderFindAnEvent />
                    : isEmployee === false
                        ? <div
                            style={{
                                marginTop: '10rem',
                                fontSize: '1rem',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center'
                            }}
                        >
                            <p>
                                Thank you for your interest in&nbsp;<strong>Events I can Join</strong>!<br/>
                                Unfortunately, events are not offered to contractors at this time.
                            </p>
                        </div>
                        : ''
            }
            <SheldonComponent/>
        </FindEventContext.Provider>
    );
};

export default withRouter(withOktaAuth(FindAnEvent));
export { FindEventContext };