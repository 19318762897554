import React, { useState, useEffect, useRef } from "react";
import { withOktaAuth } from "@okta/okta-react";
import { Button, Modal, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

import env from "../../env";
import useDebounce from "../../hooks/useDebounce";

const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PeerSearchModal = ({
  authState,
  visible,
  onToggle,
  selectedUser,
  setSelectedUser,
  alertMessageObject,
  loading,
  onSubmit,
}) => {
  const [results, setResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const ref = useRef();

  useEffect(() => {
    const searchSlackUsers = async () => {
      try {
        const resp = await axios({
          method: "get",
          url: `${env.BACKEND_URL}/search-slack-users?search=${debouncedSearchTerm}`,
          headers: { Authorization: authState.accessToken.accessToken },
        });
        if (resp.data.ok) {
          setSearchLoading(true);
          setResults(resp.data.data);
          setSearchLoading(false);
        } else {
          setResults([]);
        }
      } catch (e) {
        setResults([]);
      }
    };

    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      searchSlackUsers();
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm]);

  const onUserSelected = (slackId) => {
    const index = results.findIndex((item) => item.slackId === slackId);
    if (index !== -1) {
      setSelectedUser(results[index]);
    }
  };

  const clearOnBackspace = (event) => {
    if (event.code === "Backspace" && event.target.value === "") {
      setSearchTerm("");
      setResults([]);
    }
  };

  const getLoader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "16em",
        }}
      >
        <Spin indicator={loaderIcon} />
      </div>
    );
  };

  const getModalContent = () => {
    return (
      <>
        <div className="booking-modal-container">
          <div className="booking-modal-header-wrapper">
            <div className="booking-modal-header">
              <div>
                <span>Search Users</span>
              </div>
            </div>
            <div className="booking-modal-content">
              <div
                style={{
                  flex: 1,
                  padding: ".5rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!alertMessageObject.value ? (
                  <>
                    <Select
                      ref={ref}
                      onClear={() => {
                        setSearchTerm("");
                        setSelectedUser(null);
                      }}
                      onKeyDown={clearOnBackspace}
                      allowClear={true}
                      autoClearSearchValue={true}
                      onChange={(value) => onUserSelected(value)}
                      style={{ borderRadius: "8px", width: "100%" }}
                      placeholder="Search Users"
                      className="booking-modal-select"
                      showSearch
                      filterOption={false}
                      loading={searchLoading}
                      defaultActiveFirstOption={false}
                      onSearch={(value) => setSearchTerm(value)}
                    >
                      {results.map((employee) => (
                        <Option key={employee.slackId}>
                          <div style={{ paddingBottom: ".5rem" }}>
                            <p style={{ marginBottom: 0, fontSize: "14px" }}>
                              {employee.fullName}
                            </p>
                          </div>
                        </Option>
                      ))}
                    </Select>
                    {/* <span style={{marginTop: '1rem', fontSize: '10px'}}>*Please note connection will  be visible only after receiver approves your request.</span> */}
                  </>
                ) : (
                  <div>{alertMessageObject.body}</div>
                )}
              </div>
            </div>
            <div className="booking-modal-footer">
              {alertMessageObject.value ? (
                <Button type="primary" onClick={() => onToggle()}>
                  Close
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={!selectedUser}
                  onClick={() => onSubmit()}
                >
                  Add
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      title={null}
      centered
      width="620px"
      visible={visible}
      wrapClassName="ant-modal-flexspace"
      onOk={() => onToggle()}
      onCancel={() => onToggle()}
      footer={null}
    >
      {loading ? getLoader() : getModalContent()}
    </Modal>
  );
};

export default withOktaAuth(PeerSearchModal);
