import React, {useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Select, Modal, Spin, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withOktaAuth } from '@okta/okta-react';

import env from '../../env';
import useDebounce from '../../hooks/useDebounce';

const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;

const SearchEmployee = ({authState, onSelected}) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [bookingsData, setBookingsData] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [desksLoading, setDesksLoading] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const ref = useRef();


    useEffect(() => {
        if(searchTerm === '') {
            onSelected(null);
        }
    }, [searchTerm]);

    useEffect(() => {

        const searchEmployee = async () => {
            try {
                const resp = await axios({
                    method: 'get',
                    url: `${env.BACKEND_URL}/search-employee?search=${debouncedSearchTerm}`,
                    headers: { 'Authorization': authState.accessToken.accessToken },
                });
                if(resp.data.ok) {
                    setSearchLoading(true);
                    setResults(resp.data.data);
                    setSearchLoading(false);
                    
                } else {
                    setResults([]);
                }
            } catch(e) {
                setResults([]);
            }
        };


        if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
          searchEmployee();
        } else {
          setResults([]);
        }
      },[debouncedSearchTerm]);

      useEffect(() => {
        if(!showModal) return;

        const getBookings = async (email) => {
            setDesksLoading(true);
            let config = { method: 'get', url: `${env.BACKEND_URL}/desk-bookings?email=${email}`, headers: { 'Authorization': authState.accessToken.value } };
            let response = await axios(config);
            const data = response.data;
            if (data) {
                const updatedBookings = data.bookings.map(item => {
                    return {
                        key: item.id,
                        id: item.id,
                        date: moment(item.date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY'),
                        address: item?.space?.floor?.building?.address,
                        floor: item?.space?.floor?.name,
                        space: item?.space?.code,
                        item: {...item},
                        startDate: item?.startDate,
                    };
                });
                setBookingsData(updatedBookings);
            }
            setDesksLoading(false);
    
            return;
        };
        getBookings(selectedEmployee.email);
      }, [showModal]);

      const onEmployeeSelected = (employeeId) => {
        const index = results.findIndex(item => item.id === employeeId);
        if(index !== -1) {
            if(results[index].seat && results[index].hotelingDesks.edges.length > 0) {
                setSelectedEmployee(results[index]);
                setShowModal(true);
            } else if(results[index].hotelingDesks.edges.length > 0) {
                setSelectedEmployee(results[index]);
                setShowModal(true);
            } else if(results[index].seat) {
                onSelected(results[index], results[index].seat.external_id);
            }
        }
      };

      const clearOnBackspace = (event) => {
        if(event.code === 'Backspace' && event.target.value === '') {
            setSearchTerm('');
            setResults([]);
        }
      };

      const toggleModal = () => {
        setSelectedEmployee(null);
        setShowModal(false);
        setDesksLoading(false);
        setBookingsData([]);
      };

    const getLoader = () => {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '16em' }}><Spin indicator={loaderIcon} /></div>;
    };
    
    const getModalContent = () => {
        return <>
            <div className="booking-modal-container">
            <div className="booking-modal-header-wrapper">
                <div className="booking-modal-header">
                    <div><span>Permanent Desk</span></div>
                </div>
                <div className="booking-modal-content">
                    <div style={{flex: 1, padding: '.5rem', display: 'flex', justifyContent: "center"}}>
                       {selectedEmployee.seat 
                       ? <div style={{display: 'flex', alignItems: 'center'}}>
                            <span>{selectedEmployee.seat.external_id}</span>
                            <div style={{marginLeft: '2rem'}}>
                            <Button type='text' style={{color: '#0071e3'}} onClick={() =>  { 
                                    onSelected(selectedEmployee, selectedEmployee.seat.external_id);
                                    toggleModal();
                                }} >Locate</Button>
                            </div>
                        </div>
                       : <h3>Employee doesn't have an assigned space.</h3>}
                    </div>
                </div>
            </div>
        </div>
        <div className="booking-modal-container">
            <div className="booking-modal-header-wrapper">
                <div className="booking-modal-header">
                    <div><span>Hotel Desks</span></div>
                </div>
                <div className="booking-modal-content">
                    <div style={{flex: 1, padding: '.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {bookingsData.map(booking => {
                            return <div key={booking.id} style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '10rem'}}>{moment(booking.item.date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</div>
                                <div style={{width: '10rem'}}>{booking.item.space.externalId}</div>
                                <div style={{marginLeft: '2rem'}}>
                                <Button type='text' style={{color: '#0071e3'}} onClick={() =>  { 
                                     const externalId = booking.item.space.externalId;
                                     const date = moment(booking.item.date, 'YYYY-MM-DD');
                                     onSelected(selectedEmployee, externalId, date);
                                     toggleModal();
                                    }} >Locate</Button>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
        </>;
    };


    const getSelectedEmployeeDesksModal = () => {
    return <Modal
            title={null}
            centered
            width="620px"
            visible={showModal}
            wrapClassName="ant-modal-flexspace"
            onOk={() => toggleModal()}
            onCancel={() => toggleModal()}
            footer={null}
        >
            {desksLoading ? getLoader() : getModalContent()}
           
        </Modal>;
    };

    return (
        <>
            <Select 
                ref={ref}
                // value={results.length === 0 ? null :  } 
                onClear={() => setSearchTerm('')}
                onKeyDown={clearOnBackspace}
                allowClear={true}
                autoClearSearchValue={true}
                // onClick={() => setSearchTerm('')}
                onChange={(value) => onEmployeeSelected(value) } 
                style={{borderRadius: '8px', width: '100%'}} 
                placeholder="Search Employee" 
                className='booking-modal-select'
                showSearch
                filterOption={false}
                loading={searchLoading}
                defaultActiveFirstOption={false}
                onSearch={(value) => setSearchTerm(value)}>
                    {results.map((employee) => <Option key={employee.id}>
                    <div style={{paddingBottom: '.5rem'}}>
                        <p style={{marginBottom: 0, fontSize: '14px',}}>{employee.name}</p>  
                        <p style={{marginBottom: 0, fontSize: '12px',  fontWeight: 'bold'}}><a href="#">{employee.seat && employee.seat.external_id}</a></p>
                        {employee.hotelingDesks && employee.hotelingDesks.edges.length > 0 && <p style={{marginBottom: 0, fontSize: '12px',  fontWeight: 'bold'}}>Hotel Desk - <a href="#">{employee.hotelingDesks.edges[0].node.external_id}</a></p>}
                        <p style={{marginBottom: 0,  fontSize: '10px'}}>{employee.department && employee.department.name}</p>
                    </div>
                    </Option>)}
            </Select>
            {showModal && getSelectedEmployeeDesksModal()}
        </>  
    
    );
};


export default withOktaAuth(SearchEmployee);