import React, {useState } from 'react';
import LabelHeader from './../components/LabelHeader';
import HomeEventCard from './HomeEventCard';
import CloseIcon from './../assets/images/bookEvent/close_white.svg';
import ProgressBar from './ProgressBar';

const GuestAttendSpace = (props) => {
    const [isHome, setHome] = useState(false);
    const [isPanEmployee, setPanEmployee] = useState(0);
    const [isExternalGuest, setExternalGuest] = useState(0);
    const [isExecutive, setPanExecutive] = useState(0);
    const [isVip, setVip] = useState(0);
    const navigateHome = () => {
        setHome(!isHome);
        props.onhandleClose();
    };

    return (
        <React.Fragment>
            {isHome === false ? <div className='flex-event-bg'> <div className="close-icon" onClick={navigateHome}><img src={CloseIcon} alt="CloseIcon" /></div>
                <div className="flexevt-card-btn-wrap form-main-wrap">
                    <LabelHeader labelNum='3' labelPath='' labelContent='How many guests will be attending? *' />
                    <div className="flexevt-card-inner-wrap">
                        <div className="form-input-label-wrap">
                            <div className="input-label-wrap">
                                <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} onChange={(e) => setPanEmployee(e.currentTarget.value)} onKeyDown={(evt)=>['e','E','+','-'].includes(evt.key) && evt.preventDefault()} onKeyUp={(e) => { if (e.currentTarget.value < 0) setPanEmployee(e.currentTarget.value * -1); }} type="number" min="0" value={isPanEmployee} /><label className="label-text">PANW Employees & Contractors</label>
                            </div>
                            <div className="input-label-wrap">
                                <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} onChange={(e) => setExternalGuest(e.currentTarget.value)} onKeyDown={(evt)=>['e','E','+','-'].includes(evt.key) && evt.preventDefault()} onKeyUp={(e) => { if (e.currentTarget.value < 0) setExternalGuest(e.currentTarget.value * -1); }} type="number" min="0" value={isExternalGuest} /><label className="label-text">External guests</label>
                            </div>
                            <div className="input-label-wrap">
                                <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} onChange={(e) => setPanExecutive(e.currentTarget.value)} onKeyDown={(evt)=>['e','E','+','-'].includes(evt.key) && evt.preventDefault()} onKeyUp={(e) => { if (e.currentTarget.value < 0) setPanExecutive(e.currentTarget.value * -1); }} type="number" min="0" value={isExecutive} /><label className="label-text">PANW Executives</label>
                            </div>
                            <div className="input-label-wrap">
                                <input onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}} onChange={(e) => setVip(e.currentTarget.value)} onKeyDown={(evt)=>['e','E','+','-'].includes(evt.key) && evt.preventDefault()} onKeyUp={(e) => { if (e.currentTarget.value < 0) setVip(e.currentTarget.value * -1); }} type="number" min="0" value={isVip} /><label className="label-text">VIPs</label>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrap"><button className={`next-btn ${isPanEmployee > 0 || isExternalGuest > 0 || isExecutive > 0 || isVip > 0 ? '' : 'disable-btn'}`} disabled={isPanEmployee > 0 || isExternalGuest > 0 || isExecutive > 0 || isVip > 0 ? false : true} onClick={()=>props.navigateConfiguration(isPanEmployee,isExternalGuest,isExecutive,isVip)}>Continue to next</button>    </div>
                    <div className="bar-position">
                        <ProgressBar filledPercentage={props.location==='community'?'66%':'50%'} />
                    </div>
                </div>
            </div> : <HomeEventCard />}
        </React.Fragment>
    );
};

export default GuestAttendSpace;
