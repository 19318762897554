import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { DateTime } from 'luxon';
import { Modal, Tooltip } from 'antd';
import { withOktaAuth } from '@okta/okta-react';
import RoomReservation from '../HQEvent/RoomReservation';
import env from '../../env';

const neighborhoods = [
    { x : 2900, y: 680, height: 620, width: 520, large: true , key: 'neighborhood-1', value: 'Work Studio 1', nbhType: 'workstudio'},
    { x : 2840, y: 740, height: 510, width: 145, large: false, key: 'sub-neighborhood-1a', value: 'Work Studio 1.A',  parent: 'Work Studio 1', nbhType: 'workstudio'},
    { x : 2595, y: 740, height: 510, width: 145, large: false, key: 'sub-neighborhood-1b', value: 'Work Studio 1.B',  parent: 'Work Studio 1', nbhType: 'workstudio'},
    { x : 1860, y: 680, height: 610, width: 710, large: true, key: 'neighborhood-2',  value: 'Work Studio 2', nbhType: 'workstudio'},
    { x : 1760, y: 740, height: 370, width: 145, large: false, key: 'sub-neighborhood-2a', value: 'Work Studio 2.A', parent: 'Work Studio 2', nbhType: 'workstudio'},
    { x : 1350, y: 740, height: 370, width: 145, large: false, key: 'sub-neighborhood-2b', value: 'Work Studio 2.B', parent: 'Work Studio 2', nbhType: 'workstudio'},
    { x : 790, y: 680, height: 610, width: 750, large: true, key: 'neighborhood-3', value : 'Work Studio 3', nbhType: 'workstudio'},
    { x : 720, y: 695, height: 245, width: 600, large: false, key: 'sub-neighborhood-1', value: 'Work Studio 3.A', parent: 'Work Studio 3', nbhType: 'workstudio'},
    { x : 720, y: 995, height: 250, width: 610, large: false, key: 'sub-neighborhood-2', value: 'Work Studio 3.B', parent: 'Work Studio 3', nbhType: 'workstudio'},
    { x : 760, y: 1710, height: 620, width: 710, large: true, key: 'neighborhood-4', value: 'The Trellis', nbhType: 'event'},
    { x : 275, y: 1790, height: 260, width: 230, large: false, key: 'sub-neighborhood-4a', value: 'The Trellis A',  parent: 'The Trellis', nbhType: 'event'},
    { x : 740, y: 1780, height: 280, width: 180, large: false, key: 'sub-neighborhood-4b', value: 'The Trellis B',  parent: 'The Trellis', nbhType: 'event'},
    { x : 740, y: 2090, height: 240, width: 700, large: false, key: 'sub-neighborhood-4c', value: 'The Trellis C',  parent: 'The Trellis', nbhType: 'event'},
    // { x : 275, y: 2090, height: 240, width: 230, large: false, key: 'sub-neighborhood-4d', value: 'The Trellis.D',  parent: 'The Trellis'},
    { x : 1800, y: 1710, height: 620, width: 720, large: true, key: 'neighborhood-5', value: 'Morning Room', nbhType: 'event'},
    { type: 'polygon',  points: [5,1710, 770,1710,770,2330,5,2150], large: true, key: 'neighborhood-6', value: 'Club Room', nbhType: 'event'},
    { type: 'polygon',  points: [1130,1710, 1730,1710,1730,2440,1130,2390], large: true, key: 'neighborhood-7', value: 'Sitting Room', nbhType: 'event'}
];

const NeighborhoodToolTip = (props) => {
        if(!props.canBook) {
            return <Tooltip  trigger="hover"  placement="top" title={<span>This neighborhood is not available for booking. Reservations must be between 48 hrs - 2 weeks in advance.</span>}>
                {props.children}
            </Tooltip>;
        }

        return props.children;

};

const Neighborhood = ({currentFloorMap, mapWidthRatio, dateCurrent, dateCurrentEnd, showEvents, showNeighborhood, authState}) => {
    const [showNeighborhoodModal, setShowNeighborhoodModal] = useState(false);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState('');
    // const [canBook, setCanBook] = useState(false);
    const [availableNeighborhoods ,setNeighborhoods] = useState([]);

    useEffect(async () => {
        const formattedDate = dateCurrent.toISO({format: 'basic', suppressMilliseconds: true}).split('.')[0];
        
        if(!formattedDate) return;
        let config = {
            method: 'get',
            url: `${env.BACKEND_URL}/get-available-neighborhoods?date=${formattedDate}`,
            headers: { 'Authorization': authState.accessToken.value },
        };
       
        try {
            let response = await axios(config);
            const data = response.data;
            if (!(data.data)) {
                throw data;
            }
            const ngh = data.data.map(item => item.name);

            setNeighborhoods(ngh);

        } catch (err) {
            console.log(err);
        }
    }, [dateCurrent]);

    // useEffect(() => {
    //     if(availableNeighborhoods.length === 0) return;

    //     const availableStartDate = DateTime.now().plus({days: 2}).set({ hour: 0, minute: 0});
    //     const currentDate = dateCurrent.set({hour: 0, minute: 0});
    //     const diffInHours = Math.round(availableStartDate.diff(currentDate, ['hours']).toObject().hours);

    //     availableNeighborhoods

    //     if(diffInHours <= 0) {
    //         setCanBook(true);
    //     } else {
    //         setCanBook(false);
    //     }


    // }, [availableNeighborhoods]);

    const isAvailable = (neighborhood) => {
        if(availableNeighborhoods.length === 0) return false;

        const availableStartDate = DateTime.now().plus({days: 2}).set({ hour: 0, minute: 0});
        const currentDate = dateCurrent.set({hour: 0, minute: 0});
        const diffInHours = Math.round(availableStartDate.diff(currentDate, ['hours']).toObject().hours);

        let isParentBooked = false;

        if(!neighborhood.large) {
            const parentIndex = availableNeighborhoods.findIndex(item => item === neighborhood.parent );
            if(parentIndex === -1) {
                isParentBooked = true;
            }
        }
        
        const index = availableNeighborhoods.findIndex(item => item === neighborhood.value );

        if(diffInHours <= 0 && index !== -1 && !isParentBooked) {
            return true;
        } else {
            return false;
        }
    };

    const toggleNeighborhoodModal = (show, value) => {
        if(show) {
            setSelectedNeighborhood(value);
            setShowNeighborhoodModal(true);

        } else {
            setShowNeighborhoodModal(false);
            setSelectedNeighborhood('');
        }
    };

    const getNeighborhoodModal = () => {
        const data={
            briefing_detail: {
                preferred_location_hq : 'Neighborhood',
                preferred_neighborhood_location_combinat: selectedNeighborhood,
            },
            preferred_date: dateCurrent.toSQLDate(),
            preferred_end_date: dateCurrentEnd.toSQLDate()
        };

        return <Modal
        title="Book a Neighborhood"
        centered
        visible={showNeighborhoodModal}
        onOk={() => toggleNeighborhoodModal(false)}
        onCancel={() => toggleNeighborhoodModal(false)}
        footer={null}
        width={1000}
        bodyStyle={{maxHeight: 500, overflow: 'auto'}}

    >
        <div style={{marginBottom: '8px'}}>
            <RoomReservation data={data} hideHeader={true} fromMap={true} />
        </div>   
    </Modal>;
    };

    const getClasses = (neighborhood, canBook) => {

        const classes = neighborhood.large ?  ['neighborhood-large'] : ['neighborhood-small'];
        if(!canBook) {
            classes.push('neighborhood-blocked');
        }

        return classes.join(' ');
 
    };

    const getPolygonPoints = (points) => {
        let point = '';

        points.forEach((item, index ) => {
            if(index %2 === 0) {
                point += `${item/mapWidthRatio},`;
            } else {
                point += `${item/mapWidthRatio} `;
            }
        });

        return point;
    };

    const getFilteredNeighborHoods = () => {
        return neighborhoods.filter(item => {
            if(showNeighborhood && item.nbhType === 'workstudio') {
                return true;
            }

            if(showEvents && item.nbhType === 'event') {
                return true;
            }

            return false;
        });
    };


       
    return <>
        

        {getFilteredNeighborHoods().map(neighborhood => {
            
            const canBook = isAvailable(neighborhood);

            return <a key={neighborhood.key} href="#" onClick={() => canBook ? toggleNeighborhoodModal(true, neighborhood.value): {}}>
                <NeighborhoodToolTip canBook={canBook}>
                    {neighborhood.type === 'polygon'
                    ? <polygon points={getPolygonPoints(neighborhood.points)} className={getClasses(neighborhood, canBook)} />
                    : <rect 
                        x={(currentFloorMap.mapW / mapWidthRatio) - (neighborhood.x/mapWidthRatio)} 
                        y={neighborhood.y/mapWidthRatio} 
                        width={neighborhood.width / mapWidthRatio} 
                        height={ neighborhood.height / mapWidthRatio} 
                        className={getClasses(neighborhood, canBook)}
                        />
                    }
                
                </NeighborhoodToolTip>
            </a>;
        }  )}

        {showNeighborhoodModal && getNeighborhoodModal() }
        
    </>;

};

export default withOktaAuth(Neighborhood);
