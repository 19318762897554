import React, { useState, useEffect } from "react";
import axios from "axios";
import { withOktaAuth } from "@okta/okta-react";
import { withRouter } from "react-router-dom";
import BookEvent from "./../assets/images/bookEvent/book_event.svg";
import BookConfrence from "./../assets/images/bookEvent/book_conference_room.svg";
import OrderFood from "./../assets/images/order_food.png";
import BookDesk from "./../assets/images/bookEvent/book_desk.svg";
import Table from "./../assets/images/bookEvent/table_icon.svg";
import { generateGreetings } from "../helpers";
import env from "../env.json";
import ComingSoonModal from "./../components/Dialog/ComingSoonModal";
import panw_icon from "../assets/images/panw_icon.svg";
import Concierge from "../assets/images/concierge.svg";
import CalenderIcon from "../assets/images/icons/calendar-icon.svg";

const HomeEventCard = (props) => {
  const [image, setImage] = useState(panw_icon);
  const [fullName, setFullName] = useState("Loading...");
  const [isModal, setIsModal] = useState(false);
  const [isEmployee, setIsEmployee] = useState(JSON.parse(localStorage.getItem("employee")));

  useEffect(async () => {
    const hasLenelId = localStorage.getItem('lenelId');
    setImage(localStorage.getItem("image"));
    setFullName(localStorage.getItem("fullName"));
    const { authState } = props;
    let urlSufix = 'get-user?image=true';
    if(!hasLenelId || hasLenelId === 'undefined') {
      urlSufix = 'get-user?image=true&lenelId=true'
    }
    const resp = await axios({
      method: "get",
      url: `${env.BACKEND_URL}/${urlSufix}`,
      headers: { Authorization: authState.accessToken.value },
    });
    const { image, fullName, manager, vp, execAdmin, lenelId, employee } = resp.data;
    setIsEmployee(employee);
    setFullName(fullName);
    setImage(image);
    localStorage.setItem("image", image);
    localStorage.setItem("fullName", fullName);
    localStorage.setItem("manager", manager);
    localStorage.setItem("vp", vp);
    localStorage.setItem("execAdmin", execAdmin);
    localStorage.setItem("employee", employee);
    if(lenelId) {
      localStorage.setItem('lenelId', lenelId);
    }
  }, []);
  const onClose = () => {
    setIsModal(false);
  };
  // const openModal = () => {
  //     setIsModal(true);
  // };

  return (
    <React.Fragment>
      <div className="flexevt-card-btn-wrap">
        <div style={{display: "flex", width: "100%"}}>
          <div
            className="profile-card step-modal-card"
            style={{ width: "100%"}}
          >
            <div className="profile-card-img-container">
              <img src={image} alt="User Profile" />
            </div>
            <div className="profile-card-text-container">
              <p>{generateGreetings()}</p>
              <p
                className={
                  fullName && fullName.length && fullName.length >= 25
                    ? "name long-username"
                    : "name"
                }
              >
                {fullName}
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() => props.onhandleClose("findMyFriends")}
          className="flexevt-card-btn step-modal-card browse-location-card"
          style={!isEmployee ? {width: '100%'} : {}}
        >
          <img src={Concierge} alt="Concierge" />
          <p>Find My Friends</p>
        </div>
        {isEmployee && <div
          onClick={() => props.onhandleClose("findAnEvent")}
          className="flexevt-card-btn step-modal-card book-desk-card"
        >
            <img
              src={CalenderIcon}
              alt="CalenderIcon"
            />
          <p>Events I Can Join</p>
        </div>}
        <div
          onClick={() => props.onhandleClose("bookadesk")}
          className="flexevt-card-btn step-modal-card step-modal-card-small book-desk-card"
        >
          <div style={{minHeight: "50%", display: "flex"}}>
            <img src={BookDesk} alt="BookDesk" style={{ maxWidth: "4.5rem", marginRight: "2em" }} />
            <img src={Table} style={{ maxWidth: "4.5rem" }} alt="Conference" />
          </div>
          <p>Book A Space</p>
        </div>
        <div
          onClick={() => props.onhandleClose("orderfood")}
          className="flexevt-card-btn step-modal-card step-modal-card-small order-food-card"
        >
          <img src={OrderFood} alt="OrderFood" />
          <p>Order Food</p>
        </div>
        <div
          onClick={() => props.onhandleClose("bookanevent")}
          className="flexevt-card-btn step-modal-card step-modal-card-small book-an-event-card"
        >
          <img src={BookEvent} alt="BookEvent" />
          <p>Book An Event</p>
        </div>
        <div
          onClick={() =>
            window.open(
              "https://theloop.paloaltonetworks.com/loop/departments/Places",
              "_blank"
            )
          }
          className="flexevt-card-btn step-modal-card step-modal-card-small browse-location-card"
        >
          <img src={BookConfrence} alt="BookConfrence" />
          <p>Browse Our Locations</p>
        </div>
      </div>
      <ComingSoonModal isOpen={isModal} title="Coming Soon" onClose={onClose} />
    </React.Fragment>
  );
};

export default withRouter(withOktaAuth(HomeEventCard));
