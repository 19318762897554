import React, {useEffect, useState} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import axios from 'axios';
import env from '../env';
import {withOktaAuth} from '@okta/okta-react';
import Home from './Home';

const GoogleCallback = (props) => {
    const [complete, setComplete] = useState(false);
    const history = useHistory();
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const callbackPage = sessionStorage.getItem('callbackPage');

    const handleRedirect = () => {
        history.replace('');
        history.push(callbackPage || '/book-a-space');
    };

    useEffect(async () => {
        try {
            const resp = await axios({
                method: 'post',
                url: `${env.BACKEND_URL}/google-token`,
                headers: { 'Authorization': props.authState.accessToken.accessToken },
                data: {code: code}
            });
            // const googleAccessToken = resp.data.data.access_token;
            const googleRefreshToken = resp.data.data.refresh_token;
            //if (!googleAccessToken) return props.history.push('/google-error');
            //console.log(googleAccessToken);
            // localStorage.setItem('googleAccessToken', googleAccessToken);
            localStorage.setItem('googleRefreshToken', googleRefreshToken);            
        } catch {}

        /* Set to complete regardless of success or failure */
        setComplete(true);
    }, []);

    /* Prevents redirect loop before googleRefreshToken is set */
    {complete && handleRedirect()}
    return null;
};

export default withRouter(withOktaAuth(GoogleCallback));