import React, { useEffect, useState } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import moment from 'moment';
import { Spin, Popover, Table, Alert, Tooltip } from 'antd';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFireExtinguisher, faUtensils, faShoePrints, faDumbbell, faSignOutAlt, faMale, faFemale, faMugHot, faMedkit, faPrint, faHeartbeat, faBabyCarriage } from '@fortawesome/free-solid-svg-icons';
import env from '../../env';
import { DateTime } from 'luxon';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import MapToolbar from './MapToolbar';
import './InteractiveMap.css';

import useGAEventsTracker from '../../hooks/useGAEventsTracker';
import Neighborhood from './Neighborhood';
import InteractiveMapSideNav from './InteractiveMapSidenav';
import MoveBookingModal from './MoveBookingModal';
// import MultipleDatePicker from '../../shared/MultipleDatePicker/MultipleDatePicker';
import { getGoogleRefreshToken, updatePeerHotelDesks } from '../../helpers';
import BookADeskModal from './BookADeskModal';
import BookAMeetingRoomModal from './BookAMeetingRoomModal';

const gAEventTracker = useGAEventsTracker('BookADesk page event');



const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const iconMap = new Map([
    ['Extinguisher', faFireExtinguisher],
    ['Kitchen Cafeteria', faUtensils],
    ['Stairs', faShoePrints],
    ['Elevator', faDumbbell],
    ['Restroom Women', faFemale],
    ['Restroom Men', faMale],
    ['Emergency Exit', faSignOutAlt],
    ['Coffee Machine', faMugHot],
    ['First Aid Kit', faMedkit],
    ['Printer', faPrint],
    ['Defibrillator', faHeartbeat],
    ['Tech Oasis', faUsers],
    ['Mothers Room', faBabyCarriage]
]);

const InteractiveMap = ({ 
    floorId, 
    dateCurrent, 
    dateCurrentEnd, 
    site, 
    floor, 
    siteData, 
    selectedDeskAssets, 
    authState, 
    showMeetingRooms,
    meetingRoomCapacity,
    socket,
    searchEmployee,
    filters,
    onFilterChange 
}) => {
    const [currentFloorMap, setCurrentFloorMap] = useState({});
    const [showBookModal, setShowBookModal] = useState(false);
    const [nodes, setNodes] = useState([]);
    const [currentNode, setCurrentNode] = useState({});
    const [messageObject, setMessageObject] = useState({value: false, body: ''});
    const [loading, setLoading] = useState(false);
    const [maxWidth, setMaxWidth] = useState(1200);
    const [mapWidthRatio, setMapWidthRatio] = useState(1);
    const [bookLoading, setBookLoading] = useState(false);
    const [currentZoom, setCurrentZoom] = useState(1);
    const [availableHeight, setAvailableHeight] = useState('100%');
    // const [floorCapacity, setFloorCapacity] = useState(0);
    const [selectedDesk, setSelectedDesk] = useState(null);
    const [refreshMap, setRefreshMap] = useState(false);
    const [bookingDates, setBookingDates] = useState([]);
    // const [desksAvailableDates, setDesksAvailableDates] = useState([]);
    const [showNeighborhood, setShowNeighborhood] = useState(false);
    const [canMove, setCanMove] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showMeetingRoomModal , setShowMeetingRoomModal] = useState(false);
    const [meetingRoomsAvalibilites, setMeetingRoomsAvalibility] = useState([]);
    const [meetingRoomEventTitle, setMeetingRoomEventTitle] = useState('');
    const [meetingRoomEventDescription, setMeetingRoomEventDescription] = useState('');
    const [silentReload, setSilentReload] = useState(false);
    const [bookableEmployeeEmail, setBookableEmployeeEmail] = useState('');
    const [alertMessageObject, setAlertMessageObject] = useState({value: false, body: ''});
    const [assignedDesk, setAssignedDesk] = useState(null);
    const [showPrivateOffice, setShowPrivateOffice] = useState(false);
    const [showEvents, setShowEvents] = useState(false);
    const [mapImage, setMapImage] = useState('');
    const [mapPins, setMapPins] = useState([]);
    const [viewOnlySpaces, setViewOnlySpaces] = useState([]);
    const [selectedMeetingRoom, setSelectedMeetingRoom] = useState(null);
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    
    // useEffect(async () => {
    //     try {
    //         // const gogleToken = sessionStorage.getItem('googleAccessToken');
    //         // if(!gogleToken) {
    //             const resp = await axios({
    //                 method: 'get',
    //                 url: `${env.BACKEND_URL}/google-auth`,
    //                 headers: { 'Authorization': authState.accessToken.accessToken },
    //             });
    //             if(resp.data) {
    //                 console.log(resp.data.data);
    //                 window.location.href = resp.data.data;
    //             }
    //         // }
    //     } catch(e) {
    //         console.log(e)
    //     }
    // }, []);

    useEffect(() => {
        const isFromEventPage = localStorage.getItem('fromEventPage');
        if(isFromEventPage && isFromEventPage === 'true') {
            setShowEvents(true);
            setShowNeighborhood(true);
            localStorage.removeItem('fromEventPage');
        }

    }, []);

    const getMeetingRooms = async (loadingAnimation = true) => {
        if(!(showMeetingRooms && nodes.length > 0)) return;
        setLoading(loadingAnimation);
        const googleNames = nodes.filter(nodeItem => nodeItem.node.isMeetingRoom && nodeItem.node.googleName).map(nodeItem => nodeItem.node.googleName);
        try {
            const resp = await axios({
                method: 'post',
                url: `${env.BACKEND_URL}/get-meeting-rooms`,
                headers: { 'Authorization': authState.accessToken.accessToken },
                data: {rooms: googleNames}
            });
    
            if(resp.data.ok) {
                setMeetingRoomsAvalibility(resp.data.data);
            }
        } catch(e) {
            console.log(e);
        }
        setLoading(false);
    };

    const getCapacity = (node) => {
        let capacity = 0;
        if(node.isMeetingRoom && node.googleName){
            const index = meetingRoomsAvalibilites.findIndex(item => item.resourceName === node.googleName);
            
            if(index !== -1) {
                capacity = meetingRoomsAvalibilites[index].capacity;
            }
        }
        if(capacity === 0 || !capacity) {
            capacity = node.capacity > 0 ? node.capacity : 0;
        }

        return capacity;
    };
    

    const filterMeetingRooms = (node) => {
        if(!meetingRoomCapacity || meetingRoomsAvalibilites.length === 0) return true;
        const capacity = getCapacity(node);
        if(meetingRoomCapacity === 10 && capacity >= meetingRoomCapacity) {
            return true;
        } else if(capacity >= meetingRoomCapacity) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(async () => {
        if(showMeetingRooms && nodes.length > 0) {
            await getMeetingRooms(!silentReload);
            setSilentReload(false);
        }
    }, [showMeetingRooms, nodes]);

   

    useEffect(() => {
        //let manager = localStorage.getItem('manager');
        // if(authState.idToken.claims.email === 'snagabhairav@paloaltonetworks.com') {
        //     manager = 'Y';
        // }

        const allowMoveNeighborhood = isSuperAdmin();

        if(allowMoveNeighborhood) {
            setCanMove(true);
        }  else {
            setCanMove(false);
        }                  

        // if(site 
        //     && floor 
        //     && site === 'HQ - Building 3' 
        //     && floor === '4th Floor [FLEXSpace]' 
        //     // && ( allowMoveNeighborhood || manager === 'Y')
        //     ) {
        //     setShowNeighborhood(true);
        // } else {
        //     setShowNeighborhood(false);
        // }
    }, [site, floor]);

    useEffect(() => {
        if(!socket) return;
        socket.on('message', (message) => {
            if(message === 'refresh') {
                setSilentReload(true);
                setRefreshMap(true);
            }
        });
    }, [socket, setRefreshMap]);

    const sendMessage = () => {
        if(socket && socket.connected) {
            socket.emit('message', JSON.stringify({body: 'booked', token: authState.accessToken.value}));
        }
    };

    useEffect(() => {
        if(dateCurrent) {
            setBookingDates([moment([dateCurrent.year, dateCurrent.month - 1, dateCurrent.day])]);
        }
    }, [dateCurrent]);

    const hasConfreneceRoom = () => {
        if(site && ( site.includes('HQ') || site === 'San Francisco' || site === 'Plano' || site === 'Reston')
            ) {
                return true;
        } else {
            return false;
        }
    };

    const hasNeighborhoodAndPrivateRooms = () => {
        if(site 
            && floor 
            && site === 'HQ - Building 3' 
            && floor === '4th Floor [FLEXSpace]' 
            ) {
                return true;
        } else {
            return false;
        }
    };

    // const getDesksAvailibility = async () => {
    //     const year = dateCurrent.year;
    //     const month = dateCurrent.month;
    //     const day = dateCurrent.day;

    //     const endDay = DateTime.local().endOf('month').day;

    //     let startDate = DateTime.fromObject({ year: year, month: month, day: day }, { zone: siteData.timezone });
    //     let endDate = DateTime.fromObject({ year: year, month: month, day: endDay }, { zone: siteData.timezone });

    //     let config = { method: 'get', url: `${env.BACKEND_URL}/floor-interactive-map?floorId=${floorId}&startDate=${startDate}&endDate=${endDate}`, headers: { 'Authorization': authState.accessToken.value } };
    //     let response = await axios(config);

    //     if (response?.data?.spaces?.spaceConnection?.edges) {
    //         setDesksAvailableDates(response?.data?.spaces?.spaceConnection?.edges);
    //     }
    // };

    const getFloorWithPins = async () => {
        const config = { method: 'get', url: `${env.BACKEND_URL}/floor-with-pins?floorId=${floorId}`, headers: { 'Authorization': authState.accessToken.value } };
        const response = await axios(config);
        if(response.data.data?.length) {
            const spaces = response.data.data;
            setMapPins(spaces);
        }
    }

    const getAssignedDesks = (spaces = []) => {
        setAlertMessageObject({ value: false, body: '' });
        setAssignedDesk(null);

        if(site !== 'Tel Aviv') return;

        const index = spaces.findIndex(space => space?.spaceAssignment?.employee?.email === authState.idToken.claims.email);
        // const index = spaces.findIndex(space => space?.spaceAssignment?.employee?.email === 'seshel@paloaltonetworks.com');
        if(index !== -1) {
            setAssignedDesk(spaces[index]);
            const body = <div>
                    Looks like you already have an assigned desk 
                    (Locate : <span onClick={() => {
                        const updatedAssignedDesk = {...spaces[index]};
                        updatedAssignedDesk.selected = true;
                        setAssignedDesk(updatedAssignedDesk);
                    }} style={{cursor: 'pointer', color: 'blue'}}>
                        {spaces[index].code}
                    </span>) 
                    on this floor. Please feel free to book a desk on another floor at this site
                </div>;

            setAlertMessageObject({ value: true, body: body });
        }
    };

    const getFloorWithSpaceAssignments = async () => {
        const config = { method: 'get', url: `${env.BACKEND_URL}/floor-with-assignments?floorId=${floorId}`, headers: { 'Authorization': authState.accessToken.value } };
        const response = await axios(config);
        if(response.data.data?.length) {
            const spaces = response.data.data;
            getAssignedDesks(spaces);

            const includedSpaces = ['Tech Oasis', 'Mothers Room'];
            const filteredSpaces = spaces
            .filter(space => includedSpaces.includes(space?.name))
            .map(space => ({...space, icon: iconMap.get(space.name)}));
           
            setViewOnlySpaces(filteredSpaces);
        }
    };

    const getSearchedEmployeeFloor = async (nodesList) => {
        
        let config = { method: 'get', url: `${env.BACKEND_URL}/floor-with-assignments?floorId=${floorId}`, headers: { 'Authorization': authState.accessToken.value } };
        let response = await axios(config);
        if(response.data.ok && response.data.data.length > 0) {
            const spaces = response.data.data;

            
            const index = spaces.findIndex(space => space?.spaceAssignment?.externalId === searchEmployee.seat.external_id);
            
            if(index === -1) return;
            if(!spaces[index].spaceAssignment.employee) return;
            const email = spaces[index].spaceAssignment.employee.email;
            config = { method: 'get', url: `${env.BACKEND_URL}/get-slack-user?email=${email}`, headers: { 'Authorization': authState.accessToken.value } };
            response = await axios(config);
            spaces[index].spaceAssignment.employee.image = response.data.data.image;
            spaces[index].spaceAssignment.employee.phone = response.data.data.phone;
            const updatedSpace = {node: {...spaces[index], dailyBookableSpacesAvailability: [ { availability: true, searched: true }] }};
            const updatedNodes =  [...nodesList];
            updatedNodes.push(updatedSpace);
            setNodes(updatedNodes);

        }
    };


    const getFloorMap = async (loadingAnimation = true) => {
        setLoading(loadingAnimation);
        const startYear = dateCurrent.year;
        const startMonth = dateCurrent.month;
        const startDay = dateCurrent.day;

        const endYear = dateCurrentEnd.year;
        const endMonth = dateCurrentEnd.month;
        const endDay = dateCurrentEnd.day;

        let startDate = DateTime.fromObject({ year: startYear, month: startMonth, day: startDay }, { zone: siteData.timezone });
        let endDate = DateTime.fromObject({ year: endYear, month: endMonth, day: endDay }, { zone: siteData.timezone });

        let config = { method: 'get', url: `${env.BACKEND_URL}/floor-interactive-map?floorId=${floorId}&startDate=${startDate}&endDate=${endDate}`, headers: { 'Authorization': authState.accessToken.value } };
        let response = await axios(config);
        setCurrentFloorMap(response.data);
        setLoading(false);
        
       
        const nodesList = [];    
 
        if( response.data.spaces?.spaceConnection?.edges &&  response.data.spaces?.spaceConnection?.edges.length > 0) {
            response.data.spaces?.spaceConnection?.edges.forEach(item => {
                if(Object.keys(currentNode).length > 0 && currentNode.code == item.node.code) {
                    setCurrentNode(item.node);
                }

            
                if(isOfficePrivateRoom(item) ) {
                    if(isVP() || isExecAdmin() || isSuperAdmin()) {
                        nodesList.push(item);
                    }
                } else {
                    nodesList.push(item);
                }

            });
            
            // const capacity = Math.round(  (availDesks / response.data.spaces?.spaceConnection?.edges.length) * 100);
            // setFloorCapacity( 100 - capacity);
        }

        setNodes(nodesList);
        if(searchEmployee && searchEmployee.seat && searchEmployee.seat.external_id) {
            await getSearchedEmployeeFloor(nodesList);
        }

    };

    const isVP = () => {
         const vp = localStorage.getItem('vp');

        if(vp && vp === 'Y') {
            return true;
        }

        return false;
    };

    const isExecAdmin = () => {
        const execAdmin = localStorage.getItem('execAdmin');

        if(execAdmin && execAdmin === 'Y') {
            return true;
        }

        return false;
    };

    const isSuperAdmin = () => {
        const allowPrivateBooking =   (
            authState.idToken.claims.email === 'snagabhairavv@paloaltonetworks.com' 
            || authState.idToken.claims.email === 'caswani@paloaltonetworks.com' 
            || authState.idToken.claims.email === 'ntram@paloaltonetworks.com'
            || authState.idToken.claims.email === 'mhuiltron@paloaltonetworks.com'
            || authState.idToken.claims.email === 'sscrivens@paloaltonetworks.com'
            || authState.idToken.claims.email === 'nthanh@paloaltonetworks.com'
        );
        if(allowPrivateBooking) {
            return true;
        }
        return false;
    };

    const isOfficePrivateRoom = (node) => {
        if(node.node.spaceType && node.node.spaceType.name === 'office_private') {
            return true;
        }

        return false;
    };

    const canShowPrivateOffice = (node) => {
        if(node.spaceType && node.spaceType.name === 'office_private') {
            if(showPrivateOffice)  {
                return true; 
            } else {
                return false;
            }
        }

        return true;
    }; 

    const canShowPrivateOfficeToggle = () => {
        if(isVP() || isExecAdmin() || isSuperAdmin()) return  true;
        return false;
    };

 
    useEffect(() => {

        if(currentFloorMap?.spaces?.spaceConnection?.edges.length > 0 && showNeighborhood) {
            if(!currentFloorMap?.spaces?.spaceConnection?.edges[0].node.neighborhood) {
                const updatedCurrentFloorMap = {...currentFloorMap};

                const updatedNodes = currentFloorMap?.spaces?.spaceConnection?.edges.map(item => {
                    const arr = item?.node?.code?.split('.');
                    if(!arr || (arr && arr?.length === 0 )) return {...item};
                    const neighborhood = `${arr[0]}.${arr[1]}`;
                    const subNeighborhood = `${arr[0]}.${arr[1]}.${arr[2]}`;
                    item.node.neighborhood = neighborhood;
                    item.node.subNeighborhood = subNeighborhood;
                    return {...item};
                });
        
                updatedCurrentFloorMap.spaces.spaceConnection.edges = updatedNodes;
                
                setNodes(updatedNodes);
                setCurrentFloorMap(updatedCurrentFloorMap);
            }
        }
    }, [showNeighborhood, currentFloorMap]);

    useEffect(() => {
        if(!currentFloorMap.background) return;
        if(site 
            && floor 
            && site === 'HQ - Building 3' 
            && floor === '4th Floor [FLEXSpace]'
        ) {
            import('../../assets/images/3.4_FLEXSpace.png').then(image => {
                setMapImage(image.default);
              });
        } else {
            setMapImage(currentFloorMap.background.url);
        }
    }, [currentFloorMap]);

    useEffect(async () => {
        if(refreshMap) {
            setRefreshMap(false);
            await getFloorMap(false);
            await getMeetingRooms(false);

        }
    }, [refreshMap]);

    useEffect(() => {
        if(floorId && floorId !== '-') {
            getFloorWithPins();
            getFloorWithSpaceAssignments();
        }
    }, [floorId]);

    useEffect(async () => {
        if (floorId && authState && dateCurrent && dateCurrentEnd && siteData) {
            await getFloorMap();
            // getDesksAvailibility();
            // if(searchEmployee) {
            //     await getSearchedEmployeeFloor();
            // }

        }
    }, [floorId, dateCurrent, dateCurrentEnd, searchEmployee]);



    // useEffect(() => {
    //     if(Object.keys(currentFloorMap).length > 0) {
    //         const nodes = currentFloorMap?.spaces?.spaceConnection?.edges;
    //         nodes.forEach(node => {
    //             const assets = node?.node?.tags;


    //             console.log(assets, selectedAssets);
    //         });

    //     }
    // }, [selectedAssets, currentFloorMap]);

    const isAssetMatch = (node) => {
        const tags = node?.tags;

        if(selectedDeskAssets.length > 0) {
            if(tags.length > 0) {
                let hasAssets = false;

                for(let i = 0; i < tags.length; i++) {
                    const index  = selectedDeskAssets.findIndex(item => item.id == tags[i].id);
                    if(index !== -1) {
                        hasAssets = true;
                        break;
                    }
                }

                return hasAssets;


            } else {
                return false;
            }

        } else {
            return true;
        }

    };

    const checkMeetingRoomAvalibility = (node) => {
        if(meetingRoomsAvalibilites.length === 0 && !node.googleName) return false;
        
        const roomIdx = meetingRoomsAvalibilites.findIndex(room => room.resourceName === node.googleName);
        if(roomIdx === -1) return false;

        const freeBusy = meetingRoomsAvalibilites[roomIdx].bookings.busy;
        let isFree = true;
        for(let i = 0; i < freeBusy.length; i++) {
            const startTime = DateTime.fromISO(freeBusy[i].start);
            const endTime = DateTime.fromISO(freeBusy[i].end);

            if((dateCurrent > startTime && dateCurrent < endTime) || (dateCurrentEnd > startTime && dateCurrentEnd < endTime) || (startTime > dateCurrent && endTime < dateCurrentEnd)) {
                isFree = false;
                break;
            }
        }
        return isFree;
    };

    const toggleMoveModal = (value) => {
        setShowMoveModal(value);
    };


    useEffect(() => {
        if(Object.keys(currentFloorMap).length === 0) return;
        setMapDimensions();
        setPageContentHeight();



        window.addEventListener('resize', () => {
            if(window.innerWidth < 1500) {
                setMaxWidth(window.innerWidth - 300);
            } else {
                setMaxWidth(1200);
            }
            setMapDimensions();
            setPageContentHeight();
        });

        return () => {
            window.removeEventListener('resize', () => {});
        };
    }, [currentFloorMap]);

    const setPageContentHeight = () => {
        if(!(document.querySelector('header'))) {
            return;
        }
        // const totalHeight = window.innerHeight;
        // const headerHeight = document.querySelector('header').getBoundingClientRect().height;
        // const envNavHeight =  document.querySelector('.eventlist-navbar-wrap').getBoundingClientRect().height;
        // let finalHeight = totalHeight - ( headerHeight + envNavHeight);
        // finalHeight =  '100%';
        //setAvailableHeight(finalHeight.toFixed(2) + 'px');
        // setAvailableHeight(finalHeight);
        setAvailableHeight('100%');

    };

    const setMapDimensions = () => {
        // console.log(currentFloorMap);
        const { mapW } = currentFloorMap;
        // const mapHeight = currentFloorMap.mapH;
        let windowWidth = window.innerWidth;

        if(windowWidth <= 600) {
            windowWidth = window.innerWidth -200;
        } else if(windowWidth <= 1500) {
            windowWidth = window.innerWidth - 300;
        } else {
            windowWidth = 1200;
        }
        const widthRatio = (mapW/ windowWidth);
        if(!isNaN(widthRatio)) {

            setMapWidthRatio(widthRatio);
        }
    };

    const onCoordinateClick = (node, canBook) => {
        // const canBook = canBookInTelAviv(node);
        node.canBook = !!canBook;
        setCurrentNode(node);
        if(node.isMeetingRoom) {
            toggleMeetingRoomModal(true);
        } else {
            toggleBookModal(true);
        }
    };

    const toggleBookModal = (val) => {
        setShowBookModal(val);
        setMessageObject({value: false, body: ''});
        setBookableEmployeeEmail('');
    };

    const toggleMeetingRoomModal = (val) => {
        setShowMeetingRoomModal(val);
        setMeetingRoomEventTitle('');
        setMeetingRoomEventDescription('');
        setMessageObject({value: false, body: ''});
    };

    const isSameDate = (dateTime1, dateTime2, checkTime) => {
        if(checkTime) {
            return dateTime1.month === dateTime2.month && 
                dateTime1.day === dateTime2.day && 
                dateTime1.year === dateTime2.year &&
                dateTime1.year === dateTime2.year &&
                dateTime1.minute === dateTime2.minute;    
        }
        return dateTime1.month === dateTime2.month && dateTime1.day === dateTime2.day && dateTime1.year === dateTime2.year;
      };

   

    const bookNow = async (bookingDate = dateCurrent, multipleBooking = false, bookingsStatus, node = currentNode, ee) => {
        const employeeEmail = bookableEmployeeEmail !== '' ? bookableEmployeeEmail.trim() : ee;

        setBookLoading(true);

        const startYear = bookingDate.year;
        const startMonth = bookingDate.month;
        const startDay = bookingDate.day;

        const endYear = dateCurrentEnd.year;
        const endMonth = dateCurrentEnd.month;
        const endDay = dateCurrentEnd.day;

        const multidayBooking = !isSameDate(bookingDate, dateCurrentEnd);

        let startDate = DateTime.fromObject({year: startYear, month: startMonth, day: startDay, hour: 9}, {zone: siteData.timezone});
        let endDate = DateTime.fromObject({year: endYear, month: endMonth, day: endDay, hour: 18}, {zone: siteData.timezone});


        if (isSameDate(startDate, DateTime.now().setZone(siteData.timezone))) {
            startDate =  DateTime.now().setZone(siteData.timezone).plus({minutes: 1});

        }


        let config = { method: 'post', url: `${env.BACKEND_URL}/create-booking?spaceId=${node.id}&startDate=${encodeURIComponent(startDate.toString())}&endDate=${encodeURIComponent(endDate.toString())}&employeeEmail=${employeeEmail}&multiDay=${multidayBooking}`, headers: { 'Authorization': authState.accessToken.value } };
        let response = await axios(config);
        if (response.data.errors && response.data.errors.length > 0) {

            if (!multipleBooking) {
                gAEventTracker('Book desk failed click');
                const errors = response.data.errors[0].unknownErrors;
                if (errors) {
                    const errorsMessages = errors.map(error => error.message);
                    setMessageObject({ value: true, bookingSuccessful: false, body: <div style={{display: 'flex', justifyContent: 'center'}}>{errorsMessages.map(item => <p key={item}>{item}</p>)}</div> });
                } else {
                    setMessageObject({ value: true, bookingSuccessful: false, body: 'Unfortunately, this desk is booked by another user. Please refresh this page and try again.' });
                }
                await getFloorMap(false);
            } else {
                bookingsStatus.push({ date: bookingDate, status: false });

            }

            // setShowErrorModal(true);
        } else {
            gAEventTracker('Book desk success click');
            updatePeerHotelDesks(response, authState.accessToken.value);

            if (!multipleBooking) {
                setMessageObject({
                    value: true, bookingSuccessful: true, body:
                        <div className='hasSubmittedTicket'>
                            <CheckCircleOutlined />
                            <div>Thanks for booking a desk! We will be sending reminders & updates for your reservation via Slack. You can always modify or delete your reservation under &quot;My Reservations&quot; on Connected</div>
                        </div>
                });
                await getFloorMap(false);
                sendMessage();

            } else {
                bookingsStatus.push({ date: bookingDate, status: true });
            }

        }

        setBookLoading(false);
        // toggleBookModal(false);


    };

    

    const handleBooking = async () => {
        
        if (bookingDates.length > 1) {
            const bookingsStatus = [];
            for (let i = 0; i < bookingDates.length; i++) {
                const bookingDate = DateTime.fromJSDate(bookingDates[i].toDate());
                await bookNow(bookingDate, true, bookingsStatus);
            }

            const dataSource = bookingsStatus.map((item, index) => ({
                key: item.date.day + index,
                space: currentNode.code,
                date: `${item.date.month}/${item.date.day}/${item.date.year}`,
                status: item.status ? 'Sucess' : 'Failed'
            }));

            const columns = [
                {
                    title: 'Space',
                    dataIndex: 'space',
                    key: 'space',
                    width: '40%',
                },
                {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                    width: '40%',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    width: '20%',
                },
            ];


            setMessageObject({
                value: true, bookingSuccessful: true, body:
                    <div className='hasSubmittedTicket'>
                        <CheckCircleOutlined />
                        <div className="booking-status-table"><Table dataSource={dataSource} columns={columns} size="small" pagination={false} bordered /></div>
                        <div>Thanks for booking a desk! We will be sending reminders & updates for your reservation via Slack. You can always modify or delete your reservation under &quot;My Reservations&quot; on Connected</div>
                    </div>
            });
            //setBookingsStatus([]);
            await getFloorMap(false);
            sendMessage();

        } else {
            bookNow();
        }
    };

    const moveBooking = async (selectedNode) => {
       try {
          
           setBookLoading(true);
           const bookingId = currentNode?.dailyBookableSpacesAvailability[0]?.bookings[0]?.id;
           const employeeEmail =  currentNode?.dailyBookableSpacesAvailability[0]?.bookings[0]?.employee?.email;
           const config = { method: 'post', url: `${env.BACKEND_URL}/delete-booking?spaceId=${bookingId}`, headers: { 'Authorization': authState.accessToken.value } };
           await axios(config);
           setBookLoading(false);
           await bookNow(dateCurrent, false, null, selectedNode, employeeEmail);
           toggleMoveModal(false);
        //    await getFloorMap(false);
       } catch(err) {
           console.log(err);
       }
    };

    const handleCancelBooking = async () => {
        try {
            setBookLoading(true);
            const bookingId = currentNode?.dailyBookableSpacesAvailability[0]?.bookings[0]?.id;
            const config = { method: 'post', url: `${env.BACKEND_URL}/delete-booking?spaceId=${bookingId}`, headers: { 'Authorization': authState.accessToken.value } };
            await axios(config);
            setBookLoading(false);
            toggleBookModal(false);
            await getFloorMap(false);
        } catch(err) {
            console.log(err);
        }
    }

    // const getCurrentNodeAvailableDates = () => {
    //     if (desksAvailableDates.length === 0) return [];

    //     const index = desksAvailableDates.findIndex(item => item.node.id === currentNode.id);
    //     if (index !== -1) {
    //         return desksAvailableDates[index].node.dailyBookableSpacesAvailability.filter(item => item.availability).map(item => item.localStartDate);
    //     } else {
    //         return [];
    //     }
    // };

    const handleMeetingroomBooking = async () => {
        const roomIdx = meetingRoomsAvalibilites.findIndex(room => room.resourceName === currentNode.googleName);
        const calenderId = meetingRoomsAvalibilites[roomIdx].resourceEmail;
        setBookLoading(true);
        try {

            const token = await getGoogleRefreshToken(authState.accessToken.accessToken);
            if(!token) throw 'invalid token';
                // if valid create booking  event
            const userCalenderId = authState.idToken.claims.email.split('@')[0] + env.DOMAIN_SUFFIX;
            const dateDiffFromCurrent = dateCurrent.diffNow().values.milliseconds;
            const dateEndDiffFromCurrent = dateCurrentEnd.diffNow().values.milliseconds;

            const startDate = dateDiffFromCurrent > 60000 ? dateCurrent : DateTime.now().plus({minutes: 1});
            const endDate = isSameDate(dateCurrent, dateCurrentEnd, true) && dateEndDiffFromCurrent < 60000 ?  DateTime.now().plus({minutes: 2}) : dateCurrentEnd;
            let config = { 
                method: 'post', 
                url: `${env.BACKEND_URL}/book-meeting-room`, 
                headers: { 'Authorization': authState.accessToken.value }, 
                data: {
                    start: startDate.toISO(),
                    end: endDate.toISO(),
                    timezone: siteData.timezone,
                    calendarId: calenderId,
                    userCalendarId: userCalenderId,
                    refreshToken : token,
                    title: meetingRoomEventTitle,
                    description: meetingRoomEventDescription,
                }
            };
            let response = await axios(config);
            if(!response.data.ok) throw 'Booking meeting room failed';

            setBookLoading(false);
            setMessageObject({
                value: true, bookingSuccessful: true, body:
                    <div className='hasSubmittedTicket'>
                        <CheckCircleOutlined />
                        <div>Thanks for booking a Meeting room!</div>
                    </div>
            });
            getMeetingRooms(false);
            sendMessage();
        } catch(e) {
            setBookLoading(false);
            toggleMeetingRoomModal(false);
            console.log(e);
        }
       

    };

    const getLoading = () => {
        return <div className="loader">
            <Spin indicator={loaderIcon} />
        </div>;
    };


    const isRangeBooking = (node) => {
        return node.dailyBookableSpacesAvailability.length > 1;
    };

    const isDeskAvailable = (node) => {
        const isMultiple = isRangeBooking(node);
        let isAvailable = true;
        
        if(isMultiple) {
            for(let i =0; i< node.dailyBookableSpacesAvailability.length - 1; i++ ) {
                if(!node.dailyBookableSpacesAvailability[i]?.availability) {
                    isAvailable = false;
                    break;
                }
            }
        } else {
            isAvailable = node.dailyBookableSpacesAvailability[0]?.availability;
        }


        return isAvailable;
    };

    useEffect(() => {
        setIsPopoverVisible(true);
        setTimeout(() => {
            setIsPopoverVisible(false);
        }, 3000);
    }, [selectedMeetingRoom])

    const getMeetingRoomJSX = (node, selectedMeetingRoom) => {
        const isMeetingRoomSelected = node.code === selectedMeetingRoom;
        const content = (<div>
                <p>{node.name}</p>
                <hr />
                <p>{node.code}</p>
                {node.comment && node.comment !== '' && <p>{node.comment}</p>}
                <p>Capacity: {node.capacity}</p>
                {node.tags.length > 0 && <p>Assets: {node.tags.map(tag => tag.name).join(', ')}</p>}
            </div>);
        
        if(checkMeetingRoomAvalibility(node)) {
            return <Popover key={node.id} content={content} visible={isPopoverVisible ? isMeetingRoomSelected : undefined}>
                <a href="#" className="booking-node" key={node.id} onClick={() => onCoordinateClick(node, true)}>
                    <circle id={node.id} className="bullet available" cx={node.x / mapWidthRatio} cy={node.y / mapWidthRatio} r={30 / mapWidthRatio} style={{ opacity: isAssetMatch(node) ? 1 : 0.5 }} stroke={node.code === selectedDesk || isMeetingRoomSelected ? 'blue' : 'green'} strokeWidth={node.code === selectedDesk || isMeetingRoomSelected ? 3 : 3} fill={`url(#${node.id})`} />
                </a>
            </Popover>;
        } else {
            return <Popover key={node.id} content={content} visible={isPopoverVisible ? isMeetingRoomSelected : undefined}>
                <a href="#" className="booking-node" key={node.id} onClick={() => onCoordinateClick(node)}>
                    <circle id={node.id} className="bullet available" cx={node.x / mapWidthRatio} cy={node.y / mapWidthRatio} r={30 / mapWidthRatio} style={{ opacity: isAssetMatch(node) ? 1 : 0.5 }} stroke={node.code === selectedDesk || isMeetingRoomSelected ? 'blue' : 'red'} strokeWidth={3} fill={`url(#${node.id})`} />
                </a>
            </Popover>;
        }
    };


    return (
        
            <div className='current-floor-container'>
            {loading
                ? getLoading()
                : <>
                    
                    <InteractiveMapSideNav 
                        nodes={nodes} 
                        showLegends={{showNeighborhood, showMeetingRooms, showHotelDesks: true, showDepartmentRestrictedHotelDesks: site === 'Tel Aviv'}}
                        height={availableHeight}
                        setSelectedDesk={setSelectedDesk}
                        date={dateCurrent}
                        meetingRoomsAvalibilites={meetingRoomsAvalibilites}
                        filters={filters}
                        hasNeighborhood={hasNeighborhoodAndPrivateRooms()}
                        hasPriavteRooms={hasNeighborhoodAndPrivateRooms()}
                        hasConfreneceRooms={hasConfreneceRoom()}
                        setShowNeighborhood={setShowNeighborhood}
                        showNeighborhood={showNeighborhood}
                        canShowPrivateOffice={canShowPrivateOfficeToggle()}
                        showPrivateOffice={showPrivateOffice}
                        setShowPrivateOffice={setShowPrivateOffice}
                        showEvents={showEvents}
                        setShowEvents={setShowEvents}
                        onFilterChange={onFilterChange}
                        meetingRoom={{selectedMeetingRoom, setSelectedMeetingRoom}}
                        />
                        <div style={{position: 'relative', overflow: 'auto', height: availableHeight, width: '100%', flex: '1 1'}}>
                            {alertMessageObject.value && 
                            <div style={{display: 'flex', justifyContent: 'center', width: '100%', zIndex: '100'}}>
                                <div className="top-alert-container" style={{width: '600px', height: '60px', marginTop: 10, marginBottom: 10}}>
                                    <Alert message={alertMessageObject.body} type="warning" style={{
                                            backgroundColor: '#fff2f0',
                                            border: '1px solid #ffccc7'
                                    }} onClose={() => {
                                        setAlertMessageObject({ value: false, body: '' });
                                    }} showIcon /> 
                                </div>
                            </div>
                            }
                        <div 
                            className="map-container" 
                            onClick={() => setSelectedDesk(null)} 
                            style={{ 
                                justifyContent: window.innerWidth > 1200 ? 'center' : 'flex-start', 
                                width: `${maxWidth}px`, 
                                height: availableHeight,
                                overflow: 'none'
                                }}>

                            <TransformWrapper
                                initialScale={ window.innerWidth > 1200 && window.innerWidth < 1500 ? 0.8 : 1}
                                initialPositionX={window.innerWidth > 1200 && window.innerWidth < 1500 ? 80 : 0}
                                initialPositionY={0}
                                limitToBounds={false}
                                minScale={1}
                                maxScale={20}
                                zoomIn={{ step: 1 }}
                                zoomOut={{ step: 1 }}
                                onZoom={(e) => {
                                    setCurrentZoom(e.state.scale);
                                }}
                                onZoomStop={(e) => {
                                    setCurrentZoom(e.state.scale);
                                }}


                            >
                                {(obj) => {
                                    const { zoomIn, zoomOut, resetTransform, }  = obj;

                                    return  (
                                        <>
                                            <MapToolbar zoomIn={zoomIn} zoomOut={zoomOut} resetTransform={resetTransform} currentZoom={currentZoom} setCurrentZoom={setCurrentZoom}   />
                                            <TransformComponent>
                                                <>
                                                    {/* <div style={{ width: `${currentFloorMap.mapW / mapWidthRatio}px`, height: `${currentFloorMap.mapH / mapWidthRatio}px` }}> */}
                                                    <div className='floor-map-img-mobile' 
                                                        style={{ 
                                                            width: `${currentFloorMap.mapW / mapWidthRatio}px`, 
                                                            height: `${currentFloorMap.mapH / mapWidthRatio}px` 
                                                            }}>
                                                        <img id="floorMap" alt=""
                                                            src={mapImage}
                                                        />
                                                    </div>

                                                    {nodes.length > 0 
                                                    && <svg id="floorMask" 
                                                            viewBox={`0 0 ${currentFloorMap.mapW / mapWidthRatio} ${currentFloorMap.mapH / mapWidthRatio}`} 
                                                            style={{ 
                                                                width: `${currentFloorMap.mapW / mapWidthRatio}px`, 
                                                                // height: `${currentFloorMap.mapH / mapWidthRatio}px`, 
                                                                position: 'absolute' }}>
                                                        <defs>
                                                            {nodes.map(({ node }, index) => {
                                                                if (!isRangeBooking(node) && !isDeskAvailable(node) && !node.isMeetingRoom) {
                                                                    return <pattern key={node.dailyBookableSpacesAvailability[0]?.bookings[0].employee.id + '' + index} id={node.dailyBookableSpacesAvailability[0]?.bookings[0].employee.id + '' + index} y={node.y / mapWidthRatio + 10} x={node.x / mapWidthRatio + 10} patternUnits="userSpaceOnUse" height={60 / mapWidthRatio} width={60 / mapWidthRatio}>
                                                                        <image height={60 / mapWidthRatio} width={60 / mapWidthRatio} href={node.dailyBookableSpacesAvailability[0]?.bookings[0].employee.image}></image>
                                                                    </pattern>;
                                                                } else if(node.isMeetingRoom) {
                                                                   return  <pattern id={node.id} y={node.y / mapWidthRatio + 10} x={node.x / mapWidthRatio + 9} patternUnits="userSpaceOnUse" height={60 / mapWidthRatio} width={60 / mapWidthRatio}>
                                                                        <FontAwesomeIcon height={60 / mapWidthRatio} width={60 / mapWidthRatio} size={60 / mapWidthRatio}  icon={faUsers} />
                                                                    </pattern>;
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </defs>
                                                        
                                                        { (showNeighborhood || showEvents ) &&  <Neighborhood currentFloorMap={currentFloorMap} dateCurrent={dateCurrent} dateCurrentEnd={dateCurrentEnd} mapWidthRatio={mapWidthRatio} showEvents={showEvents} showNeighborhood={showNeighborhood} />}
                                                        
                                                        {currentFloorMap?.blockInstances.map((item) => {
                                                            return <image key={item.id} href={item?.block?.url} height={item?.map?.h / mapWidthRatio} width={item?.map?.w / mapWidthRatio} x={(item?.map?.x - (item?.map?.w/2))   / mapWidthRatio } y={(item?.map?.y - (item?.map?.h/2)) / mapWidthRatio } />;
                                                        })}
                                                        {mapPins?.map(({id, map, globalMapObject}) => {
                                                            const icon = iconMap.get(globalMapObject?.name);
                                                            
                                                            return !icon ? null : (
                                                                <foreignObject
                                                                    key={id} 
                                                                    width={map?.w / mapWidthRatio + 4} 
                                                                    style={{ height: map?.w / mapWidthRatio + 4 + 'px'}}
                                                                    x={(map?.x - (map?.w/2))   / mapWidthRatio } 
                                                                    y={(map?.y - (map?.h/2)) / mapWidthRatio }>
                                                                         <Tooltip placement="top" title={globalMapObject?.name}>
                                                                            <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', padding: '2px', backgroundColor: globalMapObject?.color ? globalMapObject?.color : '#000'}}>

                                                                                <FontAwesomeIcon icon={icon} color="#fff" />
                                                                            </div>
                                                                         </Tooltip>
                                                                </foreignObject>
                                                            );
                                                        })}
                                                        {viewOnlySpaces.map(space => (
                                                            <foreignObject
                                                                key={space.id} 
                                                                width={space?.w / mapWidthRatio + 4} 
                                                                style={{ height: space?.w / mapWidthRatio + 4 + 'px'}}
                                                                x={(space?.x - (space?.w/2))   / mapWidthRatio } 
                                                                y={(space?.y - (space?.w/2)) / mapWidthRatio }>
                                                                    <Tooltip placement="top" title={<div><p style={{marginBottom: 0}}>{space?.code}</p><p style={{marginBottom: 0}}>{space?.name}</p></div>}>
                                                                        <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', padding: '2px', backgroundColor:'green', borderRadius: '50%'}}>

                                                                            <FontAwesomeIcon icon={space.icon} color="#fff" />
                                                                        </div>
                                                                    </Tooltip>
                                                            </foreignObject>
                                                        ))}
                                                        {nodes.map(({ node }, index) => {
                                                            
                                                            if (!isDeskAvailable(node) && !node.isMeetingRoom) {
                                                                if(!canShowPrivateOffice(node)) return null;
                                                                if(isRangeBooking(node)) {
                                                                    return <a href="#" className="booking-node" key={node.id} onClick={() => onCoordinateClick(node)}>
                                                                    <circle id={node.id} className="bullet available" cx={node.x / mapWidthRatio} cy={node.y / mapWidthRatio} r={24 / mapWidthRatio} style={{ opacity: isAssetMatch(node) ? 1 : 0.5 }} fill="red" />
                                                                </a>;      
                                                                } else {
                                                                    const content = <div>
                                                                        <p>{node.code}</p>
                                                                        <p>{node.dailyBookableSpacesAvailability[0].bookings[0].employee.name}</p>
                                                                    </div>;
                                                                    return <Popover key={node.id} content={content} >
                                                                        <a href="#" key={node.id} onClick={() => onCoordinateClick(node)}>
                                                                            <circle id={node.id} className="bullet available" strokeWidth={node.code === selectedDesk ? 3 : 0} stroke="purple" cx={node.x / mapWidthRatio} cy={node.y / mapWidthRatio} r={ 24 / mapWidthRatio} style={{ opacity: isAssetMatch(node) ? 1 : 0.5 }} fill={`url(#${node.dailyBookableSpacesAvailability[0]?.bookings[0].employee.id + '' + index})`} />
                                                                        </a>
                                                                    </Popover>;
                                                                }
                                                            
                                                            }  else if(node.isMeetingRoom ) {
                                                                if(!showMeetingRooms || !filterMeetingRooms(node)) return null;
                                                                return getMeetingRoomJSX(node, selectedMeetingRoom);
                                                            
                                                            } else {
                                                                if(searchEmployee && node.spaceAssignment && node.spaceAssignment.employee) {
                                                                    const employee = node.spaceAssignment.employee;
                                                                    const content = <div>
                                                                            <div>
                                                                                <img src={employee.image} alt={employee.name} style={{height: '60px', width: 'auto', borderRadius: '50%', marginBottom: '10px'}} />
                                                                            </div>
                                                                            <p><strong>Name</strong>: {employee.name}</p>
                                                                            <p><strong>Seating location</strong>: {node.code}</p>
                                                                            <p><strong>Position</strong>: {employee.title}</p>
                                                                            <p><strong>Department</strong>: {employee.dept.name}</p>
                                                                            {employee.phone !== '' && <p><strong>Phone</strong>: {employee.phone}</p> }
                                                                            
                                                                        </div>;
                                                                    const defId = node.spaceAssignment.employee.id;
                                                                    return (
                                                                        <>
                                                                            <defs>
                                                                                <pattern 
                                                                                    key={defId} 
                                                                                    id={defId} 
                                                                                    y={node.y / mapWidthRatio + 10} 
                                                                                    x={node.x / mapWidthRatio + 10} 
                                                                                    patternUnits="userSpaceOnUse" 
                                                                                    height={60 / mapWidthRatio} 
                                                                                    width={60 / mapWidthRatio}>
                                                                                    <image 
                                                                                        height={60 / mapWidthRatio} 
                                                                                        width={60 / mapWidthRatio} 
                                                                                        href={node.spaceAssignment.employee.image}></image>
                                                                                </pattern>
                                                                            </defs>
                                                                            <Popover key={node.id} content={content} defaultVisible={true} >
                                                                                <a href="#" className="booking-node" key={defId} onClick={() => {}}>
                                                                                    <circle 
                                                                                        id={defId} 
                                                                                        className="bullet available" 
                                                                                        cx={node.x / mapWidthRatio} 
                                                                                        cy={node.y / mapWidthRatio} 
                                                                                        r={24 / mapWidthRatio}  
                                                                                        fill={`url(#${defId})`} />
                                                                                </a> 
                                                                            </Popover>
                                                                        </>
                                                                    );
                                                                   
                                                                }
                                                                if(!canShowPrivateOffice(node)) return null;
                                                                if(assignedDesk) {
                                                                    return <a href="#" className="booking-node" key={node.id} onClick={() => {}}>
                                                                    <circle id={node.id} className="bullet available" cx={node.x / mapWidthRatio} cy={node.y / mapWidthRatio} r={24 / mapWidthRatio} style={{ opacity: isAssetMatch(node) ? 1 : 0.5 }} fill="red" />
                                                                </a>; 
                                                                }
                                                                return <Popover key={node.id} content={<div><p>{node.code}</p></div>} >
                                                                    <a href="#" className="booking-node" key={node.id} onClick={() => onCoordinateClick(node)}>
                                                                        <circle id={node.id} className="bullet available" cx={node.x / mapWidthRatio} cy={node.y / mapWidthRatio} r={24 / mapWidthRatio} style={{ opacity: isAssetMatch(node) ? 1 : 0.5 }} fill={'green'} />
                                                                    </a>
                                                                </Popover>;
                                                            }

                                                        })}
                                                        {assignedDesk && assignedDesk.selected &&  <a href="#" className="booking-node" key={assignedDesk.id} onClick={() => {}}>
                                                                <circle 
                                                                    id={assignedDesk.id} 
                                                                    className="bullet available" 
                                                                    cx={assignedDesk.x / mapWidthRatio} 
                                                                    cy={assignedDesk.y / mapWidthRatio} 
                                                                    r={24 / mapWidthRatio}  
                                                                    fill="blue"
                                                                    // strokeWidth={node.code === selectedDesk ? 3 : 0} 
                                                                    // stroke="green"
                                                                    />
                                                            </a>}
                                                    </svg>
                                                    }
                                                </>
                                            </TransformComponent>
                                        </>

                                    );
                                }}

                            </TransformWrapper>


                        </div>
                    </div>
                    {showMoveModal && <MoveBookingModal 
                        show={showMoveModal} 
                        onClose={toggleMoveModal} 
                        onSubmit={moveBooking} 
                        isNeighborhood={showNeighborhood} 
                        loading={bookLoading}
                        nodes={nodes}
                        currentNode={currentNode} /> }

                    {showMeetingRoomModal && Object.keys(currentNode ).length > 0 && <BookAMeetingRoomModal
                        show={showMeetingRoomModal}
                        onToggle={toggleMeetingRoomModal}
                        onSubmit={handleMeetingroomBooking}
                        messageObject={messageObject}
                        currentNode={currentNode}
                        loading={bookLoading}
                        capacity={getCapacity(currentNode)}
                        site={site}
                        dateCurrent={dateCurrent}
                        dateCurrentEnd={dateCurrentEnd}
                        meetingRoomEventTitle={meetingRoomEventTitle}
                        setMeetingRoomEventTitle={setMeetingRoomEventTitle}
                        meetingRoomEventDescription={meetingRoomEventDescription}
                        setMeetingRoomEventDescription={setMeetingRoomEventDescription}


                    />}
                    

                    { Object.keys(currentNode ).length > 0 &&  <BookADeskModal 
                        show={showBookModal}
                        onToggle={toggleBookModal}
                        onSubmit={handleBooking}
                        onMoveBooking={setShowMoveModal}
                        onCancelBooking={handleCancelBooking}
                        messageObject={messageObject}
                        currentNode={currentNode}
                        loading={bookLoading}
                        isDeskAvailable={isDeskAvailable}
                        canMove={canMove}
                        site={site}
                        floor={floor}
                        employeeEmail={bookableEmployeeEmail}
                        onEmployeeEmailChange={setBookableEmployeeEmail}
                    />}



                </>}
        </div>

    );
};

export default withOktaAuth(InteractiveMap);
