import React, {useState, useEffect} from 'react';
import {Select, DatePicker, Switch, TimePicker } from 'antd';
import moment from 'moment';
import { DateTime } from 'luxon';
const { RangePicker } = DatePicker;
const { Option } = Select;


const InteractiveMapFilter = ({
    filters,
    onFilterChange,
    hasNeighborhood,
    setShowNeighborhood,
    showNeighborhood,
    canShowPrivateOffice,
    showPrivateOffice,
    setShowPrivateOffice,
    hasPriavteRooms,
    hasConfreneceRooms,
    showEvents,
    setShowEvents,
    nodes,
    meetingRoom,
}) => {

    // const children = filters.deskAssets.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>);
    const [startConferenceDate, setStartConferenceDate] = useState(DateTime.now());
    const [endConferenceDate, setEndConferenceDate] = useState(DateTime.now());
    const [meetingRoomSearch, setMeetingRoomSearch] = useState('');

    useEffect(() => {
        if(filters.showMeetingRooms) {
            setStartConferenceDate(filters.dates.dateCurrent);
            setEndConferenceDate(filters.dates.dateCurrentEnd);
        }
    }, [filters.dates.dateCurrent, filters.dates.dateCurrentEnd, filters.showMeetingRooms]);

    const meetingRoomChildren = [
        { value: null, name: 'All'},
        { value: 1, name: '1'},
        { value: 2, name: '2'},
        { value: 3, name: '3'},
        { value: 4, name: '4'},
        { value: 5, name: '5'},
        { value: 6, name: '6'},
        { value: 7, name: '7'},
        { value: 8, name: '8'},
        { value: 9, name: '9'},
        { value: 10, name: '10+'},
    ].map(item => <Option key={item.value} value={item.value}>{item.name}</Option>);

    const mapData = (sites) => {
		sites.sort((a, b) => a.name < b.name ? -1 : 1);
		const res = [];
		sites.forEach(s => {
			res.push(<Select.Option value={s.id} key={s.id}>{s.name}</Select.Option>);
		});
		return res;
	};

    const isSFBuilding = () => {
        const {sitesCurrent, sitesData} = filters?.sites;
        if(!sitesCurrent || !sitesData) return false;

        const currentSiteIndex = sitesData.findIndex(site => sitesCurrent === site.id && site.name === 'San Francisco');

        if(currentSiteIndex !== -1) {
            return true;
        }

        return false;
    };

    const getMaxFutureDays = ()  => {
        const isManager = localStorage.getItem('manager');
        let maxFutureDays = 14;
        if(isManager === 'Y' && isSFBuilding()) {
            maxFutureDays = 365;
        }
        if(filters.showMeetingRooms) {
            maxFutureDays = 365;
            return maxFutureDays;
        }
        if(canShowPrivateOffice && hasPriavteRooms && showPrivateOffice) {
            maxFutureDays = 180;
            return maxFutureDays;
        }
        if(showEvents || showNeighborhood) {
            maxFutureDays = 89;
        }

        return maxFutureDays;
    };

    const setConferenceRoomDates = (start, end) => {
        onFilterChange('dateCurrentEnd', end);
        setTimeout(() => {
            onFilterChange('dateCurrent', start);
        }, 100);
    };

    return  <div className="info-box-container">
        <div  className="info-box" style={{backgroundColor: '#000', color: '#fff', paddingTop: '1em'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div className='filter-container-item filter-item-select-date' style={{minWidth: '250px'}}>
					    <div className='filter-container-label'>Booking Date</div>
						{ filters.getSiteName(filters.sites.sitesCurrent) === 'Shanghaii' && !filters.showMeetingRooms
						? <DatePicker
							value={moment(filters.dates.dateCurrent.toISO().toString())}
							format={filters.dates.dateFormat}
							allowClear={false}
							onChange={(e) => {
								onFilterChange('dateCurrent', DateTime.fromJSDate(e.toDate()));
								onFilterChange('dateCurrentEnd', DateTime.fromJSDate(e.toDate()));
							}}
							disabledDate={current=> {
                                const maxDays = getMaxFutureDays();
								return filters.getDisabledDate(current, maxDays);
							}}
						/>
						: 
                        <div>
                             { filters.showMeetingRooms 
                             ? <div style={{marginBottom: '10px'}}>
                                    <DatePicker
                                        value={moment(startConferenceDate.toISO().toString())}
                                        format={ filters.dates.dateFormat}
                                        allowClear={false}
                                        style={{width: '100%'}}
                                        onChange={(e) => {
                                            setStartConferenceDate(DateTime.fromJSDate(e.toDate()));
                                            setEndConferenceDate(DateTime.fromJSDate(e.toDate()));
                                        }}
                                        minuteStep={15}
                                        disabledDate={current=> {
                                            const maxDays = getMaxFutureDays();
                                            return filters.getDisabledDate(current, maxDays);
                                        }}
                                    /> 
                                    <br /><br />
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <TimePicker
                                        minuteStep={15}
                                        format='hh:mm A'
                                        allowClear={false}
                                        style={{width: '49%'}}
                                        value={moment(startConferenceDate.toISO().toString())}
                                        onChange={(date) => {
                                            const lxDate = DateTime.fromJSDate(date.toDate());
                                            const updatedStartDate = startConferenceDate.set({hour: lxDate.hour, minute: lxDate.minute, second: lxDate.second}); 
                                            setStartConferenceDate(updatedStartDate);
                                        }}
                                        />
                                        <TimePicker
                                            minuteStep={15}
                                            format='hh:mm A'
                                            allowClear={false}
                                            style={{width: '49%'}}
                                            value={moment(endConferenceDate.toISO().toString())}
                                            onChange={(date) => {
                                                const lxDate = DateTime.fromJSDate(date.toDate());
                                                const updatedEndDate = endConferenceDate.set({hour: lxDate.hour, minute: lxDate.minute, second: lxDate.second});
                                                setEndConferenceDate(updatedEndDate);
                                                setConferenceRoomDates(startConferenceDate, updatedEndDate);
                                            }}
                                            />
                                    </div>
                             </div> 
                            : <RangePicker 
                            	value={[moment(filters.dates.dateCurrent.toISO().toString()), moment(filters.dates.dateCurrentEnd.toISO().toString())]}
                            	format={ filters.dates.dateFormat}
                            	allowClear={false}
                            	showTime={filters.showMeetingRooms}
                            	onChange={(e) => {
                            		onFilterChange('dateCurrent', DateTime.fromJSDate(e[0].toDate()));
                            		onFilterChange('dateCurrentEnd', DateTime.fromJSDate(e[1].toDate()));
                            	}}
                            	minuteStep={15}
                            	disabledDate={current=> {
                                    const maxDays = getMaxFutureDays();
                            		return filters.getDisabledDate(current, maxDays);
                            	}} /> }
                        </div>  
						
						}
				</div>
                <div className='filter-container-item filter-item-select-site' style={{minWidth: '250px'}}>
                    <div className='filter-container-label'>Sites</div>
                    <Select value={filters.sites.sitesCurrent} loading={filters.sites.sitesLoading} onChange={(e) => onFilterChange('sitesCurrent', e)}>
                        {mapData(filters.sites.sitesData)}
                    </Select>
                </div>
                <div className='filter-container-item filter-item-select-building' style={{minWidth: '250px'}}>
                    <div className='filter-container-label'>Floors</div>
                    <Select value={filters.floors.floorsCurrent} loading={filters.floors.floorsLoading} onChange={(e) => onFilterChange('floorsCurrent', e)}>
                        {mapData(filters.floors.floorsData.filter(floor => !floor.hidden))}
                    </Select>
                </div>
                {/*<div style={{ margin: '5px 0'}}>*/}
                {/*    <div>Desk Assets</div>*/}
                {/*    <Select*/}
                {/*        mode="multiple"*/}
                {/*        allowClear*/}
                {/*        style={{ width: '100%', minWidth: 200}}*/}
                {/*        placeholder="Please select"*/}
                {/*        // defaultValue={['a10', 'a11', 'a12', 'a13', 'a14', 'a15', 'a16', 'a17']}*/}
                {/*        onChange={filters.onAssetsFilterChange}*/}
                {/*        filterOption={(input, option) =>*/}
                {/*            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                {/*        }*/}
                {/*        filterSort={(optionA, optionB) =>*/}
                {/*            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())*/}
                {/*        }*/}
                {/*    >*/}
                {/*        {children}*/}
                {/*    </Select>*/}
                {/*</div>*/}
                {filters.showMeetingRooms && <div style={{ margin: '5px 0'}}>
                    <div>Meeting Room Capacity</div>
                    <Select
                        allowClear
                        style={{ width: '100%', minWidth: 200}}
                        placeholder="Please select"
                        value={filters.meetingRooms.meetingRoomCapacity}
                        defaultValue={filters.meetingRooms.meetingRoomCapacity}
                        // defaultValue={['a10', 'a11', 'a12', 'a13', 'a14', 'a15', 'a16', 'a17']}
                        onChange={filters.onMeetingRoomCapacityChange}
                    
                    >
                        {meetingRoomChildren}
                    </Select>
                </div>}
                {filters.showMeetingRooms && <div style={{ margin: '5px 0'}}>
                    <div>Meeting Rooms</div>
                    <Select
                        allowClear
                        autoClearSearchValue
                        onClear={() => {
                            setMeetingRoomSearch('');
                            meetingRoom.setSelectedMeetingRoom(null);
                        }} 
                        showSearch
                        filterOption={false}
                        style={{ width: '100%', minWidth: 200}}
                        placeholder="Please select"
                        value={meetingRoom.selectedMeetingRoom}
                        defaultValue={meetingRoom.selectedMeetingRoom}
                        onChange={(value) => meetingRoom.setSelectedMeetingRoom(value)}
                        onSearch={(value) => setMeetingRoomSearch(value)}
                    >
                        <Option value=''>All</Option>
                        {nodes
                            .filter(({node}) => node.isMeetingRoom)
                            .filter(({node}) => {
                                if(meetingRoomSearch === '') return true;
                                if(node.name?.toLowerCase()?.includes(meetingRoomSearch?.toLowerCase())) {
                                    return true;
                                }
                                return false;
                            })
                            .map(({node}) => (
                            <Option key={node.code} value={node.code}>{node.name}</Option>
                        ))}
                    </Select>
                </div>}
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px',  marginTop: '10px'}}>
                    <div>Desks:</div>
                    <Switch className='filter-toggle'  disabled={true} checked={true} defaultChecked={true} onChange={() => {}} />
                </div>
                {hasConfreneceRooms && <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div>Conference Rooms:</div>
                    <Switch className='filter-toggle' checked={filters.showMeetingRooms} defaultChecked={filters.showMeetingRooms} onChange={filters.toggleMeetingRooms} />
                </div>}
                { hasNeighborhood &&  <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div>Work Studios:</div>
                    <Switch className='filter-toggle' checked={showNeighborhood} defaultChecked={showNeighborhood} onChange={(value) => {setShowNeighborhood(value); }} />
                </div> }
                { hasNeighborhood &&  <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div>Events:</div>
                    <Switch className='filter-toggle' checked={showEvents} defaultChecked={showEvents} onChange={(value) => {setShowEvents(value); }} />
                </div> }
                { canShowPrivateOffice && hasPriavteRooms &&  <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div>Private Office:</div>
                    <Switch className='filter-toggle'  checked={showPrivateOffice} defaultChecked={showPrivateOffice} onChange={(value) => {setShowPrivateOffice(value); }} />
                </div> }
            </div>
        </div>
    </div>;
};

export default InteractiveMapFilter;
