import React, {useState} from 'react';
import { Modal, Button, Select } from 'antd';

import BookDesk from '../../assets/images/bookEvent/book_desk.svg';
import './BookADeskModal.css';

const MoveBookingModal = ({show, onClose, onSubmit, isNeighborhood, loading, nodes, currentNode }) => {
    const [selectedNode, setSelectedNode] = useState({});

    const getAvailableSubNeighborhoodNodes = (nodes, currentSubNeighborhood) => {
        const neighborhoodNodesList = [];
        nodes.forEach(item => {
            const subNeighborhood = item?.node?.subNeighborhood;
            const available = item?.node?.dailyBookableSpacesAvailability[0]?.availability;

            if(subNeighborhood !== currentSubNeighborhood && available) {
                neighborhoodNodesList.push(item.node);
            }
        });
        neighborhoodNodesList.sort((a, b) => {
            return a.code.localeCompare(b.code);
         });

        return neighborhoodNodesList.map(item => <Select.Option value={item.code} key={item.code}>{item.code}</Select.Option>);
    };

    
    const getAvailableNodes = (nodes, currentNode) => {
        const neighborhoodNodesList = [];
        nodes.forEach(item => {
            
            const available = item?.node?.dailyBookableSpacesAvailability[0]?.availability;

            if(item.code !== currentNode.code && available) {
                neighborhoodNodesList.push(item.node);
            }
        });
        neighborhoodNodesList.sort((a, b) => {
            return a.code.localeCompare(b.code);
         });

        return neighborhoodNodesList.map(item => <Select.Option value={item.code} key={item.code}>{item.code}</Select.Option>);
    };

    const neighborhoodNodeSelectHandler = (nodes, selectedNeibhorhoodCode) => {
       
        nodes.forEach(item => {
            const code = item?.node?.code;
            if(code === selectedNeibhorhoodCode) {
                setSelectedNode(item.node);
            }
        });

    };

    const closeHandler = () => {
        setSelectedNode({});
        onClose(false);
    };

    const getModalContent = (title, content, footer) => {
        return <div className="booking-modal-container">
            <div className="booking-modal-header-wrapper">
                <div className="booking-modal-header">
                    {title}
                </div>
                <div className="booking-modal-content">{content}</div>
            </div>
            <div className="booking-modal-footer">
                {footer}
            </div>
        </div>;
    };

    const getDeskContent = () => {
        const content =  <div className="booking-modal-body">
                 <div style={{marginBottom: '8px'}}>
                    <div style={{display: 'flex'}}>
                        <p style={{minWidth: '100px'}}><b>Move From: </b></p>
                        <p>{currentNode.code}</p>
                    </div>
                    <div style={{display: 'flex'}}>
                        <p style={{minWidth: '100px'}}><b>Move To: </b></p>
                        <Select 
                            style={{width: '200px'}} 
                            value={selectedNode?.code} 
                            className='booking-modal-select'
                            onChange={(e) => neighborhoodNodeSelectHandler(nodes, e)}>
                            {isNeighborhood ? getAvailableSubNeighborhoodNodes(nodes, currentNode.subNeighborhood): getAvailableNodes(nodes, currentNode) }
                        </Select>
                    </div>
                </div> 
        </div>;

        const footer = <div>
            <Button className="mr-1" onClick={() => {
                     closeHandler(); 
                }} >
            Cancel
            </Button>
                <Button
                    disabled={Object.keys(selectedNode).length === 0}
                    key="submit" type="primary"  loading={loading} onClick={() => onSubmit(selectedNode)}>
                    Move
                </Button>
        </div>;

        const title = getTitle('MOVE A DESK');

        return getModalContent(title, content, footer);
    };

    const getTitle = (text) => {
        return <div><span><img src={BookDesk} alt="BookDesk" style={{maxWidth: '1.6rem', marginRight: '2em'}} /></span><span>{text}</span></div>;
    };

    return <Modal
        title={null}
        centered
        width="600px"
        visible={show}
        wrapClassName="ant-modal-flexspace"
        onOk={() => closeHandler()}
        onCancel={() => closeHandler()}
        footer={null}

    >
         {getDeskContent()}
    </Modal>;
};

export default MoveBookingModal;