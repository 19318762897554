import React, { useContext, useState } from 'react';
import { FindEventContext } from '../../pages/FindAnEvent';
import { Tooltip } from 'antd';
import CalendarCirclePlusRegular from '../../assets/images/FontAwesome/calendar-circle-plus-regular.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const FindAnEventItem = ({ item }) => {
    const {
        name,
        location,
        start,
        end,
        calendar,
        calendarId,
        eventId,
    } = item;

    const [hovered, setHovered] = useState(false);

    const {
        colorMap,
        addEventHandler
    } = useContext(FindEventContext);

    const { width } = useWindowDimensions();

    const hoverHandler = () => {
        setHovered(!hovered);
    };

    return (
        <div
            style={{
                marginTop: "2rem",
                marginBottom: "2rem",
                width: "100%",
                display: "flex",
                justifyContent: "center"
        }}
        >
            <Tooltip title={'Add to Google Calendar'}>
                <div
                    onMouseEnter={hoverHandler}
                    onMouseLeave={hoverHandler}
                    style={{
                        overflow: "auto",
                        cursor: "pointer",
                        height: "100%",
                        width: "80%",
                        backgroundColor: colorMap[calendar]?.['events'] ?? "#feffe6",
                        borderRadius: "8px",
                        padding: "1rem",
                        boxShadow: hovered ? "0px 4px 4px 0px rgba(0,0,0,0.25)" : ""
                    }}
                    onClick={() => addEventHandler({eventTitle: name, eventId, calendarId})}
                >
                    <div style={{display: "flex", justifyContent: "space-between", gap: "0.4rem", alignItems: "flex-start", fontSize: "12px"}}>
                        <div>{name}</div>
                        {width > 800 &&
                            <img
                                style={{width: '1rem'}}
                                src={CalendarCirclePlusRegular} alt='Add to Calendar'
                            />
                        }
                    </div>
                    <div style={{fontSize: "10px", fontWeight: "bold", marginTop: ".25rem"}}>{start.toFormat('t')} - {end.toFormat('t')}</div>
                    <div style={{marginTop: "1rem", fontSize: "12px"}}>{location}</div>
                </div>
            </Tooltip>
        </div>
    )
};

export default FindAnEventItem;