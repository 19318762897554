import Sheldon from '../assets/images/Sheldon.png';
import SheldonHover from '../assets/images/SheldonHover.png';
import React from 'react';


const SheldonComponent = () => {

    return (
        <span className="sheldon-chat-bot">
						<img className="Sheldon-bot" src={Sheldon} width='100%' alt='Sheldon-chat-bot' />
						<img className="Sheldon-bot-hover" src={SheldonHover} width='100%' alt='Sheldon-chat-bot-hover' onClick={() => 	window.open('https://app.moveworks.ai/bot/12158181891317158097/slack/?embed_location=ssp&campaign=flexwork&platform=flexwork&version=1', '_blank')} />
                        {/* <img className="Sheldon-bot-hover" src={SheldonHover} width='100%' alt='Sheldon-chat-bot-hover' onClick={() =>  window.open('https://panservicedesk.service-now.com/esp?id=pan_esp_wor_inner&sys_id=cd26f004ff331100a0a5fffffffffff9', '_blank')} /> */}

					</span>
    );
};

export default SheldonComponent;
