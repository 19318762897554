import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import Header from '../shared/Header';
// import LabelHeader from './../components/LabelHeader';
import CoffeeBar from './../assets/images/coffee_bar_icon.png';
import FoodIcon from './../assets/images/food_icon.png';
import EventNavigationbar from '../components/EventList/EventNavigationbar';
import SheldonComponent from '../components/SheldonComponent';

const foodLocations = [
    {
        name: 'Santa Clara HQ',
        places: [
            {
                name: 'BUILDING 1 - COFFEE BAR - GROUND UP',
                icon: CoffeeBar,
                url: 'https://urldefense.com/v3/__https://delivery.zipthruordering.com/PANCOFFE__;!!Mt_FR42WkD9csi9Y!ZBb1TubWsGAqwuxtLEv1y_b5seS60vsaUUacc2lu4GwWaMTquYlXWVKRjko075XNckf38GRsVDXRVO8QYYtkhKaIumKR3mZlug$'
            },
            {
                name: 'BUILDING 2 - BISTARO BREAKFAST AND LUNCH',
                icon: FoodIcon,
                url: 'https://delivery.zipthruordering.com/BonAppetitUX/?storeid=PANBSTRO'
            },
            {
                name: 'BUILDING 3 - CAFE LUNCH - CAFE 3200',
                icon: FoodIcon,
                url: 'https://urldefense.com/v3/__https://delivery.zipthruordering.com/PANBLDG3__;!!Mt_FR42WkD9csi9Y!ZBb1TubWsGAqwuxtLEv1y_b5seS60vsaUUacc2lu4GwWaMTquYlXWVKRjko075XNckf38GRsVDXRVO8QYYtkhKaIumKXdlyqUQ$'
            },
            {
                name: 'BUILDING 3 - COFFEE BAR - DEJABREW',
                icon: CoffeeBar,
                url: 'https://urldefense.com/v3/__https://delivery.zipthruordering.com/PANB3COF__;!!Mt_FR42WkD9csi9Y!ZBb1TubWsGAqwuxtLEv1y_b5seS60vsaUUacc2lu4GwWaMTquYlXWVKRjko075XNckf38GRsVDXRVO8QYYtkhKaIumLOAhlvcA$'
            },
            {
                name: 'BUILDING 4 - MARKETPLACE - LUNCH',
                icon: FoodIcon,
                url: 'https://delivery.zipthruordering.com/BonAppetitUX/?storeid=PANB4CAF'
            }
        ]
    }
];

const OrderFood = () => {
    const [ locations ] = useState(foodLocations);
    const [step, setStep] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedBuilding, setSelectedBuilding] = useState('');
    const history = useHistory();

    const renderLocations = () => {
        return locations.map((location, index) => {
            const html = <div key={location.name} onClick={() => {
                setStep(2);
                setSelectedLocation(location.name);
            }} className="flexevt-card-btn step-modal-card">
                    <p style={{textAlign: 'center'}}>{location.name}</p>
                </div>;

            return locations.length % 2 !== 0 && index === 0 ? <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>{html}</div> : html;
        });
    };

    const renderBuildings = () => {
        const locationIndex = locations.findIndex(location => location.name === selectedLocation);
        
        if(locationIndex === -1) return null;

        return locations[locationIndex].buildings.map((building, index) => {
            const html =  <div key={building.name} onClick={() => {
                setStep(3);
                setSelectedBuilding(building.name);
            }} className="flexevt-card-btn step-modal-card">
                <p style={{textAlign: 'center'}}>{building.name}</p>
            </div>;

            return locations[locationIndex].buildings.length % 2 !== 0 && index === 0 ? <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>{html}</div> : html;

        });
    };

    const renderPlaces = () => {

        const locationIndex = locations.findIndex(location => location.name === selectedLocation);
        if(locationIndex === -1) return null;

        // const buildingIndex = locations[locationIndex].buildings.findIndex(building => building.name === selectedBuilding);
        // if(buildingIndex === -1) return null;

        // return locations[locationIndex].buildings[buildingIndex].places.map((place, index) => {
        return locations[locationIndex].places.map((place, index) => {
            const html =  <div key={place.name} onClick={() => window.open(place.url, '_blank')} className="flexevt-card-btn step-modal-card"><img src={place.icon} alt={place.name} /><p style={{textAlign: 'center'}}>{place.name}</p></div>;
            // return locations[locationIndex].buildings[buildingIndex].places.length % 2 !== 0 && index === 0 ? <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>{html}</div> : html;
            return locations[locationIndex].places.length % 2 !== 0 && index === 0 ? <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>{html}</div> : html;
        });
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return renderLocations();
            case 2:
                return renderPlaces();
            default:
                break;
        }
    };

    const handleBack = () => {
        if(step > 1) {
            setStep(step - 1);
        } else {
            history.push('/');
        }
    };

    const getNavigationTitle = () => {
        let title = 'Order Food';
        switch (step) {
            case 1:
                return `${title}: Select Location`;
            case 2:
                return `${title}: Select Food Court`;
            default:
                return title;
        }
    };
 
    return (
        <>
            <Header hideMyBadge={true} />
            <EventNavigationbar navigationTitle={getNavigationTitle()}  path='/' onBack={() => handleBack()} />
                <div className='custom-bck inner-bck'>
                    <section className="custom-container">
                    <div className="flexevt-card-btn-wrap">
                        <div className="flexevt-card-inner-wrap">
                            {renderStep()}
                        </div>
                    </div>
                    
                    </section>
                </div>
            
            <SheldonComponent/>
        </>
    );
};

export default withOktaAuth(OrderFood);
