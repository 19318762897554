import React from 'react';
import './SolidButton.css';
const SolidButton = (props) => {
    return (
        <button id="ClickMe" className="solid-btn" onClick={props.onHandle}>
            {props.label}
        </button>
    );
};

export default SolidButton;