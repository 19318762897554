import React from 'react';
import { MessageOutlined } from "@ant-design/icons";
import { Button } from 'antd';

const FeedBackComponent = ({onClick}) => {
    return (
        <div className="feedback-container" onClick={onClick}>
            
            {/* <Button size='large' shape="circle" icon={<MessageOutlined style={{fontSize: '2rem'}} />} /> */}
            <MessageOutlined style={{fontSize: "2rem", color: "white"}} />
            <div className="feedback-text-container">
                <span className="feedback-text">Tell us how we're doing</span>
            </div>
            {/* <img className="Sheldon-bot-hover" src={SheldonHover} width='100%' alt='Sheldon-chat-bot-hover' onClick={() =>  window.open('https://panservicedesk.service-now.com/esp?id=pan_esp_wor_inner&sys_id=cd26f004ff331100a0a5fffffffffff9', '_blank')} /> */}

        </div>
    );
};

export default FeedBackComponent;