import React from 'react';

const ProgressBar = (props) => {
    return (
        <div className="progress-bar-main">
            <div className="progress-bar-outer">
                <div className="progress-bar-inner" style={{ width: props.filledPercentage }}></div>
            </div>
            <span className="progress-bar-label">{props.filledPercentage}{' '} Complete</span>
        </div>
    );
};

export default ProgressBar;