import React  from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import {Select, Modal} from 'antd';
import Joyride from 'react-joyride';
//import moment from 'moment';
import axios from 'axios';
import env from '../env';
// import EventHeader from '../components/EventList/EventHeader/EventHeader';
import Header from '../shared/Header';
import InteractiveMap from '../components/BookADesk/InteractiveMap';
import { DateTime } from 'luxon';
//import { FilterOutlined  } from '@ant-design/icons';
//import DeskAssets from '../components/BookADesk/DeskAssets';
import example_floormap from '../assets/images/bck/example_floormap.png';
import EventNavigationbar from '../components/EventList/EventNavigationbar';
import SheldonComponent from '../components/SheldonComponent';
//const { RangePicker } = DatePicker;

const rangeFormat = 'MM/DD/YYYY hh:mm A';
const dateFormat = 'ddd, MMM DD';

class BookADesk extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// dateCurrent: moment(moment().format(dateFormat), dateFormat),
			dateCurrent: DateTime.now(),
			dateCurrentEnd: DateTime.now(),
			sitesData: [],
			sitesLoading: true,
			sitesCurrent: '-',
			floorsData: [],
			floorsLoading: true,
			floorsCurrent: '-',
			floorAvailabilities: [],
			showFloorsUnavailableModal: false,
			deskAssets: [],
			selectedDeskAssets: [],
			steps: [
				{
					target: '.filter-item-select-date',
					content: 'Please select the date that you would like to book your space for. As a reminder, bookings are for the entire day and can be made up to 2 weeks in advance.',
					disableBeacon : true,
				},
				{
					target: '.filter-item-select-site',
					content: 'Select the site that you would like to book for here',
				},
				{
					target: '.filter-item-select-building',
					content: 'Select the floor that you would like to book for. We only show floors with available desks on them',
				},
				{
					target: '.filter-item-select-assets',
					content: 'You can filter for specific assets such as a Monitor, Mouse, etc',
				},
				{
					target: '.booking-node',
					content: 'Available desks are highlighted in green and are available for booking on the date selected. Orange desks are team-specific and available to book near your team.',
				}
			],
			showTour: false,
			showBeaconPopover: false,
			showMeetingRooms: false,
			meetingRoomCapacity: null,
			searchEmployee: false
		};
	}


	async getShowTour() {
		await new Promise((resolve) => {
			let interval = setInterval(() => {
				if(!this.state.sitesLoading && this.state.sitesData.length > 0) {
					clearInterval(interval);
					resolve(true);
				} 
			}, 7000);	
		});
		const showTour = localStorage.getItem('welcomeTourShown');
		if (showTour == undefined) {
			this.setState({ showTour: true });
			localStorage.setItem('welcomeTourShown', true);
		}
	}

	defaultToHQBuilding3 = (sites) => {
		for (let s in sites) {
			if (sites[s].code === 'SC3') {
				return sites[s].id;
			}
		}
		return sites[0].id;
	}

	defaultToHQBuilding3Floor4 = (floorsData) => {
		if (!floorsData) return '';
		for (let f in floorsData) {
			if (floorsData[f].name === '4th Floor [FLEXSpace]') {
				return floorsData[f].id;
			}
		}
		return floorsData[0]?.id;
	}

	filterHiddenSites = (sitesData) => {
		const updatedSitesData = [];

		sitesData.forEach(site => {
			const hideSite = site.floors.every(item => item.hidden);
			if (!hideSite) 
				updatedSitesData.push(site);
		});

		return updatedSitesData;
	}

	getDeskSites = async () => {
		let config = {method: 'get', url: `${env.BACKEND_URL}/desk-sites`, headers: {'Authorization': this.props.authState.accessToken.value}};
		let response = await axios(config);
		const sitesCurrent = localStorage.getItem('sitesCurrent') ? localStorage.getItem('sitesCurrent') : this.defaultToHQBuilding3(response.data);
		let sitesData = this.filterHiddenSites(response.data);
                const amsterdamIndex = sitesData.findIndex(site => site.name === 'Amsterdam');
                const amsterdamFlexIndex = sitesData.findIndex(site => site.name === 'Amsterdam (FLEXWork)');

		const sfIndex = sitesData.findIndex(site => site.code === 'SF');
		const telAvivIndex = sitesData.findIndex(site => site.name === 'Tel Aviv');
		if(telAvivIndex !== -1) {
		    let telAvivFloors =  [...sitesData[telAvivIndex].floors];
		    telAvivFloors = telAvivFloors.filter(item => item.name !== "9th Floor");
		    sitesData[telAvivIndex].floors = telAvivFloors;
		}

        if(amsterdamIndex !== -1) {
                    sitesData.splice(amsterdamIndex, 1);
                }
		if(sfIndex !== -1) {
                    sitesData.splice(sfIndex, 1);
                }
        if(amsterdamFlexIndex !== -1) {
                   let amsterdamFlexFloors =  [...sitesData[amsterdamFlexIndex].floors];
                   amsterdamFlexFloors = amsterdamFlexFloors.filter(item => item.code !== "1" && item.code !== "2" && item.code !== "3"  && item.code !== "4");
                   sitesData[amsterdamFlexIndex].floors = amsterdamFlexFloors;
                }
		this.setState({sitesData: sitesData, sitesLoading: false, sitesCurrent: sitesCurrent},()=>{
			this.checkFloorAvailabilities(sitesCurrent);
		});
		return sitesCurrent;
	};

	checkFloorAvailabilities = async (sitesCurrent) => {
		// const startDate= moment(this.state.dateCurrent).format('YYYY-MM-DD');
		const startDate= this.state.dateCurrent.toISODate().toString();
		let config = {method: 'get', url: `${env.BACKEND_URL}/check-floor-availabilities?buildingId=${sitesCurrent}&startDate=${startDate}`, headers: {'Authorization': this.props.authState.accessToken.value}};
		let response = await axios(config);

		const canFloorsBook = this.checkBookableFloors(response.data);
		if(!canFloorsBook && !this.state.showFloorsUnavailableModal) {

			this.setState({showFloorsUnavailableModal: true});
		}

		this.setState({floorAvailabilities: response.data},()=>{
			this.getDeskFloors(sitesCurrent);
		});
		return;
	};

	getDeskFloors = async (sitesCurrent) => {
		//TODO: only show floors with desks available for the given date
		const site = this.state.sitesData.find(x => x.id === sitesCurrent);
		if (!site || !('floors' in site)) {
			localStorage.removeItem('sitesCurrent');
			this.setState({sitesCurrent: '-'});
			return;
		}

		const floorsData = site.floors.filter(({id})=>this.state.floorAvailabilities.find(floor=> {

			const floors = this.state.sitesData.find(x => x.id === sitesCurrent).floors;
			const building34thFloorIndex = floors.findIndex(item => item.id === id && item.name === '4th Floor [FLEXSpace]');
			if(building34thFloorIndex !== -1) return true;
			
			// return floor.id===id && floor.canBook;
			return floor.id===id;
		}));
		this.setState({floorsData: floorsData || [], floorsLoading: false, floorsCurrent: this.defaultToHQBuilding3Floor4(floorsData)});
	};

	getDeskAssets = async () => {
		let config = {method: 'get', url: `${env.BACKEND_URL}/desk-assets`, headers: {'Authorization': this.props.authState.accessToken.value}};
		let response = await axios(config);
		if(response.data && response.data.length > 0) {
			this.setState({deskAssets: response.data});
		}
		return;
	}

	onAssetsFilterChange = (val, assets) => {
		const updatedAssets = assets.map(asset => ({id: asset.value, name: asset.children}));
		this.setState({selectedDeskAssets: updatedAssets});
	}
	onMeetingRoomCapacityChange = (val) => {
		this.setState({meetingRoomCapacity: val});
	}



	componentDidMount = async () => {
		/* Supports Google Callback */
		sessionStorage.setItem('callbackPage', '/book-a-space');

		const sitesCurrent = await this.getDeskSites();
		await this.getDeskFloors(sitesCurrent);
		await this.getDeskAssets();
		this.loadExternalSite();
		this.getShowTour();
	};

	mapData = (sites) => {
		sites.sort((a, b) => a.name < b.name ? -1 : 1);
		const res = [];
		sites.forEach(s => {
			res.push(<Select.Option value={s.id} key={s.id}>{s.name}</Select.Option>);
		});
		return res;
	};


	onChange = async (key, value) => {
		this.setState({[key]: value});
		if(key === 'sitesCurrent') {
			localStorage.setItem('sitesCurrent', value);
			// await this.getDeskFloors(value);
			await this.checkFloorAvailabilities(value);

			if(this.isSameDate(this.state.dateCurrent, DateTime.now())) {

				const timezone = this.getSiteData(value).timezone;
				if(timezone) {
					const siteTime = DateTime.now().setZone(timezone);
					if(siteTime.day > DateTime.now().day) {
						const updatedDate = DateTime.now().set({day: this.state.dateCurrent.day + 1});
						this.setState({dateCurrent: updatedDate, dateCurrentEnd: updatedDate});
					}
				}
			}
		}
	};

	getSiteName = (id) => {
		if(this.state.sitesData.length > 0) {

			const index = this.state.sitesData.findIndex((site) => site.id == id);
			if(index !== -1) {
				return this.state.sitesData[index].name;
			}

		}
		return '';
	}

	getFloorName = (id) => {
		if(this.state.floorsData.length > 0) {
			const index = this.state.floorsData.findIndex((floor) => floor.id == id);
			if(index !== -1) {
				return this.state.floorsData[index].name;
			}
			return this.state.floorsData[0].name;
		}
		return '';
	}

	checkBookableFloors = (floors) => {
		return true;
		// let floorsList = floors ? floors : [...this.state.floorAvailabilities];
		// let floorsAvailable = false;
		// floorsList.forEach(item => {
		// 	if(item.canBook) {
		// 		floorsAvailable =true;
		// 	}
		// });

		// return floorsAvailable;
	}

	getSiteData = (siteId) => {
		if(this.state.sitesData.length === 0) return;

		const index = this.state.sitesData.findIndex(item => item.id == siteId);

		if(index !== -1) {
			return this.state.sitesData[index];
		}
		return {};
 	}

	 isSameDate = (dateTime1, dateTime2) => {
        return dateTime1.month === dateTime2.month && dateTime1.day === dateTime2.day && dateTime1.year === dateTime2.year;
      };

	toggleBeaconPopup = (value) => {
		this.setState({showBeaconPopover: value});
	}

	toggleMeetingRooms = (value) => {
		this.setState({showMeetingRooms: value});
	}

	handleStartTour = () => {
		this.toggleBeaconPopup(false);
		this.setState({showTour: true});
	}

	getDisabledDate = (date, maxDays) => {
        const currentDate = DateTime.now();
        const dateObject = DateTime.fromJSDate(date.toDate());
        const maxFutureDate = maxDays ? currentDate.plus({days: maxDays}) : currentDate.plus({days: 14});
        const isSameDate =  this.isSameDate(currentDate, dateObject);
        if(dateObject > maxFutureDate) {
            return true;
        }
        if(date && (!isSameDate && dateObject  < currentDate)) {
            return true;
        } else {
            return false;
        }
    }

		
	loadExternalSite = async () => {
		if(!this.props.location.state) return;
		const {seat, name} = this.props.location.state;
		try {
			const resp = await axios({
				method: 'get',
				url: `${env.BACKEND_URL}/search-employee?search=${name}`,
				headers: { 'Authorization': this.props.authState.accessToken.value },
			});
			if(resp.data.ok) {
				if(resp.data.data.length === 1) {
					const employeeInfo = resp.data.data[0];
					await new Promise((resolve) => {
						let interval = setInterval(() => {
							if(!this.state.sitesLoading && this.state.sitesData.length > 0) {
								clearInterval(interval);
								resolve(true);
							} 
						}, 1000);	
					});
					let transformedSeat = seat;
					let date;
					if(seat.split('-').length === 1) {
						transformedSeat = `${seat.split('.')[0]}-${seat.split('.')[1]}`;
						employeeInfo.seat = {
							external_id:  `${transformedSeat}-${seat}`
						};
						this.navigateToSelectedEmployee(employeeInfo, transformedSeat);
					} else {
						// employeeInfo.seat = {
						// 	external_id: transformedSeat
						// };
						date = DateTime.now();
						this.navigateToSelectedEmployee(employeeInfo, transformedSeat, date, true);
					}
				}
			}
		} catch(e) {
			return;
		}

		// const {seat, employeeInfo, date} = this.props.location.state;
		// await new Promise((resolve) => {
		// 	let interval = setInterval(() => {
		// 		if(!this.state.sitesLoading && this.state.sitesData.length > 0) {
		// 			clearInterval(interval);
		// 			resolve(true);
		// 		} 
		// 	}, 1000);	
		// });

		// let updatedDate;
		// if(date) {
		// 	updatedDate = DateTime.fromFormat(date, 'MM/dd/yyyy');
		// }
	
		// this.navigateToSelectedEmployee(employeeInfo, seat, updatedDate, true);
	}


	navigateToSelectedEmployee = async (employeeInfo, externalId, date, luxonDate) => {
		if(employeeInfo === null) {
			this.setState({
				searchEmployee: employeeInfo,
			});

			const sitesCurrent = await this.getDeskSites();
			await this.getDeskFloors(sitesCurrent);
			await this.getDeskAssets();
			return;
		}
		// const externalId = employeeInfo.seat.external_id;
		const buildingCode = externalId.split('-')[0];
		const floorCode = externalId.split('-')[1];
		//const deskCode = externalId.split('-')[2];
		console.log(buildingCode, floorCode);
		const buildingIndex = this.state.sitesData.findIndex(item => buildingCode === item.code);
		const buildingId = this.state.sitesData[buildingIndex].id;
		const floorIndex = this.state.sitesData[buildingIndex].floors.findIndex(item => floorCode === item.code);
		const floorId = this.state.sitesData[buildingIndex].floors[floorIndex].id;

		this.setState({
			sitesCurrent: buildingId, 
			floorsCurrent: floorId, 
			floorsData: this.state.sitesData[buildingIndex].floors ,
			searchEmployee: employeeInfo,
			dateCurrent: date ? luxonDate ? date : DateTime.fromJSDate(date.toDate()) : this.state.dateCurrent,
			dateCurrentEnd: date ? luxonDate ? date : DateTime.fromJSDate(date.toDate()) : this.state.dateCurrentEnd,
		});

	}

	render() {
		const {dateCurrent, dateCurrentEnd, sitesCurrent, sitesData, sitesLoading, floorsData, floorsLoading, floorsCurrent, deskAssets} = this.state;



		return (
			<div>
				{this.state.showTour && <Joyride
					steps={this.state.steps}
					continuous={true}
					callback={(data) => {
						if( data.action === 'reset') {
							this.setState({showTour: false});
						}
					}}
				/>}
				<Header hideMyBadge={true}/>
				<EventNavigationbar navigationTitle={'Book A Space'}  path='/'  isBookADesk={true} onEmployeeSelected={this.navigateToSelectedEmployee} />
				{/* <div className='book-a-desk-filters-container'>
					<div className='filter-container-item filter-item-select-date'>
						<div className='filter-container-label'>Booking Date</div>
						{ this.getSiteName(sitesCurrent) === 'Shanghai' && !this.state.showMeetingRooms
						? <DatePicker
							value={moment(dateCurrent.toISO().toString())}
							format={dateFormat}
							allowClear={false}
							onChange={(e) => {
								this.onChange('dateCurrent', DateTime.fromJSDate(e.toDate()));
								this.onChange('dateCurrentEnd', DateTime.fromJSDate(e.toDate()));
							}}
							disabledDate={current=> {
								return this.getDisabledDate(current);
							}}
						/>
						: 
						<RangePicker 
							value={[moment(dateCurrent.toISO().toString()), moment(dateCurrentEnd.toISO().toString())]}
							format={this.state.showMeetingRooms  ? rangeFormat :  dateFormat}
							allowClear={false}
							showTime={this.state.showMeetingRooms}
							onChange={(e) => {
								this.onChange('dateCurrent', DateTime.fromJSDate(e[0].toDate()));
								this.onChange('dateCurrentEnd', DateTime.fromJSDate(e[1].toDate()));
							}}
							minuteStep={15}
							disabledDate={current=> {
								return this.getDisabledDate(current);
							}} />
						}
					</div>
					<div className='filter-container-item filter-item-select-site'>
						<div className='filter-container-label'>Sites</div>
						<Select value={sitesCurrent} loading={sitesLoading} onChange={(e) => this.onChange('sitesCurrent', e)}>
							{this.mapData(sitesData)}
						</Select>
					</div>
					<div className='filter-container-item filter-item-select-building'>
						<div className='filter-container-label'>Floors</div>
						<Select value={floorsCurrent} loading={floorsLoading} onChange={(e) => this.onChange('floorsCurrent', e)}>
							{this.mapData(floorsData)}
						</Select>
					</div>
					<div className='filter-action-item filter-item-select-assets' style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
						<div className='filter-container-label'>Filters</div>
						<Popover content={<DeskAssets 
											deskAssets={deskAssets} 
											onFilterChange={this.onAssetsFilterChange} 
											showMeetingRooms={this.state.showMeetingRooms} 
											toggleMeetingRooms={this.toggleMeetingRooms}
											meetingRoomCapacity={this.state.meetingRoomCapacity}
											onMeetingRoomCapacityChange={this.onMeetingRoomCapacityChange}  />}  trigger="click" placement="bottom">
							<Button
								type={this.state.selectedDeskAssets.length> 0 ? 'primary' : 'default' }
								icon={<FilterOutlined />}
								style={{minWidth: 40}}
								/>
						</Popover>
					</div> */}
					{/* {!this.state.showTour && <Popover 
						visible={this.state.showBeaconPopover}
						content={<div>
							<p>Do you want to start a tour?</p>
							<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
								<Button type='primary' onClick={this.handleStartTour}>Yes</Button>
								<Button onClick={() => this.toggleBeaconPopup(false)}>No</Button>
							</div>
						</div>} trigger="click" placement="bottom">
						<div className='beacon-button' onClick={() => this.toggleBeaconPopup(true)}></div>
					</Popover> } */}
				{/* </div> */}
				{window.location.search && window.location.search === '?demo=true' &&
					<img src={example_floormap}/>
				}
				{
				this.checkBookableFloors() && <InteractiveMap
					floorId={floorsCurrent}
					dateCurrent={dateCurrent}
					dateCurrentEnd={this.getSiteName(sitesCurrent) === 'Shanghaii' ? dateCurrent : dateCurrentEnd}
					site={this.getSiteName(sitesCurrent)}
					floor={this.getFloorName(floorsCurrent)}
					siteData={this.getSiteData(sitesCurrent)}
					selectedDeskAssets={this.state.selectedDeskAssets}
					socket={this.props.socket}
					showMeetingRooms={this.state.showMeetingRooms}
					meetingRoomCapacity={this.state.meetingRoomCapacity}
					searchEmployee={this.state.searchEmployee}
					filters={{
						sites: { sitesLoading, sitesCurrent, sitesData}, 
						floors: {floorsLoading, floorsCurrent, floorsData},
						dates: {dateCurrent, dateCurrentEnd, dateFormat, rangeFormat},
						meetingRooms: {meetingRoomCapacity:  this.state.meetingRoomCapacity},
						showMeetingRooms: this.state.showMeetingRooms,
						deskAssets: deskAssets,
						onAssetsFilterChange: this.onAssetsFilterChange,
						onMeetingRoomCapacityChange: this.onMeetingRoomCapacityChange,
						getSiteName: this.getSiteName,
						getDisabledDate: this.getDisabledDate,
						toggleMeetingRooms: this.toggleMeetingRooms
					}}
					onFilterChange={this.onChange}
					/>
				}
				<Modal
                        title="Book a Desk"
                        centered
                        visible={this.state.showFloorsUnavailableModal}
                        onOk={() => {
							this.setState({showFloorsUnavailableModal: false});
							localStorage.removeItem('sitesCurrent');
							this.getDeskSites();
						}}
                        onCancel={() => {
							this.setState({showFloorsUnavailableModal: false});
							localStorage.removeItem('sitesCurrent');
							this.getDeskSites();
						}}
                        footer={null}
                    >
                        <p>No Floors available to book for given date</p>

                    </Modal>
				<SheldonComponent/>

			</div>


		);
	}
}


BookADesk.propTypes = {
	authState: PropTypes.object
};
export default withRouter(withOktaAuth(BookADesk));
