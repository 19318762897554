import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './EventList.css';
import { withRouter } from 'react-router-dom';
import SearchEmployee from '../BookADesk/SearchEmployee';

const EventNavigationBar = (props) => {
    return (
        <section className="eventlist-navbar-wrap" style={props.isBookADesk ? { height: 'auto', padding: '5px 0'} : null}>
            <div className="eventlist-nav-bar custom-container-fluid">
                <div className="link" onClick={() => {
                    if (props.onBack) {
                        props.onBack();
                    } else {
                        props.history.push(props.path);
                    }
                }}>
                    <span className={'mr-2'}><FontAwesomeIcon icon={faArrowLeft} /> </span>
                </div>
                <div className="eventlist-title">
                    {props.navigationTitle}
                </div>
                {/*<div className="link" onClick={props.openModal}>*/}
                {/*    MY RESERVATIONS*/}
                {/*</div>*/}
                {props.isBookADesk && (
                    <div style={{ width: '250px', position: 'absolute', right: '3em', display: 'flex', marginTop: '-3px'}}>
                        <SearchEmployee onSelected={props.onEmployeeSelected} />
                    </div>
                )}
            </div>
        </section>
    );
};
export default withRouter(EventNavigationBar);
