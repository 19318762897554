import React, {useState, useEffect} from 'react';
import moment from 'moment';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { Card, Form, Input, Select, Alert, DatePicker, TimePicker, Checkbox, Button, Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import env from '../../env';
import { DateTime } from 'luxon';
import useDebounce from '../../hooks/useDebounce';
// import CheckAvailability from './CheckAvailability';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 10,
    }
};

const ProjectRoom = ({authState, data}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [messageObject, setMessageObject] = useState({});
    const [showEventModal, setShowEventModal] = useState(false);
    // const [showAvailability, setShowAvailability] = useState(false);
    const [hqDates, setHqDates] = useState([]);
    const history = useHistory();

    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    
    useEffect(async () => {
          if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
            let config = { method: 'get', url: `${env.BACKEND_URL}/search-employees?query=${debouncedSearchTerm}`, headers: { 'Authorization': authState.accessToken.value } };
            let response = await axios(config);
            setResults(response.data.data);
          } else {
            setResults([]);
          }
    },[debouncedSearchTerm]);


    useEffect(async () => {
        let config = {
            method: 'get',
            url: `${env.BACKEND_URL}/get-hq-dates-availability`,
            headers: { 'Authorization': authState.accessToken.value },
        };
       
        try {
            let response = await axios(config);
            const data = response.data;
            if (!(data.data)) {
                throw data;
            }

            setHqDates(data.data);

        } catch (err) {
            console.log(err);
        }
    }, []);


    const onFormSubmit = async (e) => {
        setLoading(true);
        let config = {
            method: 'post',
            url: data ? `${env.BACKEND_URL}/update-hq-event?eventId=${data.briefing_id}` : `${env.BACKEND_URL}/create-hq-event`,
            headers: { 'Authorization': authState.accessToken.value },
            data: {
                title: e.eventName,
                requestor_name: e.requesterName,
                requestor_email: e.requesterEmail,
                requestor_phone: e.requesterPhone,
                region_id: e.department,
                email: e.requesterEmail,
                preferred_date: e.proposedStartDate.format('YYYY-MM-DD'),
                preferred_end_date: e.proposedEndDate.format('YYYY-MM-DD'),
                preferred_start_time:  e.proposedStartTime.format('HH:mm:ss'),
                preferred_end_time:  e.proposedEndTime.format('HH:mm:ss'),
                attendees_type: e.attendeesType,
                project_overview: e.projectOverview,
                business_need: e.businessNeed,
                authorizing_sr_directorvpsvp: e.authPerson,
                i_acknowledge: e.acknowledge,
                recurring1: e.recurring,
                center_id: 1,
                briefing_type_id: 2,
                status_id: 2,
                submit: true
            }
          
        };

        if(data) {
            config.data.status_id = undefined;
            config.data.center_id = undefined;
            config.data.briefing_type_id = undefined;
            config.data.status_id =  undefined;
        }
        try {
            let response = await axios(config);
            const responseData = response.data;
            if (!(responseData.data)) {
                throw responseData;
            }

            setMessageObject({ 
                value: true,  
                body: <div className='hasSubmittedTicket'>
                        <CheckCircleOutlined />
                        <h4>Thank You</h4>
                        <div>Your request has been {data ? 'updated ' : 'created '} successfully. An event manager will be in contact with you within 48 hours to discuss your event plan.</div>
                    </div> 
            });
            setShowEventModal(true);
            if(!data) {
                form.resetFields();
            }

        } catch (err) {
            setMessageObject({ 
                value: true,  
                body: <div className='hasSubmittedTicket'>
                        <div>{e?.error?.message ? e?.error?.message : 'Error occured. Unable to submit an event.' }</div>
                    </div> 
            });
            setShowEventModal(true);
        }
        setLoading(false);
    };

    const toggleEventModal = () => {
        setShowEventModal(false);
        setMessageObject({});
    };

    const getMessageModal = (messageObject) => {
        return <Modal
            title="Book an event"
            centered
            visible={showEventModal}
            onOk={() => toggleEventModal()}
            onCancel={() => toggleEventModal()}
            footer={
                [
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => history.push('/my-reservations') }
                    >
                        My Reservations
                    </Button>
                ]}
        >
            <div style={{marginBottom: '8px'}}>{messageObject.body}</div>
        </Modal>;
    };

    // const toggleAvailability = (e) => {
    //     e.preventDefault();
    //     setShowAvailability(true);
    // }

    const isSameDate = (dateTime1, dateTime2) => {
        return dateTime1.month === dateTime2.month && dateTime1.day === dateTime2.day && dateTime1.year === dateTime2.year;
    };

    const getDisabledDate = (date, hqDates) => {
        const currentDate = DateTime.now();
        const dateObject = DateTime.fromJSDate(date.toDate());
        const maxFutureDate = currentDate.plus({days: 90});
        if(dateObject > maxFutureDate) {
            return true;
        }
        if(date && (dateObject  < currentDate)) {
            return true;
        } else {
            const index = hqDates.findIndex(d => {
                const hqDateObj = DateTime.fromSQL(d.date);
                if(isSameDate(hqDateObj, dateObject) && d.available) {
                    return true;
                } else {
                    return false;
                }

            });

            if(index !== -1) {
                return false;
            } else {
                return true;
            }
        }
    };

    return <>
    <div className="room-reservation-form">
        <Card title="Meeting Details" style={{ width: '100%' }}>
        <Form
            name="room-reservation-form"
            {...layout}
            initialValues={{ remember: true }}
            onFinish={onFormSubmit}
            onFinishFailed={() => { }}
            autoComplete="off"
            form={form}>
            <Form.Item
                label="Event name"
                name="eventName"
                initialValue={data?.title}
                rules={[{ required: true, message: 'Please input event name!' }]}>
                <Input />
            </Form.Item>
           
            <Alert message="Requester Details" type="info" />
            <br />
            <Form.Item
                label="Requester's name"
                name="requesterName"
                initialValue={ data ? data?.requestor_name : authState.idToken.claims.name}
                rules={[{ required: true, message: 'Please input requester\'s name!' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                label="Requester's Email"
                name="requesterEmail"
                initialValue={ data ? data?.requestor_email :  authState.idToken.claims.email }
                rules={[{ required: true, message: 'Please input requester\'s email!' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                label="Requester's Mobile Number"
                name="requesterPhone"
                initialValue={data?.requestor_phone}
                rules={[{ required: true, message: 'Please input requester\'s mobile number!' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                label="Region"
                name="department"
                initialValue={data?.region_id}
                rules={[{ required: true, message: 'Please select Department !' }]}>
                <Select >
                    <Option value={1}>Americas</Option>
                    <Option value={2}>EMEA</Option>
                    <Option value={3}>APAC</Option>
                    <Option value={4}>Japan</Option>
                </Select>
            </Form.Item>
           
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '13%' }}>
                <div style={{flex: 2}}>
                    <Form.Item
                        style={{ marginBottom: '5px' }}
                        labelCol={{ span: 12 }}
                        label="Proposed Start Date"
                        name="proposedStartDate"
                        initialValue={data ? moment(data?.preferred_date) : null}
                        rules={[{ required: true, message: 'Please input Proposed Start Date!' }]}>
                        <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: '230px' }}
                            onChange={(selectedDate) => {
                                form.setFieldsValue({
                                    proposedEndDate: selectedDate
                                });
                            }}
                            disabledDate={current=> {
								return getDisabledDate(current, hqDates);
							}}
                            
                        />
                    </Form.Item>
                    {/* <div style={{ width: '100%', marginLeft: '50%' }}>
                        <a href="#" rel='noreferrer' className="check-availability" onClick={toggleAvailability}>CHECK AVAILABILITY</a>
                    </div> */}
                </div>
                <div style={{flex: 1}} >
                    <Form.Item
                        labelCol={{ span: 3 }}
                        label="to"
                        name="proposedEndDate"
                        initialValue={data ? moment(data?.preferred_end_date) : null}
                        rules={[{ required: true, message: 'Please input Proposed End Date!' }]}>
                        <DatePicker
                            format="YYYY-MM-DD"
                            style={{ width: '230px' }}
                            disabledDate={current=> {
								return getDisabledDate(current, hqDates);
							}}
                        />
                    </Form.Item>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '13%', marginTop: '8px' }}>
                <div style={{flex: 2}}>
                    <Form.Item
                        style={{ marginBottom: '5px' }}
                        labelCol={{ span: 12 }}
                        label="Proposed Time"
                        name="proposedStartTime"
                        initialValue={data ? moment(data?.preferred_start_time, 'HH:mm:ss') : null}
                        rules={[{ required: true, message: 'Please input Proposed Start Time!' }]}>
                        <TimePicker
                            style={{ width: '230px' }}
                        />
                    </Form.Item>
                </div>
                <div style={{ flex: 1 }}>
                    <Form.Item
                        labelCol={{ span: 3 }}
                        label="to"
                        name="proposedEndTime"
                        initialValue={data ? moment(data?.preferred_end_time, 'HH:mm:ss') : null}
                        rules={[{ required: true, message: 'Please input Proposed End Time!' }]}>
                        <TimePicker
                            style={{ width: '230px' }}
                        />
                    </Form.Item>
                </div>
            </div>
            <div style={{ flex: 1 }}>
                <Form.Item
                    wrapperCol={{ offset: 10}}
                    name="recurring"
                    initialValue={data ? data?.briefing_detail?.recurring1 : false}
                    valuePropName="checked">
                    <Checkbox >Recurring</Checkbox>
                </Form.Item>
            </div>
            <Alert message="Logistics" type="info" />
            <br />
            <Form.Item
                label="Attendees Type"
                name="attendeesType"
                initialValue={data ? data?.briefing_detail?.attendees_type : null}
                rules={[{ required: true, message: 'Please select Attendees Type!' }]}>
                <Select>
                    <Option value="Internal">Internal</Option>
                    <Option value="External">External</Option>
                    <Option value="Both Internal & External">Both Internal & External</Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Project Overview"
                name="projectOverview"
                initialValue={data ? data?.briefing_detail?.project_overview : null}
            >
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item
                label="Business Need"
                name="businessNeed"
                initialValue={data ? data?.briefing_detail?.business_need : null}

            >
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item
                label="Authorizing Sr. Director/VP/SVP"
                name="authPerson"
                initialValue={data ? data?.briefing_detail?.authorizing_sr_directorvpsvp : null}
                rules={[{ required: true, message: 'Please input Authorizing Sr. Director/VP/SVP!' }]}>
                {/* <Input placeholder="Search Employees.." /> */}

                <Select 
                    onChange={(value) => {
                        form.setFieldsValue({
                            authPerson: value
                        });
                    }} 
                    style={{borderRadius: '8px', width: '100%'}} 
                    showSearch
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    onSearch={(value) => setSearchTerm(value)}>
                        {results.map((employee) => <Option key={employee.email}>
                        <div style={{paddingBottom: '.5rem'}}>
                            <p style={{marginBottom: 0}}>{employee.firstName + ' ' + employee.lastName }</p>  
                            <p style={{marginBottom: 0, fontWeight: 'bold'}}>{employee.email}</p>
                        </div>
                        </Option>)}
                </Select>
            </Form.Item>
            
            <Alert
                message={<p>Please read and acknowledge our  <a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/17O_3VxdSBtq9Y6_uJOzoHbJ3S2_4kp64b49g9dSAjTw">HQ Events Guidelines</a></p>}
                type="info"
                showIcon
            />
            <br />
            <Form.Item 
                wrapperCol={{ offset: 10}}
                name="acknowledge" 
                valuePropName="checked"
                initialValue={data ? data?.briefing_detail?.i_acknowledge : false}
                rules={[{ required: true, message: 'Please check to continue!' }]}>
                <Checkbox >I acknowledge <span className="required-field">*</span></Checkbox>
            </Form.Item>
            <Form.Item>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {data ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    </Card>
</div>
    {showEventModal && getMessageModal(messageObject)}
    {/* {showAvailability && <CheckAvailability open={showAvailability} onClose={() => setShowAvailability(false)} />} */}
</> ;

};

export default withOktaAuth(ProjectRoom);