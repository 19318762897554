import React from 'react';
import {LoginCallback} from '@okta/okta-react';

const OktaError = () => {
	return (
		<React.Fragment>
			<div id='app-home' className='app-home-background'>
				<div id='app-home-info'>
					<span id="app-home-icon-text">Connected</span>
					<div id='app-home-text-container' style={{maxWidth: '70%'}}>
						<div id='app-home-header'>We are unable to authenticate you with our <span style={{color: '#F9582D'}}>FLEXSpacce platform</span>. Please close this window and try again or reach out to helpdesk@paloaltonetworks.com if this error persists.</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);

};

const OktaComponentError = () => (<LoginCallback errorComponent={OktaError}/>);

export default OktaComponentError;

