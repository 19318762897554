import React, { useState } from "react";
import { Table, Button, Tag, Switch, Tooltip, Popconfirm } from "antd";
import { withRouter } from "react-router-dom";
import env from "../../env";
import {
  DeleteOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  LoadingOutlined,
  LoginOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

// import PeerLocateModal from "./PeerLocateModal";

const PeerList = ({
  history,
  data,
  loading,
  onUpdate,
  onDelete,
  onUpdatePeerNotifications,
  onBadgeTrigger,
  onRequestConnection,
}) => {
  const [painationOptions, setPaginationOptions] = useState({
    pageSize: 10,
    showSizeChanger: true,
  });
  // const [selectedEmployee, setSelectedEmployee] = useState(null);
  // const [locateVisible, setLocateVisible] = useState(false);

  // const onLocateClose = () => {
  //   setSelectedEmployee(null);
  //   setLocateVisible(false);
  // };

  const columns = [
    {
      title: "Send Arrival Notification",
      dataIndex: "item",
      key: "outboundStatus",
      width: "20%",
      render: (item) => {
        return [
          <React.Fragment key={item.id + "--" + item.peerName}>
            {!item.outboundStatus ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tag color="volcano">Not Requested</Tag>
              </div>
            ) : item.outboundStatus === "pending" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tag color="warning" icon={<WarningOutlined />}>
                  Pending Approval
                </Tag>
                <Button
                  type="primary"
                  size="small"
                  icon={<CheckOutlined />}
                  onClick={() => onUpdate(item.id, true)}
                  style={{ marginRight: ".5rem" }}
                />
                <Button
                  type="primary"
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => onUpdate(item.id, false)}
                />
              </div>
            ) : (
              <>
                {item.outboundStatus === "active" ? (
                  <Tag color="success" icon={<CheckCircleOutlined />}>
                    Active
                  </Tag>
                ) : (
                  <Tag color="red" icon={<CloseOutlined />}>
                    Inactive
                  </Tag>
                )}
                <Switch
                  checked={item.outboundStatus === "active"}
                  onChange={(value) =>
                    onUpdatePeerNotifications(item, value, "outboundStatus")
                  }
                />
              </>
            )}
          </React.Fragment>,
        ];
      },
    },
    {
      title: "Receive Arrival Notification",
      dataIndex: "item",
      key: "inboundStatus",
      width: "20%",
      render: (item) => {
        return [
          <React.Fragment key={item.id + "---" + item.peerName}>
            {!item.inboundStatus ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => onRequestConnection(item.peerName)}
                >
                  Request
                </Button>
              </div>
            ) : item.inboundStatus === "pending" ? (
              <Tag color="warning" icon={<WarningOutlined />}>
                Pending Approval
              </Tag>
            ) : (
              <>
                {item.inboundStatus === "active" ? (
                  <Tag color="success" icon={<CheckCircleOutlined />}>
                    Active
                  </Tag>
                ) : (
                  <Tag color="red" icon={<CloseOutlined />}>
                    Inactive
                  </Tag>
                )}
                <Switch
                  checked={item.inboundStatus === "active"}
                  onChange={(value) =>
                    onUpdatePeerNotifications(item, value, "inboundStatus")
                  }
                />
              </>
            )}
          </React.Fragment>,
        ];
      },
    },
    {
      title: "Name",
      dataIndex: "item",
      key: "peerName",
      width: "15%",
      render: (item) => {
        return [
          <React.Fragment key={item.id + "-" + item.peerName}>
            <Tag color="processing">{item.peerName}</Tag>
          </React.Fragment>,
        ];
      },
    },
    {
      title: "Office Desk Booking",
      dataIndex: "item",
      key: "officeDeskBooking",
      width: "15%",
      render: (item) => {
        return [
          <React.Fragment key={item.officeDeskBooking}>
            {item.officeDeskBooking && item.officeDeskBooking !== "N/A" ? (
              <Button
                type="text"
                style={{ color: "#0071e3" }}
                onClick={() => {
                  // console.log(item.officeDeskBooking);
                  history.push("/book-a-space", {
                    name: item.peerName,
                    seat: item.officeDeskBooking,
                  });
                }}
              >
                {item.officeDeskBooking}
              </Button>
            ) : (
              <span style={{ padding: "4px 16px", lineHeight: "1.57rem" }}>
                {item.officeDeskBooking}
              </span>
            )}
          </React.Fragment>,
        ];

        // return [
        //   <React.Fragment key={item.officeDeskBooking}>
        //    <Button type='text' style={{color: '#0071e3'}} onClick={() =>  {
        //           setSelectedEmployee(item.peerName);
        //           setLocateVisible(true);
        //         }} >Locate</Button>
        //   </React.Fragment>,
        // ];
      },
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      width: "20%",
    },
    {
      title: <DeleteOutlined />,
      dataIndex: "item",
      key: "item",
      width: "4%",
      render: (item) => {
        return [
          <React.Fragment key={item.id}>
            <Popconfirm
              title={
                <>
                  <span>Are you sure you want to remove this peer?</span>
                  <div>{item.peerName} </div>
                </>
              }
              onConfirm={() => onDelete(item.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                type="text"
                onClick={() => {}}
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </React.Fragment>,
        ];
      },
    },
  ];

  if (env.NODE_ENV !== "prod") {
    columns.push({
      title: "Badge In / Out",
      dataIndex: "item",
      key: "item",
      width: "12%",
      render: (item) => {
        return [
          <React.Fragment key={item.id + "-" + item.peerName + "--badgeinout"}>
            <Tooltip title={"Badge In " + item.peerName} placement="bottom">
              <Button
                onClick={() => onBadgeTrigger(true, item.id)}
                type="primary"
                icon={<LoginOutlined />}
              ></Button>
            </Tooltip>
            <Tooltip title={"Badge Out " + item.peerName} placement="bottom">
              <Button
                style={{ marginLeft: "0.25em" }}
                type="primary"
                onClick={() => onBadgeTrigger(false, item.id)}
                icon={<LogoutOutlined />}
              ></Button>
            </Tooltip>
          </React.Fragment>,
        ];
      },
    });
  }

  const handleTableChange = (pagination) => {
    setPaginationOptions(pagination);
  };

  return (
    <>
      <div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={painationOptions}
          className="ant-table-flexspace ant-table-flexspace-pagination"
          bordered
          scroll={{ x: true }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
      {/* {locateVisible && <PeerLocateModal user={selectedEmployee} visible={locateVisible} onClose={onLocateClose}  />} */}
    </>
  );
};

export default withRouter(PeerList);
