import React, {useState, useEffect} from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {Table, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import env from '../../env';

import { EditOutlined  } from '@ant-design/icons';

// import briefingSourceMeetingRooms from './briefingSourceMeetingRooms';

const HQEvents = ({authState}) => {
    const [events, setEvents] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(async () => {
        setLoading(true);
        try {

            let config = { method: 'get', url: `${env.BACKEND_URL}/get-hq-events`, headers: { 'Authorization': authState.accessToken.value } };
            let response = await axios(config);
            let respData = response?.data?.data?.data;

            if(respData) {
                const roomIds = respData.map(item => item.room_id);
                respData = respData.map(item => ({...item, key: item.briefing_id }));
               
                config = { method: 'post', url: `${env.BACKEND_URL}/get-room-names`, headers: { 'Authorization': authState.accessToken.value }, data: { roomIds: roomIds }};
                response = await axios(config);
                const roomRespData = response?.data?.data;

                respData = respData.map(item => {
                    const index = roomRespData.findIndex(room => room.id === item.room_id);
                    if(index !== -1) {
                        return {...item, room_name: roomRespData[index].name_and_capacity };
                    }

                    return {...item, room_name: null };
                });

                setEvents(respData);
            }
        } catch(e) {
            console.log(e);
        }
        setLoading(false);
        
    }, []);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'briefing_id',
            key: 'briefing_id',
            width: '5%',
            sorter: {
                compare: (a, b) =>  a.briefing_id?.toString()?.localeCompare(b.briefing_id?.toString()),
            },
            sortOrder: 'ascend',
        },
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			width: '20%',
		},
		{
			title: 'Scheduled',
			//dataIndex: 'scheduled_date',
			key: 'scheduled_date',
			width: '15%',
            render: item => {
                let date = '';
                let time = '';
                if(item.scheduled_date) {
                    date = item.scheduled_date.split(' ')[0];
                }
                if(item.start_time && item.end_time) {
                    time = `${item.start_time} - ${item.end_time}`;
                }

                return [<div key={item.briefing_id}>
                    <div>{date}</div>
                    <div>{time}</div>
                </div>];
            }
		},
		{
			title: 'Room',
			dataIndex: 'room_name',
			key: 'room_name',
			width: '20%',
            // render: item => {
            //     const roomIndex = briefingSourceMeetingRooms.findIndex(i => i['room_id'] === item);
            //     if(roomIndex !== -1) {
            //         return briefingSourceMeetingRooms[roomIndex]['room'];
            //     }

            // }
		},
		{
			title: 'Requester',
			dataIndex: 'requestor_name',
			key: 'requestor_name',
			width: '10%',
		},
        {
			title: 'Manager',
			dataIndex: 'confirmed_by',
			key: 'confirmed_by',
			width: '10%',
		},
        {
			title: 'Type',
			dataIndex: 'briefing_type_id',
			key: 'briefing_type_id',
			width: '10%',
            render: item => {
                if(item === 1) {
                    return 'Room Reservation';
                } else if(item === 2) {
                    return 'Project Room';
                } else {
                    return null;
                }
                
            }
		},
        {
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: '5%',
            render: item => {
                return item?.name;                
            }
		},
		{
			title: 'Actions',
			width: '5%',
			render: item => {
				return (
					[
                        <Button
                            key={item.briefing_id}
                            style={{padding: 0, height: 'auto', color: '#1890ff'}}
                            type='text'
                            onClick={() => {
                                history.push({
                                    pathname: '/hq-event',
                                    state: {eventData: item}
                                });
                            }}
                            icon={<EditOutlined  />}
                        />
					
					]
				);
			}
		},
	];

    return <div className="hq-events-table-container">
        <div style={{width: '100%', overflowX: 'auto' }}>
            <Table
            columns={columns}
            dataSource={events}
            pagination={false}
            className="ant-table-flexspace"
            scroll={{x: true}}
            bordered
            loading={loading}
        />
        </div>
    </div>;

};

export default withOktaAuth(HQEvents);
