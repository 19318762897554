import React from 'react';
import Slider from '../../shared/Slider/Slider';
import SolidButton from '../../shared/SolidButton';

const EventGridList = (props) => {

    return (
            <div className="event-card-list-wrap-container" >
                {props.roomList.length !== 0 && <h5 className='grid-title'>Based on your interests, we suggest any of the following rooms:</h5>}
                <div className="event-card-list-wrap">
                    {props.roomList.length > 0 && props.roomList.map((item, index) => {
                        return (<div key={index + 1} className="grid-wrap">
                            <div className="craousel-wrap"><Slider images={item.images} /></div>
                            <div className="info-wrap"><h4>{item.name}</h4>
                                <div className="des-wrap">
                                    <p>{item.location}</p>
                                    <p>{item.configStyle}</p>
                                    <p>Max capacity: {item.maxCapacity}</p>
                                </div>
                            </div>
                            <div className="grid-btn-wrap"><SolidButton label={'Select room'} onHandle ={()=>props.openModal(item.roomID)}/></div>
                        </div>);
                    })}
                    {props.roomList.length === 0 && <div className='no-results-found'><div>No results found! Please adjust the filters on the left</div></div>}
                </div>
        </div>
    );
};

export default EventGridList;
