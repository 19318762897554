import React from 'react';
class NavClick extends React.Component {
    constructor(props) {
      super(props);
    }
    
    render() {
      let current = this.props.current;
      let items = this.props.items.map( (el, index) => {
        let name = (index == current) ? 'active' : '';
        return (
          <li key={index}>
            <span 
              className={name} 
              onClick={ () => this.props.changeSilde(current, index) }
            ></span>
          </li>
        );
      });
      
      return (
        <ul>{items}</ul>
      );
    }
  }

  export default NavClick;