import React from 'react';
import Dropdown from '../../shared/Dropdown';
import moment from 'moment';
import { DatePicker, Space } from 'antd';
import GroupDropdown from '../../shared/Dropdown/GroupDropdown';
import 'antd/dist/antd.css';
const { RangePicker } = DatePicker;

const EventToolBar = (props) => {
  const groupdropDowndata = {
    'ok':true,
    'data':{
       'Hong Kong':[
          {
             'name':'Building 1, Floor 1',
             'id':'abcd1201'
          },
          {
             'name':'Building 1, Floor 2',
             'id':'abcd1202'
          }
       ],
       'Plano':[
          {
             'name':'Building 1, Floor 1',
             'id':'abcd1301'
          },
          {
             'name':'Building 1, Floor 2',
             'id':'abcd1302'
          },
          {
             'name':'Building 1, Floor 3',
             'id':'abcd1303'
          }
       ],
       'Santa Clara, HQ':[
          {
             'name':'Building 1, Floor 1',
             'id':'abcd1401'
          }
       ]
    }
 };
  const handleCampus = (item) => {
    props.selectedCampus(item);
  };
  let url = window.location.pathname;
  const onDateTimeChange = (value, dateStrings) => {
    props.selectedDateTime(value, dateStrings[0], dateStrings[1]);
  };
  let dateFormat = 'ddd MMM DD,YYYY hh:mma';
  return (
    <section className="toolbar-wrap">
      <div className="toolbar custom-container-fluid">
        <div>
          <div className="calendar-label-wrap">
            <label className="date-time">*Start date and time</label>
            <div>
              <div className="calendar-input-wrap event-toolbar">
                <Space direction="vertical" size={12}>
                  {props.navigationTitle !== 'Book A Desk' ?
                    <RangePicker
                      showTime={{ use12Hours: true }}
                      format={dateFormat}
                      onChange={onDateTimeChange}
                      defaultValue={[moment(props.eventStartDate, dateFormat), moment(props.eventEndDate, dateFormat)]}
                      disabledDate={d => !d || d.isSameOrBefore(moment())}
                    /> :
                    <RangePicker
                      showTime={{ use12Hours: true }}
                      format={dateFormat}
                      onChange={onDateTimeChange}
                      defaultValue={[moment(props.eventStartDate, dateFormat), moment(props.eventEndDate, dateFormat)]}
                      disabledDate={d => !d || d.isBefore(moment().add(-1, 'days'))||d.isAfter(moment().add(1, 'days'))}
                    />}
                </Space>
              </div>

            </div>
          </div>
        </div>
        <div>
          <div className="calendar-label-wrap">
            <label>Campus</label>
            <div>
              <div className="calendar-input-wrap">
                {url !== '/book-a-desk'? <Dropdown dropDownData={props.campusList} idValue={'label'} initialCampus={props.initialCampus} selectedValue={handleCampus} />:<GroupDropdown dropDownData={groupdropDowndata.data} idValue={'label'} selectedValue={handleCampus} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventToolBar;