import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import env from '../env.json';
import Header from '../shared/Header';
import EventSpace from '../components/EventSpace';
import LocationSpace from '../components/LocationSpace';
import GuestAttendSpace from '../components/GuestAttendSpace';
import ConfigurationSpace from '../components/ConfigurationSpace';
import EventList from './EventList';
import SheldonComponent from '../components/SheldonComponent';
import EventNavigationbar from '../components/EventList/EventNavigationbar';

const stateInit = {
    formFields: [],
    campusList: [],
    roomList: [],
    isLocation: false,
    isGuestAttendence: false,
    isConfiguration: false,
    isCommunity: false,
    isEventList: false,
    location: '',
    configuration: '',
    eventStartDate: '',
    eventEndDate: '',
    guestsAttending: {
        'emp': 0,
        'guest': 0,
        'exec': 0,
        'vip': 0
    }
};

class BookAnEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = stateInit;
    }
    componentDidMount = async () => {
        const form = await axios.get(`${env.BACKEND_URL}/eventBookingForm`);
        const spaces = await axios.get(`${env.BACKEND_URL}/eventSpaces`);
        if(form.data.data !== undefined && spaces.data.data.campuses !== undefined && spaces.data.data.rooms !==undefined ){
        this.setState({
            formFields: form.data.data,
            campusList: spaces.data.data.campuses,
            roomList: spaces.data.data.rooms
        });
    }
    }
    navigateBookAnEvent = () => {
        this.setState(stateInit);
        this.props.history.push('/');
    }
    navigateLocation = (item) => {
        if (item === 'community') {
            this.setState({
                isCommunity: true,
                location: item
            });
        }
        else {
            this.setState({
                isCommunity: false,
                location: item
            });
        }
        this.setState({
            isLocation: !this.state.isLocation,
            isGuestAttendence: false,
            isConfiguration: false
        });
    }
    navigateEventList = (item) => {
        this.setState({
            isEventList: !this.state.isEventList,
            isLocation: false,
            isGuestAttendence: false,
            isConfiguration: false,
            isCommunity: false,
            configuration: item
        });
    }
    navigateGuestAttend = (startDate, endDate) => {
        this.setState({
            isGuestAttendence: !this.state.isGuestAttendence,
            isLocation: false,
            isConfiguration: false,
            eventStartDate: startDate,
            eventEndDate: endDate
        });
    }
    navigateConfiguration = (isPanEmployee, isExternalGuest, isExecutive, isVip) => {
        this.setState({
            isLocation: false,
            isGuestAttendence: false,
            guestsAttending: {
                'emp': parseInt(isPanEmployee),
                'guest': parseInt(isExternalGuest),
                'exec': parseInt(isExecutive),
                'vip': parseInt(isVip)
            }
        });
        if (this.state.isCommunity === true) {
            this.setState({
                isEventList: !this.state.isEventList,
                isConfiguration: false,
                isCommunity: false
            });
        }
        else {
            this.setState({
                isConfiguration: !this.state.isConfiguration
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.state.isEventList === false ?
                    (
                        <>
                            <Header hideMyBadge={true}/>
                            <EventNavigationbar navigationTitle='Book an Event'  path='/'/>
                            <div className='custom-bck inner-bck'>
                                <section className="custom-container">
                                    {this.state.isLocation === true ?
                                        <LocationSpace
                                            navigateGuestAttend={this.navigateGuestAttend}
                                            onhandleClose={this.navigateBookAnEvent}
                                            location={this.state.location}
                                        /> :
                                        this.state.isGuestAttendence === true ?
                                            <GuestAttendSpace
                                                navigateConfiguration={this.navigateConfiguration}
                                                onhandleClose={this.navigateBookAnEvent}
                                                location={this.state.location}
                                            /> :
                                            this.state.isConfiguration === true && this.state.isCommunity === false ?
                                                <ConfigurationSpace
                                                    navigateEventList={this.navigateEventList}
                                                    onhandleClose={this.navigateBookAnEvent}
                                                />
                                                :
                                                <EventSpace
                                                    onhandleClose={this.navigateBookAnEvent}
                                                    navigateLocation={this.navigateLocation}
                                                />}
                                </section>
                            </div>
                        </>
                    )
                    : <EventList
                        onhandleClose={this.navigateBookAnEvent}
                        location={this.state.location}
                        configuration={this.state.configuration}
                        eventStartDate={this.state.eventStartDate}
                        eventEndDate={this.state.eventEndDate}
                        guestsAttending={this.state.guestsAttending}
                        roomList={this.state.roomList}
                        campusList={this.state.campusList}
                        formFields={this.state.formFields}
                    />
                }
                <SheldonComponent/>
            </React.Fragment>
        );
    }
}
BookAnEvent.propTypes = {
    authState: PropTypes.object
};
export default withRouter(withOktaAuth(BookAnEvent));
