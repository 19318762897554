import React from 'react';
import axios from 'axios';
import moment from 'moment';
import env from '../env.json';
import EventHeader from '../components/EventList/EventHeader/EventHeader';
import EventNavigationbar from '../components/EventList/EventNavigationbar';
import EventToolBar from '../components/EventList/EventToolBar';
import EventListSidebar from '../components/EventList/EventListSidebar';
import EventGridList from '../components/EventList/EventGridList';
import ComingSoonModal from '../components/Dialog/ComingSoonModal';
import BookingForm from '../components/EventList/BookingForm/BookingForm';
class EventList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            existingReservations: [],
            roomList: this.props.roomList,
            campusList: this.props.campusList,
            formFields: this.props.formFields,
            roomListMatch: [],
            selectedCampus: 'Santa Clara',
            eventStartDate: this.props.eventStartDate,
            eventEndDate: this.props.eventEndDate,
            locationType: this.props.location,
            configuration: this.props.configuration,
            guestsAttending: {
                'emp': this.props.guestsAttending.emp,
                'guest': this.props.guestsAttending.guest,
                'exec': this.props.guestsAttending.exec,
                'vip': this.props.guestsAttending.vip
            },
            isModal: false,
            isBookingForm: false,
            roomID:''
        };
    }
    componentDidMount = async () => {
        this.retrieveReservations();
    }

    filterRooms = () => {
        let roomType = '', capacityKey = 'capacity';
        if (this.state.locationType === 'multipurpose') {
            roomType = 'Multi-Purpose Room';
            capacityKey += this.state.configuration;
        }
        else {
            roomType = 'Community Space';
            capacityKey += 'Community';
        }

        let requiredCapacity = 0;
        Object.keys(this.state.guestsAttending).forEach(typ => requiredCapacity += this.state.guestsAttending[typ]);

        let matchedRooms = this.state.roomList.filter(room => {
            if (room.type !== roomType) { return false; }
            if (room.campus !== this.state.selectedCampus) { return false; }
            if (this.state.guestsAttending.exec > 0 && !room.isExecRecommended) { return false; }
            if (this.state.existingReservations.includes(room.roomID)) { return false; }
            if (room[capacityKey] < requiredCapacity) { return false; }
            return true;
        });

        matchedRooms = matchedRooms.map(room => {
            return {
                name: room.name,
                location: room.campus,
                roomID: room.roomID,
                configStyle: this.state.configuration !== '' ? this.state.configuration + ' style' : 'Community Space',
                maxCapacity: room[capacityKey],
                images: room.images.map(img => { return [env.SN_BASE_URL, img].join('/'); })
            };
        });

        this.setState({ roomListMatch: matchedRooms });
    }
    handleSelectedCampus = (item) => {
        this.setState({ selectedCampus: item }, this.retrieveReservations);
    }
    handleLocation = (item) => {
        if (item === 'community') {
            this.setState({
                locationType: item,
                configuration: ''
            }, this.filterRooms);
        }
        else {
            this.setState({
                locationType: item,
                configuration: this.props.configuration !== undefined ? this.props.configuration : this.state.configuration
            }, this.filterRooms);
        }
    }
    handleConfiguration = (item) => {
        this.setState({ configuration: item }, this.filterRooms);
    }
    handleGuestAttending = (emp, guest, exec, vip) => {
        this.setState({
            guestsAttending: {
                'emp': parseInt(emp),
                'guest': parseInt(guest),
                'exec': parseInt(exec),
                'vip': parseInt(vip)
            }
        }, this.filterRooms);
    }
    handleDateRange = (value, startDate, EndDate) => {
        this.setState({
            eventStartDate: startDate,
            eventEndDate: EndDate
        }, this.retrieveReservations);
    }
    retrieveReservations = async () => {
        //const dStart = moment(this.state.eventStartDate, 'dddd MMM DD,YYYY hh:mm a').format('YYYY-MM-DD HH:mm');
        const dStart = moment(this.state.eventStartDate, 'ddd MMM DD,YYYY hh:mma').format('YYYY-MM-DDTHH:mm:ss');
        const dEnd = moment(this.state.eventEndDate, 'ddd MMM DD,YYYY hh:mma').format('YYYY-MM-DDTHH:mm:ss');

        const result = await axios.get(`${env.BACKEND_URL}/eventReservations?dateStart=${dStart}&dateEnd=${dEnd}&campus=${this.state.selectedCampus}`);
        this.setState({ existingReservations: result.data.data }, this.filterRooms);
    }
    onClose = () => {
        this.setState({ isModal: false });
    };
    openModal = () => {
        this.setState({ isModal: true });
    };
    onCloseBookingForm=()=>{
        this.setState({ isBookingForm: false });
    }
    openModalBookingForm = async(roomno) => {
        this.setState({ isBookingForm: true, roomID: roomno });
    };
    render() {
        const { eventStartDate, eventEndDate, locationType, configuration, roomListMatch, guestsAttending, selectedCampus} = (this.state);
        return (
            <React.Fragment>
                <div className="">
                    <EventHeader/>
                    <EventNavigationbar  navigationTitle={'Book An Event'} onhandleClose={this.props.onhandleClose} openModal={this.openModal} />
                    <EventToolBar
                        eventStartDate={eventStartDate}
                        eventEndDate={eventEndDate}
                        campusList={this.state.campusList}
                        initialCampus={selectedCampus}
                        selectedCampus={this.handleSelectedCampus}
                        selectedDateTime={this.handleDateRange}
                    />
                    <section className="sidebar-list-wrap">
                        <div className="sidebar-list-wrap custom-container-fluid event-list-spacing">
                            <EventListSidebar
                                location={locationType}
                                configuration={configuration}
                                guestsAttending={guestsAttending}
                                locationConfig={this.handleLocation}
                                ConfigurationConfig={this.handleConfiguration}
                                guestAttendingConfig={this.handleGuestAttending}
                            />
                            <EventGridList roomList={roomListMatch} openModal={this.openModalBookingForm} />
                        </div>
                    </section>
                    <ComingSoonModal isOpen={this.state.isModal} title='Coming Soon' onClose={this.onClose} />
                    <BookingForm isOpen={this.state.isBookingForm}
                        title='Booking Form'
                        onCloseBookingForm={this.onCloseBookingForm}
                        campus={selectedCampus}
                        formFields={this.state.formFields}
                        startDate={eventStartDate}
                        endDate={eventEndDate}
                        roomID={this.state.roomID}
                        configStyle={this.state.configuration || ''}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default EventList;
